import React from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { useSearchStore } from "../../configs/stores/search";
import moment from "moment";
import { getPropValue } from "../../functions/getPropValue";
import { isAndroid, primaryColor } from "../../constants";

const styleBox = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "0px solid transparent",
  radiusBorder: 8,
  boxShadow: 1,
};

type Props = {
  placeholder?: string;
  value: number | null;
};

const SearchInputDate = ({ placeholder = "Date", value }: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { updateDate, clearDate, submitFormError } = useSearchStore();
  const date = React.useMemo(
    () => (value ? moment(value) : null),
    [value]
  );
  const [openModal, setOpenModal] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const error = React.useMemo(
    () => (getPropValue(submitFormError, "date") ?? false) as boolean,
    [submitFormError]
  );
  function onFocused(has: boolean) {
    setIsFocused(has);
  }
  function onSensitiveClick() {
    setOpenModal(true);
  }
  function clearDt() {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.value = "";
    clearDate();
  }
  function update(e: moment.Moment) {
    if (!e) {
      return;
    }
    if (!inputRef.current) {
      return;
    }
    inputRef.current.value = e.format("LL");
    updateDate(moment(e).valueOf());
  }
  React.useEffect(() => {
    if (date) {
      const dt = moment(date).valueOf();
      const now = moment(moment().format("YYYY-MM-DD")).valueOf();
      if (now > dt) {
        clearDt();
      } else {
        if (!inputRef.current) {
          return;
        }
        inputRef.current.value = moment(date).format("LL");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);
  return (
    <React.Fragment>
      <div className="flex flex-row flex-1 transition-all relative duration-150">
        <div className="absolute inset-y-0 left-0 flex items-center pl-[8px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill={
              !!date
                ? primaryColor
                : error
                ? "red"
                : isFocused
                ? primaryColor
                : "rgb(148 163 184)"
            }
            className="w-6 h-7 absolute cursor-text"
            onClick={onSensitiveClick}
          >
            <path d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
            <path
              fillRule="evenodd"
              d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div
          style={{
            display: !!date ? "flex" : "none",
          }}
          onClick={() => clearDt()}
          className="absolute inset-y-0 right-0 flex items-center pl-1 pr-8"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill={
              !!date
                ? primaryColor
                : error
                ? "red"
                : isFocused
                ? primaryColor
                : "rgb(148 163 184)"
            }
            className="w-6 h-6 absolute cursor-pointer"
          >
            <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
          </svg>
        </div>
        <input
          type="text"
          ref={inputRef}
          placeholder={placeholder}
          onFocus={() => onFocused(true)}
          onBlur={() => onFocused(false)}
          onClick={onSensitiveClick}
          readOnly
          className={`flex flex-1 rounded-[10px] phone:max-w-[calc(100vw-100px)] text-black pl-9 border-[2.5px] caret-primary ${
            !!date
              ? "border-primary focus:outline-none"
              : error
              ? "caret-red-500 border-red-500 outline-none"
              : "border-slate-300 focus:outline-none focus:border-primary"
          } font-medium ${
            isAndroid ? "phone:text-[17px]" : "phone:text-[18px]"
          } text-[19px] py-2 pr-1 bg-slate-200`}
        />
      </div>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        slots={{ backdrop: Backdrop }}
        closeAfterTransition
        slotProps={{
          backdrop: {
            style: { backdropFilter: `blur(2px)` },
            timeout: 250,
          },
        }}
      >
        {openModal ? (
          <Fade in={openModal}>
            <Box
              sx={{
                ...styleBox,
                minWidth: 200,
                borderRadius: 2,
                outline: "none",
              }}
            >
              <div className="flex relative">
                <div className="flex flex-col gap-1 rounded-[15px] p-3 pt-4 overflow-hidden bg-white">
                  <span className="self-center flex text-[18px] font-medium phone:font-bold">
                    Sélectionner le jour
                  </span>
                  <div className="flex flex-col gap-3">
                    <DateCalendar
                      defaultValue={null}
                      minDate={moment()}
                      value={date}
                      views={["year", "month", "day"]}
                      onChange={update}
                      showDaysOutsideCurrentMonth
                      fixedWeekNumber={6}
                      sx={{
                        color: primaryColor,
                        ...(isAndroid
                          ? {
                              "@media (max-width: 640px)": {
                                width: "95%",
                              },
                            }
                          : {}),
                      }}
                    />
                    <button
                      onClick={() => setOpenModal(false)}
                      className="flex rounded-[10px] hover:bg-hover phone:hover:bg-primary transition-all duration-150 bg-primary active:bg-active py-3 items-center justify-center"
                    >
                      <span className="flex text-[18px] text-white font-medium">
                        Confirmer
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </Fade>
        ) : (
          <></>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default SearchInputDate;
