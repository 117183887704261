import React from "react";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";

type Props = {
  color?: string;
  size?: number;
  href?: string;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
};

const Logo = ({ color = "#F56B2A", size = 28, href, onClick }: Props) => {
  const Ratio_SVG = 166 / 28;
  const navigate = useNaviLoadBoth;
  const heigth = size.toString();
  const width = (Ratio_SVG * size).toString();
  const clickable = !!onClick || !!href;
  function click(e: any) {
    onClick && onClick(e);
    href && navigate(href);
  }
  return (
    <svg
      width={width}
      height={heigth}
      viewBox="0 0 166 28" //Ratio_SVG
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clickable ? "cursor-pointer" : undefined}
      onClick={click}
    >
      <path
        d="M19.257 6.97025C14.4704 6.97025 11.6749 10.1813 11.6749 14.9646C11.6536 16.1469 11.8608 17.3226 12.2856 18.4299H12.3631V18.6228C13.4887 21.1866 15.9669 22.6254 19.6555 22.6254C21.9621 22.6254 23.5472 22.3441 25.2485 21.7527V18.4064C23.6339 19.0267 22.3367 19.2791 20.3475 19.2791C17.7531 19.2791 16.3692 18.237 15.9946 16.068H25.9404C25.97 15.3919 25.97 14.6887 25.97 14.0126C25.9737 9.22395 23.3202 6.97025 19.257 6.97025ZM16.0278 13.3419C16.3968 11.3424 17.5557 10.4121 19.1979 10.4121C20.8125 10.4121 21.8791 11.2577 21.9953 13.3419H16.0278Z"
        fill={color}
      />
      <path
        d="M10.839 19.966V19.7731H10.7578C10.6344 19.465 10.5315 19.1494 10.4496 18.8283H4.41013V0H0V22.6037H13.0034C12.0417 21.9416 11.2913 21.0272 10.839 19.966Z"
        fill={color}
      />
      <path
        d="M12.2856 17.9701C12.3115 18.035 12.3393 18.0999 12.367 18.163V17.9701H12.2856Z"
        fill={color}
      />
      <path
        d="M33.9525 13.0552C30.93 12.5486 30.4115 12.2601 30.4115 11.4488C30.4115 10.6375 31.1606 10.239 33.0631 10.239C34.8132 10.2356 36.5505 10.5309 38.1966 11.1117V7.75274C36.641 7.18841 35.0264 6.96304 32.8638 6.96304C28.5681 6.96304 26.3206 8.68126 26.3206 11.7517C26.3206 14.4562 28.02 15.7182 31.3655 16.2862C34.2477 16.7657 34.8234 17.0759 34.8234 17.9773C34.8234 18.9618 34.0171 19.3566 31.7972 19.3566C29.9815 19.3566 27.9628 18.9618 26.3482 18.1739V21.695C27.7322 22.2864 29.9225 22.6236 32.1423 22.6236C36.6687 22.6236 39.062 20.963 39.062 17.779C39.0601 14.8582 37.5027 13.6466 33.9525 13.0552Z"
        fill={color}
      />
      <path
        d="M62.1201 6.97025C57.1915 6.97025 54.1634 10.0966 54.1634 14.9141C54.1634 19.7028 57.0752 22.6308 62.0057 22.6308C66.9049 22.6308 69.9403 19.5333 69.9403 14.7158C69.9403 9.89826 66.9916 6.97025 62.1201 6.97025ZM62.0629 19.0825C59.8136 19.0825 58.4592 17.5608 58.4592 14.8005C58.4592 12.0402 59.8136 10.5203 62.0629 10.5203C64.2772 10.5203 65.6372 12.0402 65.6372 14.8005C65.6372 17.5608 64.2828 19.0825 62.0629 19.0825Z"
        fill={color}
      />
      <path
        d="M80.5117 6.95763L77.0814 17.1245L73.5071 6.95763H68.7206L74.9188 21.6878L72.6122 28H76.8507L78.9838 21.659L85.123 6.95763H80.5117Z"
        fill={color}
      />
      <path
        d="M90.6015 6.95763C88.7322 6.94784 86.8735 7.23276 85.0971 7.80142V11.3587C86.5819 10.7817 88.1679 10.4944 89.7656 10.5131C92.2161 10.5131 93.1959 11.2739 93.1959 13.0769V13.9207C92.1017 13.5835 91.0351 13.4429 89.5349 13.4429C85.9902 13.4429 83.7999 15.217 83.7999 18.118C83.7999 20.9071 85.6728 22.6524 88.8153 22.6524C90.8912 22.6524 92.4763 22.061 93.6572 21.0748L93.8879 22.2017H97.464V13.378C97.4621 8.90122 95.6169 6.95763 90.6015 6.95763ZM93.1959 18.4209C92.7987 18.8065 92.326 19.11 91.806 19.3132C91.2861 19.5164 90.7296 19.6152 90.1697 19.6036C88.9297 19.6036 88.0366 19.124 88.0366 17.9142C88.0366 16.7044 88.9592 16.1383 90.5443 16.1383C91.443 16.1449 92.336 16.2779 93.1959 16.5332V18.4209Z"
        fill={color}
      />
      <path
        d="M108.5 19.2394L104.031 18.9023C102.647 18.8175 102.273 18.4786 102.273 17.8872C102.276 17.7382 102.311 17.5916 102.374 17.4561C102.438 17.3206 102.529 17.1992 102.642 17.0993C103.48 17.3114 104.343 17.4156 105.208 17.4094C109.268 17.4094 111.635 15.3252 111.635 12.3395C111.635 11.0161 111.204 9.8604 110.311 9.04366C111.174 9.01481 112.01 9.01481 112.905 9.01481V6.05795C110.796 6.08856 108.705 6.44015 106.707 7.10006C106.213 7.01001 105.711 6.96295 105.208 6.95943C101.114 6.95943 98.7501 8.95892 98.7501 12.1988C98.7501 13.7187 99.2409 14.9592 100.191 15.8318C99.2686 16.5368 98.7796 17.4094 98.7796 18.5363C98.7629 19.0324 98.8863 19.5235 99.1363 19.9557C99.3863 20.3879 99.7531 20.7445 100.197 20.9865C98.8128 21.7185 97.7979 22.7894 97.7979 24.367C97.7979 26.7884 99.9587 28 104.659 28C109.875 28 112.931 26.1682 112.931 23.0995C112.94 20.6764 111.241 19.4377 108.5 19.2394ZM105.216 9.94514C106.858 9.94514 107.751 10.706 107.751 12.1988C107.751 13.6628 106.858 14.4237 105.216 14.4237C103.542 14.4237 102.649 13.6628 102.649 12.1988C102.649 10.706 103.542 9.94514 105.216 9.94514ZM104.926 25.2703C102.332 25.2703 101.496 24.7637 101.496 23.6657C101.496 23.0184 101.9 22.4829 102.621 22.0881L107.29 22.4487C108.582 22.5334 109.135 22.8093 109.135 23.5755C109.135 24.7006 108.011 25.2667 104.926 25.2667V25.2703Z"
        fill={color}
      />
      <path
        d="M126.03 21.7581V18.4064C124.416 19.0267 123.118 19.2791 121.129 19.2791C118.535 19.2791 117.151 18.237 116.776 16.068H126.722C126.75 15.3919 126.75 14.6887 126.75 14.0126C126.75 9.22395 124.098 6.97025 120.033 6.97025C115.248 6.97025 112.453 10.1813 112.453 14.97C112.453 19.7587 115.248 22.6308 120.437 22.6308C122.742 22.6308 124.327 22.3567 126.03 21.7581ZM119.976 10.3995C121.591 10.3995 122.657 11.2451 122.773 13.3293H116.804C117.173 11.337 118.332 10.4067 119.974 10.4067L119.976 10.3995Z"
        fill={color}
      />
      <path
        d="M132.974 22.6452C134.906 22.6452 136.52 22.0827 137.875 20.927L138.105 22.2233H141.715V6.97025H137.413V17.5464C137.011 17.9854 136.517 18.3358 135.966 18.5745C135.414 18.8133 134.817 18.9348 134.214 18.9311C132.599 18.9311 131.907 18.0855 131.907 16.0572V6.97025H127.613V16.5061C127.613 20.7016 129.025 22.6452 132.974 22.6452Z"
        fill={color}
      />
      <path
        d="M151.626 6.50689C150.068 6.50689 148.655 7.09826 147.444 8.53342L147.127 6.95763H143.525V22.6308H147.791V12.6748C148.8 11.1531 150.155 10.5329 151.482 10.5329C151.844 10.5312 152.206 10.562 152.563 10.6249C152.705 10.6496 152.845 10.6851 152.982 10.7312V6.67096C152.54 6.55423 152.083 6.49899 151.626 6.50689Z"
        fill={color}
      />
      <path
        d="M160.905 13.0552C157.879 12.5486 157.359 12.2656 157.359 11.4488C157.359 10.6321 158.108 10.239 160.012 10.239C161.761 10.236 163.497 10.5313 165.142 11.1117V7.75274C163.586 7.18841 161.972 6.96304 159.809 6.96304C156.637 6.96304 154.585 7.90238 153.72 9.62962V9.6152C153.497 10.0729 153.355 10.5643 153.301 11.0684C153.443 11.0931 153.583 11.1287 153.72 11.1748V11.1892C153.584 11.1423 153.443 11.1067 153.301 11.0828C153.274 11.3048 153.259 11.5281 153.259 11.7517C153.259 14.4562 154.96 15.7182 158.303 16.2862C161.186 16.7657 161.763 17.0759 161.763 17.9773C161.763 18.9618 160.955 19.3566 158.735 19.3566C156.92 19.3566 154.901 18.9618 153.288 18.1739V21.695C154.67 22.2864 156.862 22.6236 159.082 22.6236C163.607 22.6236 166 20.963 166 17.779C166 14.8582 164.443 13.6466 160.905 13.0552Z"
        fill={color}
      />
      <path
        d="M57.0974 2.94243L58.2728 0H53.0231L52.0137 2.84507C53.7085 2.76173 55.4072 2.79426 57.0974 2.94243Z"
        fill={color}
      />
      <path
        d="M41.518 0H35.9823L38.442 6.20399C39.9537 5.49711 41.5143 4.89465 43.1123 4.40103L41.518 0Z"
        fill={color}
      />
      <path
        d="M49.4618 9.88023C48.6517 12.0961 47.8416 14.6617 47.2899 16.4665C46.7068 14.6635 45.8968 12.0961 45.0867 9.88023L44.0386 6.97566C42.5108 7.58876 41.016 8.27779 39.5602 9.04005L45.0959 22.492H49.698L56.649 3.898C54.8473 4.03821 53.0587 4.30818 51.2978 4.70573L49.4618 9.88023Z"
        fill={color}
      />
      <path
        d="M56.649 3.898C59.8117 3.66362 63.0206 3.88178 66.08 4.78146C63.1469 3.8368 60.1136 3.21986 57.0383 2.94243L56.649 3.898Z"
        fill={color}
      />
    </svg>
  );
};

export default Logo;
