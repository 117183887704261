import React from "react";
import Slider from "@mui/material/Slider";
import { useDebounce } from "@uidotdev/usehooks";
import { useSearchStore } from "../../../configs/stores/search";

function valuetext(value: number) {
  return `${value}°C`;
}

const CostFilter = () => {
  const { updateFilter, results_filter } = useSearchStore();
  const [value, setValue] = React.useState<number[]>([
    results_filter.price_intervalle.min,
    results_filter.price_intervalle.max,
  ]);
  const debouncedValue = useDebounce(value, 1200);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  React.useEffect(() => {
    updateFilter({
      ...results_filter,
      price_intervalle: { min: debouncedValue[0], max: debouncedValue[1] },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <div className="flex flex-col gap-11">
      <span className="flex text-[16px] font-bold">Intervale de prix</span>
      <div className="flex flex-col gap-[10px] w-[90%]">
        <Slider
          getAriaLabel={() => "Temperature range"}
          value={value}
          min={500}
          max={70000}
          step={500}
          onChange={handleChange}
          valueLabelDisplay="on"
          getAriaValueText={valuetext}
        />
      </div>
    </div>
  );
};

export default CostFilter;
