import React from "react";

const FormInput = ({ title = "",placeholder="", important = false }) => {
  return (
    <div className="flex flex-col gap-2 w-full">
      {!!title&&<span className="flex text-[18px]">
        {title} {important && <span className="flex text-coral pl-2">*</span>}
      </span>}
      <input
        type="text"
        placeholder={placeholder}
        className="flex w-full text-[18px] font-normal rounded-md border border-gainsboro bg-white py-3 outline-none px-3"
      />
    </div>
  );
};

export default FormInput;
