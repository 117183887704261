import React from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { useDimensions } from "../../hooks/useDimensions";
import { useBasicsStore } from "../../configs/stores/basics";
import DestinationRegionalItem from "./item";
import "./style.css";

const DestinationRegional = () => {
  const destinationCapitals = useBasicsStore((e) => e.destinationCapitals);
  const [refDim, dimensions] = useDimensions<HTMLDivElement>();
  const [ref] = useKeenSlider<HTMLDivElement>({
    loop: false,
    mode: "snap",
    slides: {
      perView: "auto",
      spacing: 15,
    },
  });
  return (
    <div className="flex flex-col gap-4 w-full">
      <div className={`flex flex-col gap-2`}>
        <div className="font-semibold text-left text-[26px] text-black">
          Visitez les plus grandes capitales de la sous région
        </div>
        <div className={`text-[18px] text-gray-100`}>
          Réalisez votre rêve de visiter l’Afrique, réservez votre prochain
          voyage dès maintenant !
        </div>
      </div>
      <div className="flex flex-row w-full">
        <div ref={refDim} className="flex flex-1">
          <div
            ref={ref}
            className="keen-slider flex"
            style={{ maxWidth: dimensions?.width ?? 10 }}
          >
            {destinationCapitals.length !== 0 &&
              destinationCapitals.map((dest) => {
                return (
                  <DestinationRegionalItem
                    key={dest.destination_id}
                    item={dest}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DestinationRegional;
