import React from "react";
import Logo from "../svgJsx/logo";
import HeaderLang, { HeaderLangDrawer } from "./lang";
// import GoPremiumPage from "./goPremiumPage";
import AccountProfile from "./accountProfile";
import BarsIcon from "../svgJsx/bars";
import Drawer from "./drawer";

const Header = () => {
  const [open, setOpen] = React.useState(false);

  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);
  return (
    <React.Fragment>
      <div
        className={`flex h-[70px] flex-row w-full items-center justify-center bg-white px-10 fixed z-[9] shadow-sm top-0 left-0 phone:px-5 phone:pr-2 phone:h-[60px] tablet_mini:px-6`}
      >
        <div
          className={`flex flex-row items-center justify-between w-full max-w-[1536px]`}
        >
          <div className="flex flex-row items-center gap-x-4">
            <div className="hidden phone:flex tablet_mini:flex">
              <BarsIcon onClick={openDrawer} />
            </div>
            <div className="hidden phone:flex">
              <Logo href="/" size={20} />
            </div>
            <div className="hidden tablet_mini:flex">
              <Logo href="/" size={24} />
            </div>
            <div className="flex phone:hidden tablet_mini:hidden">
              <Logo href="/" />
            </div>
          </div>
          <div className="flex flex-row items-center gap-[10px] phone:gap-[5px]">
            {/* <div className="flex phone:hidden">
              <GoPremiumPage />
            </div> */}
            <div className="flex phone:hidden tablet_mini:hidden">
              <HeaderLang />
            </div>
            <div className="flex phone_mini:hidden">
              <AccountProfile />
            </div>
          </div>
        </div>
      </div>
      <Drawer isOpen={open} onClose={closeDrawer}>
        <DrawerContent close={closeDrawer} />
      </Drawer>
    </React.Fragment>
  );
};

type Props = {
  close: () => void;
};

function DrawerContent({ close }: Props) {
  return (
    <div className="flex w-full flex-col gap-y-2">
      <div className="flex flex-row justify-between items-center pb-3 border-b border-slate-300">
        <Logo href="/" size={20} />
        <svg
          onClick={close}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6 stroke-2"
        >
          <path
            fillRule="evenodd"
            d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div className="flex flex-col gap-4 pt-2">
        {/* <a
          href="/premium"
          className="transition-all duration-150 flex h-[45px] px-3 py-1 items-center justify-center rounded-[10px] flex-row gap-2 bg-black/10 active:bg-black/10"
        >
          <span className="flex text-[18px] font-bold text-primary">
           Offre Premium
          </span>
        </a> */}
        <HeaderLangDrawer />
        {/* <Logo href="/" size={24} /> */}
      </div>
    </div>
  );
}

export default Header;
