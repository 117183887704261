import React from "react";
import Title from "../../components/sampleModels/title";
import Paragraphe from "../../components/sampleModels/paragraphe";

const MentionLegal = () => {
  return (
    <div className="flex flex-1 w-full overflow-hidden items-start justify-center phone:px-5 px-10 py-10">
      <div className="flex w-[70%] phone:w-[100%] flex-col 2xl:w-[1536px] gap-[40px] items-center">
        <Title color="black">Mention Légales</Title>
        <div className="flex flex-col w-full justify-start gap-4">
          <Title>Éditeur du site</Title>
          <Paragraphe>
            Le site LesVoyageurs.ci est édité par Awanze Technology, une société
            Entreprise Individuelle enregistrée sous le numéro
            CI-ABJ-05-2023-A11-00134 au Registre du Commerce et du Crédit
            Mobilier de Côte d'Ivoire. <br></br>Le siège social est situé à
            Abidjan-Cocody II plateaux 7è Tranche lot 2905, ilot 242.
            <br></br>
            <br></br>
            E-mail : hello@lesvoyageurs.ci <br></br>
            <br></br>
            Directeur de la publication : Terrassone TANOU
          </Paragraphe>
        </div>

        <div className="flex flex-col w-full justify-start gap-4">
          <Title>Hébergement du site</Title>
          <Paragraphe>
            Hébergé par Vercel.com, 340 S Lemon Ave #4133 Walnut, CA 91789.
          </Paragraphe>
        </div>
      </div>
    </div>
  );
};

export default MentionLegal;
