import React from "react";
import { useAuthStore } from "../../configs/stores/auth";
import LoginPhoneInput from "./phone-input";
import { isNumber, isPhoneNumber } from "../../constants";
import CodeInput from "./code-input";
import clsx from "clsx";

const LoginPhoneForm = () => {
  const isLoadingLoginPhone = useAuthStore((e) => e.isLoadingLoginPhone);
  const isLoadingLoginPhoneConfirmation = useAuthStore(
    (e) => e.isLoadingLoginPhoneConfirmation
  );
  const loginPhone = useAuthStore((e) => e.loginPhone);
  const LoginPhoneConfirmationCode = useAuthStore(
    (e) => e.LoginPhoneConfirmationCode
  );
  const [phone, setPhone] = React.useState("");
  const [phoneErrorState, setPhoneErrorState] = React.useState({
    state: false,
    msg: "",
  });
  const [code, setCode] = React.useState("");
  const [codeErrorState, setCodeErrorState] = React.useState({
    state: false,
    msg: "",
  });
  const [step, setStep] = React.useState("0");
  const [try_times, setTry_times] = React.useState("0 / 5");
  const [errorState, setErrorState] = React.useState({
    generalMsg: "",
  });

  function onPhoneChange(value: string) {
    if (phoneErrorState.state) {
      setPhoneErrorState({ state: false, msg: "" });
    }
    setPhone(value);
  }
  function onCodeChange(value: string) {
    if (codeErrorState.state) {
      setCodeErrorState({ state: false, msg: "" });
    }
    setCode(value);
  }
  function updateStep(step: string = "0") {
    setStep(step);
  }

  function checkStep0() {
    let errorOccured = false;
    if (phone.length === 0 || !isPhoneNumber(phone)) {
      errorOccured = true;
      let msg = "Numero de téléphone incorrecte ";
      setPhoneErrorState({ state: true, msg });
    } else {
      setPhoneErrorState({ state: false, msg: "" });
    }
    return errorOccured;
  }
  function submitStep0(resend: boolean = false) {
    if (checkStep0()) {
      console.log("Error Occured !!!");
      return;
    }
    setErrorState((e) => ({
      ...e,
      generalMsg: "",
    }));
    resend && setCodeErrorState({ state: false, msg: "" });
    loginPhone(
      {
        phone: phone,
      },
      resend
    ).then((res: any) => {
      if (!!res?.data) {
        setTry_times(res.data);
      }
      if (res.success) {
        updateStep("1");
      } else {
        setErrorState((e) => ({
          ...e,
          generalMsg: res.message.toString(),
        }));
      }
    });
  }

  function checkStep1() {
    let errorOccured = false;
    // phone
    if (phone.length === 0 || !isPhoneNumber(phone)) {
      errorOccured = true;
      let msg = "Numero de téléphone incorrecte ";
      setPhoneErrorState({ state: true, msg });
    } else {
      setPhoneErrorState({ state: false, msg: "" });
    }
    // step
    if (step !== "1") {
      errorOccured = true;
    }
    if (code.length !== 5 || !isNumber(code)) {
      errorOccured = true;
      let msg = "Formulation incorrecte";
      setCodeErrorState({ state: true, msg });
    } else {
      setCodeErrorState({ state: false, msg: "" });
    }
    return errorOccured;
  }
  function submitStep1(e: any) {
    e.preventDefault();
    if (checkStep1()) {
      console.log("Error Occured !!!");
      return;
    }
    setErrorState((e) => ({
      ...e,
      generalMsg: "",
    }));
    LoginPhoneConfirmationCode({
      phone: phone,
      code: code,
    }).then((res: any) => {
      if (!res.success) {
        if (!!res?.data) {
          setTry_times(res.data);
        }
        setErrorState((e) => ({
          ...e,
          generalMsg: res.message.toString(),
        }));
      }
    });
  }
  return (
    <div className="flex flex-col gap-4">
      <LoginPhoneInput
        disableEdit={step === "1"}
        error={phoneErrorState}
        onTextChange={onPhoneChange}
        enableEdit={() => {
          setStep("0");
          setCode("");
          setTry_times("0 / 5")
        }}
      />
      {step === "0" && (
        <>
          {!!errorState.generalMsg && (
            <div className="flex w-full bg-red-500/15 pl-3 py-2 rounded-md">
              <span className="flex text-[14px] font-medium text-red-600">
                {errorState.generalMsg}
              </span>
            </div>
          )}
          <button
            onClick={() => submitStep0(false)}
            className={`flex rounded-[10px] ${
              !isLoadingLoginPhone ? "bg-primary hover:bg-hover" : "bg-hover"
            }  items-center justify-center py-[16px]`}
          >
            <span className="flex text-white font-semibold text-[18px]">
              {isLoadingLoginPhone ? "Connexion...." : "Connexion"}
            </span>
          </button>
        </>
      )}
      {step === "1" && (
        <>
          <div className="flex flex-col gap-2">
            <CodeInput error={codeErrorState} onTextChange={onCodeChange} />
            <div className="group flex flex-row self-end items-center gap-2 pr-2">
              <span
                onClick={() => !isLoadingLoginPhone && submitStep0(true)}
                className={clsx(
                  "flex underline underline-offset-2 font-medium text-[15px]",
                  !isLoadingLoginPhone
                    ? "text-primary group-hover:text-hover cursor-pointer"
                    : "text-black/40 cursor-progress"
                )}
              >
                Renvoyer le code
              </span>
              <span className="flex font-medium text-[15px]">-</span>
              <span className="flex font-medium text-[15px]">{try_times}</span>
            </div>
          </div>
          {!!errorState.generalMsg && (
            <div className="flex w-full bg-red-500/15 pl-3 py-2 border-red-500 rounded-md">
              <span className="flex text-[14px] font-medium text-red-600">
                {errorState.generalMsg}
              </span>
            </div>
          )}
          <button
            onClick={submitStep1}
            className={`flex rounded-[10px] ${
              !isLoadingLoginPhoneConfirmation
                ? "bg-primary hover:bg-hover"
                : "bg-hover"
            }  items-center justify-center py-[16px]`}
          >
            <span className="flex text-white font-semibold text-[18px]">
              {isLoadingLoginPhoneConfirmation ? "Connexion...." : "Connexion"}
            </span>
          </button>
        </>
      )}
    </div>
  );
};

export default LoginPhoneForm;
