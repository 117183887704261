import React from "react";
import { isNumber } from "../../constants";

type Props = {
  error?: { state: boolean; msg: string };
  onTextChange?: (value: string) => void;
  defaultValue?: string;
  placeholder?: string;
};

const CodeInput = ({
  defaultValue = "",
  placeholder = "Code de validation",
  error = { state: false, msg: "" },
  onTextChange = (str) => {},
}: Props) => {
  const [inputVal, setinputVal] = React.useState(defaultValue ?? "");
  const inputRef = React.useRef<HTMLInputElement>(null);
  const handleInputChange = (e: any) => {
    const inputValue = e.currentTarget.value;
    if (isNumber(inputValue) || inputValue.length === 0) {
      setinputVal(inputValue);
      onTextChange(inputValue);
    }
  };
  return (
    <div className="flex flex-col relative gap-[6px]">
      <div className="flex flex-row gap-2 items-center">
        <input
          ref={inputRef}
          type="tel"
          placeholder={placeholder}
          maxLength={5}
          onChange={handleInputChange}
          value={inputVal}
          className={`flex transition-all duration-150 flex-1 rounded-[10px] text-black pl-4 min-w-0 border-[2.5px] caret-primary ${
            error.state
              ? "caret-red-500 border-red-500 outline-none text-red-500 placeholder:text-red-500"
              : "border-slate-300 outline-none focus:border-primary"
          } font-medium text-[19px] py-3 bg-slate-100`}
        />
      </div>
      {error.msg.length !== 0 && (
        <span className="flex select-none pl-[6px] text-[14px] text-red-500">
          {error.msg}
        </span>
      )}
    </div>
  );
};

export default CodeInput;
