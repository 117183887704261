import moment from "moment";
import Voyageurslogo from "../../logo/voyageurslogo";

const XxlFooter = () => {
  return (
    <div className="flex flex-col w-full bg-dimgray">
      <div className="flex fle-row px-10 w-full justify-center">
        <div className="flex  flex-row items-start justify-between gap-5 py-[50px] w-full 2xl:w-[1536px] md:flex-col">
          <div className="flex min-w-[20%] pr-2 flex-col items-start justify-start mr-10 sm:pl-0 sm:box-border gap-4">
            <Voyageurslogo
              {...{ color: "white", className: "flex h-[50px] w-[250px]" }}
            />
            <div className="flex flex-row gap-3 items-start justify-center h-[25px]">
              <a href="/" target="_blank" className="">
                <img
                  className="flex h-full w-[25px]"
                  src="../tweeter.svg"
                  alt="tweeter"
                />
              </a>
              <a href="/" target="_blank" className="">
                <img
                  className="flex h-full w-[25px]"
                  src="../linkedin.svg"
                  alt="tweeter"
                />
              </a>
              <a
                href="https://www.facebook.com/LesVoyageursci/"
                target="_blank"
                className=""
                rel="noreferrer"
              >
                <img
                  className="flex h-full w-[28px]"
                  src="../facebook.svg"
                  alt="tweeter"
                />
              </a>
              <a href="/" target="_blank" className="">
                <img
                  className="flex h-full w-[25px]"
                  src="../tiktok.svg"
                  alt="tweeter"
                />
              </a>
              <a href="/" target="_blank" className="">
                <img
                  className="flex h-full w-[25px]"
                  src="../insta.svg"
                  alt="tweeter"
                />
              </a>
              <a href="/" target="_blank" className="">
                <img
                  className="flex h-full w-[30px]"
                  src="../youtube.svg"
                  alt="tweeter"
                />
              </a>
            </div>
          </div>
          <div className="flex flex-1 flex-row gap-5 justify-start">
            <div className="flex flex-1 flex-col items-start justify-center  gap-2 text-primary-contrast1 md:items-start md:justify-center sm:items-start sm:justify-center">
              <b className="relative text-coral">A propos</b>
              <a
                href="/a-propos"
                className="[text-decoration:none] relative  cursor-pointer hover:text-orange text-[inherit]"
              >
                LesVoyageurs
              </a>
              <a
                href="/engagement-qualite"
                className="[text-decoration:none] relative  cursor-pointer hover:text-orange text-[inherit]"
              >
                Notre engagement qualité
              </a>
              <a
                href="/mention-legale"
                className="[text-decoration:none] relative  cursor-pointer hover:text-orange text-[inherit]"
              >
                Mentions légales
              </a>
              {/* <a
                href="/presse"
                className="[text-decoration:none] relative  cursor-pointer hover:text-orange text-[inherit]"
              >
                Presse
              </a> */}
              <a
                href="/carriere"
                className="[text-decoration:none] relative  cursor-pointer hover:text-orange text-[inherit]"
              >
                Emploi
              </a>
            </div>
            <div className="flex flex-1 flex-col items-start justify-center  gap-2 text-primary-contrast1 md:items-start md:justify-center sm:items-start sm:justify-center">
              <b className="relative  text-coral">Service client</b>
              {/* <a
                href="/contactez-nous"
                className="[text-decoration:none] relative  cursor-pointer hover:text-orange text-[inherit]"
              >
                Nous contacter
              </a> */}
              <a
                href="/reclamations"
                className="[text-decoration:none] relative  cursor-pointer hover:text-orange text-[inherit]"
              >
                Réclamations
              </a>
              <a
                href="/faq"
                className="[text-decoration:none] relative  cursor-pointer hover:text-orange text-[inherit]"
              >
                FAQ
              </a>
              <a
                href="/privacy-policy"
                className="[text-decoration:none] relative text-[inherit] cursor-pointer hover:text-orange"
              >
                Confidentialité & Cookies
              </a>
              <a
                href="/cgv"
                className="[text-decoration:none] relative  cursor-pointer hover:text-orange text-[inherit]"
              >
                Conditions de Vente
              </a>
            </div>
          </div>
          <div className="flex flex-1 flex-row gap-5 items-start">
            <div className="flex flex-1 flex-col items-start justify-center gap-2 text-primary-contrast1">
              <b className="relative  text-coral">Professionnels</b>
              {/* <a
                href="/plus"
                className="[text-decoration:none] relative  cursor-pointer hover:text-orange text-[inherit]"
              >
                LesVoyageurs Plus
              </a> */}
              <a
                href="/entreprises"
                className="[text-decoration:none] relative  cursor-pointer hover:text-orange text-[inherit]"
              >
                Solutions transporteurs
              </a>
              <a
                href="https://business.lesvoyageurs.ci/signup/"
                target={"_blank"}
                className="[text-decoration:none] relative  cursor-pointer hover:text-orange text-[inherit]"
                rel="noreferrer"
              >
                Créer un compte pro
              </a>
              <a
                href="https://business.lesvoyageurs.ci/signin/"
                target={"_blank"}
                className="[text-decoration:none] relative  cursor-pointer hover:text-orange text-[inherit]"
                rel="noreferrer"
              >
                Votre espace
              </a>
              <a
                href="/publicite"
                className="[text-decoration:none] relative  cursor-pointer hover:text-orange text-[inherit]"
              >
                Publicité
              </a>
            </div>
            <div className="flex flex-1 flex-col items-start justify-center gap-5">
              <div className="flex flex-col items-start justify-start gap-[9px]">
                <b className="relative   text-coral">
                  Télécharger l’application
                </b>
                <div className="relative  text-primary-contrast1">
                  Utilisez LesVoyageurs sur votre téléphone
                </div>
              </div>
              <div className="flex flex-col items-center justify-start gap-2">
                <button className="cursor-pointer [border:none] p-0 bg-[transparent] relative w-[135px] h-10 shrink-0">
                  <img
                    className="absolute top-[0px] left-[0px] rounded w-[135px] h-10"
                    alt=""
                    src="../link--app-store.svg"
                  />
                </button>
                <button className="cursor-pointer [border:none] p-0 bg-[transparent] relative w-[135px] h-10 shrink-0">
                  <div className="absolute top-[0px] left-[0px] w-[135px] h-10">
                    <img
                      className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                      alt=""
                      src="../linkgoogleplay@2x.png"
                    />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#353535] h-13 flex items-center justify-center w-full">
        <div className="leading-[45.27px] text-gray-300 text-xl  font-light">
          © {moment().year().toString()} LesVoyageurs, Tous droits réservés
        </div>
      </div>
    </div>
  );
};

export default XxlFooter;
