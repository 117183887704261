import React from "react";

const SecondBloc = () => {
  return (
    <div className="flex w-full py-8 justify-center px-10 2xl:px-0 text-black">
      <div className="flex flex-col w-full 2xl:w-[1336px] items-center justify-between gap-[40px]">
        <div className="flex flex-row items-center h-[350px] w-full gap-10">
          <div className="flex h-full min-w-[550px] bg-[#f2f2f2]">
            <img src="../lescompanies.jpg" className="flex flex-1" alt="" />
          </div>
          <div className="flex flex-col w-full gap-4">
            <span className="flex text-[31.5px] text-coral font-extrabold leading-[1.5]">
              Rejoignez LesVoyageurs,
            </span>
            <span className="flex text-[18px] leading-[1.4] w-[80%]">
              la plateforme innovante de réservation de billets de transport en
              commun en Côte d'Ivoire et dans la sous-région ouest-africaine.
            </span>
            <span className="flex text-[18px] leading-[1.4] w-[80%]">
              En devenant partenaire, vous bénéficierez d'une multitude
              d'avantages pour dynamiser votre entreprise et améliorer
              l'expérience de vos clients :
            </span>
          </div>
        </div>
        <SecondBlocOpts
          title="Augmentez vos ventes"
          descripiton="Grâce à notre plateforme en ligne accessible 24/7, élargissez votre clientèle et boostez vos ventes de billets, en offrant à vos clients un processus de réservation simplifié et rapide."
        />
        <SecondBlocOpts
          title="Améliorer votre notoriété"
          descripiton="Renforcez la présence de votre marque sur le marché et positionnez-vous comme un acteur innovant et axé sur le client, en étant associé à notre plateforme moderne et pratique."
        />
        <SecondBlocOpts
          title="Proposez une offre premium"
          descripiton="Offrez à vos clients une expérience de première classe avec notre offre premium, comprenant des sièges de première classe, un service client dédié et des équipements supplémentaires à bord. Attirez une clientèle plus aisée et générez des revenus supplémentaires."
        />
        <SecondBlocOpts
          title="Gérez facilement vos reservations"
          descripiton="Simplifiez la gestion de vos réservations grâce à notre système centralisé et réduisez les coûts liés à l'émission de billets physiques et à la gestion manuelle des ventes."
        />
        <SecondBlocOpts
          title="Accédez a des données precieuses"
          descripiton="Grâce aux données collectées et analysées par notre plateforme, optimisez votre stratégie commerciale en adaptant vos offres, vos horaires et vos services aux besoins et aux préférences de vos clients."
        />
      </div>
    </div>
  );
};

function SecondBlocOpts({ title = "", img = "", descripiton = "" }) {
  return (
    <div className="flex flex-row w-full gap-4">
      <div className="flex w-[60px] h-[60px] bg-[#525252]">
        <img src={img} alt="pulse" className="flex flex-1" />
      </div>
      <div className="flex flex-1 gap-3 flex-col">
        <span className="flex font-bold text-[22px]">{title}</span>
        <span className="flex font-medium text-[18px] leading-[1.6] w-[80%]">
          {descripiton}
        </span>
      </div>
    </div>
  );
}

export default SecondBloc;
