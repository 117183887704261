import { TravelResultModel } from "../../../hooks/useResults";
import { postUrl } from "../../apiRequest";

export function validateVoucher(data: any) {
  return postUrl("/voucher/validate", data);
}

export function verifyTravelTripDisponibilityAndRegister(
  start: TravelResultModel,
  user: any,
  voucher: any | null
) {
  return postUrl("/search/travel/verify", { start, user, voucher });
}
