import React from "react";
import { ConvenienceModel } from "../../models/models";
import Icon from "../convenience-icons/icon";
import { primaryColor } from "../../constants";
import ConvenienceShowModal from "../modals/models/convenienve-show";

type ConvenienceItemProps = {
  item: ConvenienceModel;
};

function ConvenienceItem({ item }: ConvenienceItemProps) {
  const [isOpen, setisOpen] = React.useState(false);
  function openModal() {
    setisOpen(true);
  }
  function closeModal() {
    setisOpen(false);
  }
  return (
    <React.Fragment>
      <div onClick={openModal} className="flex flex-col cursor-pointer">
        <Icon name={item.convenience_name} color={primaryColor} size={30} />
      </div>
      <ConvenienceShowModal
        convenience={item}
        handleClose={closeModal}
        open={isOpen}
      />
    </React.Fragment>
  );
}

export default ConvenienceItem;
