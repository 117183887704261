import { useSearchStore } from "../configs/stores/search";
import React from "react";
import { useBasicsStore } from "../configs/stores/basics";
import { CompanyModel, TravelTripModel } from "../models/models";

export type TravelResultModel = {
  id: string;
  start: {
    id: string;
    number: number;
    day: string;
    hour: string;
  };
  start_date: number;
  travel: {
    travel_id: string;
    travel_company: CompanyModel;
    travel_places: number;
    travel_type: string;
    travels: TravelTripModel;
    travel_created_date: number;
  };
};

export function useResultsHook() {
  const { searchTrajet, from, to, date, vehicule, results_filter } =
    useSearchStore();
  const { userCountry } = useBasicsStore();
  const [isLoading, setIsLoading] = React.useState(true);
  const [results, setResults] = React.useState<Array<TravelResultModel>>([]);
  function search() {
    setIsLoading(true);
    searchTrajet(userCountry?.address.country)
      .then((results) => {
        const trajetDecompress = results;
        setResults(trajetDecompress);
        setIsLoading(false);
      })
      .catch((er) => {
        console.error(er);
        setIsLoading(false);
      });
  }
  function clearResults() {
    setResults([]);
  }
  React.useEffect(() => {
    if (from && to && date) {
      search();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicule, results_filter]);
  return { results, isLoading, clearResults, search };
}
