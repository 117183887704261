import React from "react";
import {
  capitalizeAllWords,
  capitalizeFirstLetter,
  isAndroid,
  primaryColor,
} from "../../constants";
import { usePopUpHook } from "../../hooks/popuphook";
import { useSearchStore } from "../../configs/stores/search";
import { getPropValue } from "../../functions/getPropValue";
import { useOpenStreetMapHook } from "../../hooks/osm";
import ReactLoading from "react-loading";

type Props = {
  placeholder?: string;
  trajetPoint: string;
  value: string | null;
};

type ResultType = {
  label: string;
  value: string;
};

const SearchInput = ({
  placeholder = "Location",
  trajetPoint,
  value,
}: Props) => {
  const selectedPoint = value;
  const { updateTrajetPoint, clearTrajetPoint, submitFormError } =
    useSearchStore();
  const { search, results, isLoading, clearResults } = useOpenStreetMapHook();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = React.useState(false);
  const { refInput, refPopUp, open, openM, closeM } = usePopUpHook<
    HTMLDivElement,
    HTMLDivElement
  >({
    onClose: () => {
      if (!selectedPoint) {
        if (!inputRef.current) {
          return;
        }
        inputRef.current.value = "";
        !!options[0] && onSelect(options[0]);
      } else {
        onSelect({
          label: capitalizeFirstLetter(selectedPoint.split(",")[0]),
          value: selectedPoint,
        });
      }
    },
  });
  const error = React.useMemo(
    () => (getPropValue(submitFormError, trajetPoint) ?? false) as boolean,
    [submitFormError, trajetPoint]
  );
  const options = React.useMemo(() => results, [results]);
  function onSensitiveClick() {
    openM();
  }
  function onSelect(e: ResultType) {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.value = e.label;
    updateTrajetPoint(trajetPoint, e.value);
    closeM();
  }
  function onFocused(has: boolean) {
    setIsFocused(has);
  }
  const handleSearch = async (value: any) => {
    if (value) {
      search(value);
    } else {
      clearResults();
    }
  };
  function clearTrPoint(slug: string) {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.value = "";
    clearResults();
    clearTrajetPoint(trajetPoint);
  }
  React.useEffect(() => {
    const lastP = selectedPoint;
    if (lastP) {
      onSelect({
        label: capitalizeFirstLetter(lastP.split(",")[0]),
        value: lastP,
      });
      if (options.length === 0) {
        search(lastP.split(",")[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPoint]);
  React.useEffect(() => {
    const lastP = selectedPoint;
    if (!lastP) {
      if (!inputRef.current) {
        return;
      }
      inputRef.current.value = "";
      clearResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPoint]);
  return (
    <div
      ref={refInput}
      className="flex flex-row flex-1 relative transition-all duration-150"
    >
      <div className="absolute inset-y-0 left-0 flex items-center pl-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill={
            !!selectedPoint
              ? primaryColor
              : error
              ? "red"
              : isFocused
              ? primaryColor
              : "rgb(148 163 184)"
          }
          className="w-6 h-6 absolute cursor-text"
        >
          <path
            fillRule="evenodd"
            d="m9.69 18.933.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 0 0 .281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 1 0 3 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 0 0 2.273 1.765 11.842 11.842 0 0 0 .976.544l.062.029.018.008.006.003ZM10 11.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      {!!selectedPoint && !isLoading && (
        <div
          onClick={() => clearTrPoint(trajetPoint)}
          className="absolute inset-y-0 right-0 flex items-center pl-1 pr-8"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill={
              !!selectedPoint
                ? primaryColor
                : error
                ? "red"
                : isFocused
                ? primaryColor
                : "rgb(148 163 184)"
            }
            className="w-6 h-6 absolute cursor-pointer"
          >
            <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
          </svg>
        </div>
      )}
      {isLoading && (
        <div
          onClick={() => clearTrPoint(trajetPoint)}
          className="absolute inset-y-0 right-0 flex h-full justify-center pl-1 pr-1"
        >
          <ReactLoading width={50} type="bubbles" color={primaryColor} />
        </div>
      )}
      <input
        ref={inputRef}
        type="text"
        onClick={onSensitiveClick}
        placeholder={placeholder}
        onFocus={() => onFocused(true)}
        onBlur={() => onFocused(false)}
        onChange={(e) => handleSearch(e.target.value ?? "")}
        className={`flex flex-1 rounded-[10px] phone:max-w-[calc(100vw-100px)] text-black pl-9 min-w-0 border-[2.5px] caret-primary ${
          !!selectedPoint
            ? "border-primary focus:outline-none"
            : error
            ? "caret-red-500 border-red-500 outline-none"
            : "border-slate-300 focus:outline-none focus:border-primary"
        } font-medium ${
          isAndroid ? "phone:text-[17px]" : "phone:text-[18px]"
        } text-[19px] py-[10px] phone:py-3 pr-1 bg-slate-200`}
      />
      <div
        ref={refPopUp}
        className="absolute select-none z-[700] flex-col top-[60px] phone:top-[60px] left-0 w-full overflow-y-auto max-h-[200px] rounded-[6px] bg-white border border-slate-200 overflow-hidden shadow-md shadow-black/30 text-[16px]"
        style={{
          visibility: open && options.length !== 0 ? "visible" : "hidden",
        }}
      >
        <div className="flex flex-col h-auto w-full">
          {options.map((e, index) => {
            return (
              <SearchInputItem
                key={index}
                item={e}
                trajetPoint={trajetPoint}
                onClick={onSelect}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

type SearchInputItemProps = {
  item: ResultType;
  trajetPoint: string;
  onClick: (item: ResultType) => void;
};

function SearchInputItem({ item, trajetPoint, onClick }: SearchInputItemProps) {
  const selectedPoint = useSearchStore((e) => {
    if (trajetPoint === "from") {
      return e.from;
    }
    return e.to;
  });
  const isSelected = selectedPoint === item.value;
  return (
    <div
      onClick={() => onClick(item)}
      className={`flex cursor-pointer flex-col leading-tight gap-[2px] py-[10px] px-3 hover:bg-slate-200 ${
        isSelected ? "text-primary" : "text-black"
      }`}
    >
      <span className="line-clamp-1 text-uppercase text-[18px] font-semibold">
        {capitalizeFirstLetter(item.label)}
      </span>
      <span className="line-clamp-2 text-[15px] leading-tight font-normal">
        {capitalizeAllWords(item.value.split(",").slice(1).join(","))}
      </span>
    </div>
  );
}

export default SearchInput;
