
const FirstBloc = () => {
  return (
    <div className="flex flex-col w-full items-center justify-start relative z-[1]">
      <div
        className="flex w-full"
        style={{
          height: (window.innerHeight - 70) * 0.8,
        }}
      >
        <img
          className="flex flex-1 object-cover object-[60%,40%]"
          src={"../../mongolfiere.jpg"}
          alt=""
        />
      </div>
      <div className="flex absolute w-full px-10 flex-col 2xl:w-[1536px] 2xl:px-0 gap-[40px] items-start pt-[4%]">
        <span className="flex w-[55%] text-white font-extrabold text-[32px] max-w-[700px]">
          Une référence dans la réservation de billets de transport en commun,
          en favorisant la mobilité durable, l'inclusion sociale et la
          prospérité économique.
        </span>
      </div>
      <img src="../TopWave.svg" alt="" className="flex w-full absolute -bottom-1" />
    </div>
  );
};

export default FirstBloc;
