import { useState, useEffect } from "react";

const returnScreenSize = () => {
  if (window.matchMedia("(max-width: 640px)").matches) {
    //@media (min-width: 640px) and (max-width: 640px)
    return "sm";
  } else if (window.matchMedia("(max-width: 1023px)").matches) {
    //@media (min-width: 768px) and (max-width: 1023px)
    return "md";
  } else if (window.matchMedia("(max-width: 1279px)").matches) {
    //@media (min-width: 1024px) and (max-width: 1279px)
    return "lg";
  } else if (window.matchMedia("(max-width: 1535px)").matches) {
    //@media (min-width: 1280px) and (max-width: 1535px)
    return "xl";
  } else {
    return "2xl";
  }
};
// screen.width;
export const useScreenSizeDetail = (): [
  type: string,
  screen: Screen,
  widthWindow: { height: number; width: number }
] => {
  // eslint-disable-next-line no-restricted-globals
  const scr = screen;
  const [screenType, setScreenType] = useState(returnScreenSize());
  const [screenValue, setScreen] = useState<Screen>(scr);
  const [screenSize, setWindowsSize] = useState({
    height: 0,
    width: 0,
  });


  useEffect(() => {
    const checkScreenSize = () => {
      // eslint-disable-next-line no-restricted-globals
      setScreen(screen);
      setWindowsSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
      if (window.matchMedia("(max-width: 640px)").matches) {// Phones
        //@media (min-width: 640px) and (max-width: 640px)
        setScreenType("sm");
      } else if (window.matchMedia("(max-width: 1023px)").matches) { // Tablettes
        //@media (min-width: 768px) and (max-width: 1023px)
        setScreenType("md");
      } else if (window.matchMedia("(max-width: 1279px)").matches) { // Ordinateurs Portables
        //@media (min-width: 1024px) and (max-width: 1279px)
        setScreenType("lg");
      } else if (window.matchMedia("(max-width: 1535px)").matches) { // Ordinateurs Gros Ecrans
        //@media (min-width: 1280px) and (max-width: 1535px)
        setScreenType("xl");
      } else { // Ordinateurs Tres Gros Ecrans
        setScreenType("2xl");
      }
    };

    checkScreenSize();

    const handleResize = () => {
      checkScreenSize();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return [screenType, screenValue, screenSize];
};
