import React from "react";

const ThirdBloc = () => {
  return (
    <div className="flex w-full py-8 justify-center px-10 2xl:px-0 text-black">
      <div className="flex flex-col w-full 2xl:w-[1336px] items-center justify-between gap-[40px]">
        <div className="flex flex-row items-center h-[350px] w-full gap-10">
          <div className="flex h-full min-w-[550px] bg-[#f2f2f2]">
            <img src="../lescompanies.jpg" className="flex flex-1" alt="" />
          </div>
          <div className="flex flex-col w-full gap-4">
            <span className="flex text-[31.5px] text-coral font-extrabold leading-[1.5]">
              LesVoyageurs, c’est pour vous !
            </span>
            <span className="flex text-[18px] leading-[1.5] w-[80%]">
              Ne manquez pas cette opportunité unique de faire partie de la
              révolution de la réservation de billets de transport en commun en
              Côte d'Ivoire et dans la sous-région ouest-africaine.
              Inscrivez-vous dès maintenant sur LesVoyageurs.com et propulsez
              votre entreprise vers de nouveaux sommets !
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdBloc;
