import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import { DestinationModel, LangModel } from "../../models/models";
import {
  getAllLangs,
  getDefaultDatas,
  getDestinationCapitals,
  getDestinationFavourites,
  getUserCountry,
} from "./functions/basics";

const LOCAL_DATASTORE_NAME = "coinchiclient-basics";

type ContextProps = {
  isLoading: boolean;
  isLoadingLangs: boolean;
  selectedLang: LangModel | null;
  langs: Array<LangModel>;
  getAll: () => Promise<any>;
  getLangs: () => Promise<any>;
  selectLang: (lang: LangModel) => void;
  userCountry: {
    [x: string]: any;
    address: {
      [x: string]: any;
      country: string;
      country_code: string;
    };
  } | null;
  getUserCountry: () => Promise<any>;
  isLoadingDestinationFavourites: boolean;
  destinationFavourites: Array<DestinationModel>;
  getDestinationFavourites: () => Promise<any>;
  isLoadingDestinationCapitals: boolean;
  destinationCapitals: Array<DestinationModel>;
  getDestinationCapitals: () => Promise<any>;
};

export const useBasicsStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      isLoading: false,
      isLoadingLangs: true,
      langs: [],
      selectedLang: null,
      userCountry: null,
      getAll: async () => {
        set(
          produce((state: ContextProps) => {
            state.isLoading = true;
          })
        );
        const res = await getDefaultDatas();
        const data = (res?.data ?? {
          langs: [],
        }) as {
          langs: Array<LangModel>;
        };
        set(
          produce((state: ContextProps) => {
            state.langs = data.langs;
            if (!state.selectedLang) {
              state.selectedLang =
                data.langs.find((e) => e.lang_code2.toLowerCase() === "fr") ??
                null;
            }
            state.isLoading = false;
          })
        );
        return data;
      },
      getLangs: async () => {
        set(
          produce((state: ContextProps) => {
            if (state.isLoadingLangs) {
              state.isLoadingLangs = true;
            }
          })
        );
        const res = await getAllLangs();
        const data = (res?.data ?? Array<LangModel>()) as Array<LangModel>;
        set(
          produce((state: ContextProps) => {
            state.langs = data;
            state.isLoadingLangs = false;
          })
        );
        return data;
      },
      selectLang: (lang) => {
        set(
          produce((state: ContextProps) => {
            state.selectedLang = lang;
          })
        );
      },
      getUserCountry: async () => {
        const result = await getUserCountry();
        set(
          produce((state: ContextProps) => {
            if (!!result) {
              state.userCountry = result;
            }
          })
        );
        return result ?? null;
      },
      isLoadingDestinationFavourites: true,
      destinationFavourites: [],
      getDestinationFavourites: async () => {
        const state = get();
        set(
          produce((state: ContextProps) => {
            if (state.isLoadingDestinationFavourites) {
              state.isLoadingDestinationFavourites = true;
            }
          })
        );
        const res = await getDestinationFavourites(
          state.userCountry?.address.country
        );
        const data = (res?.data ??
          Array<DestinationModel>()) as Array<DestinationModel>;
        set(
          produce((state: ContextProps) => {
            state.destinationFavourites = data;
            state.isLoadingDestinationFavourites = false;
          })
        );
        return data;
      },
      isLoadingDestinationCapitals: true,
      destinationCapitals: [],
      getDestinationCapitals: async () => {
        set(
          produce((state: ContextProps) => {
            if (state.isLoadingDestinationCapitals) {
              state.isLoadingDestinationCapitals = true;
            }
          })
        );
        const res = await getDestinationCapitals();
        const data = (res?.data ??
          Array<DestinationModel>()) as Array<DestinationModel>;
        set(
          produce((state: ContextProps) => {
            state.destinationCapitals = data;
            state.isLoadingDestinationCapitals = false;
          })
        );
        return data;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_bsc")),
    }
  )
);
