import React from "react";
import FormInputReservation from "./formInput";
import { Checkbox, FormControlLabel } from "@mui/material";
import { primaryColor } from "../../constants";
import { useReservationSessionStore } from "../../configs/stores/reservationSession";
import FormInputIdentifiantReservation from "./formInputIdentifiant";

const FormReservationUser = () => {
  const { updateUserFormData, userFormData, errorUserFormData } =
    useReservationSessionStore();
  const [checked] = React.useState(userFormData.saveUserChecked);
  return (
    <div className="flex flex-col gap-3 mt-2 w-full pt-3">
      <span className="flex text-[17px]">Vos informations personnels</span>
      <FormInputReservation
        title={"Nom & Prénoms"}
        placeholder="Nom Prénoms"
        defaultValue={userFormData.fullname}
        onChange={updateUserFormData("fullname")}
        error={errorUserFormData.fullname}
      />
      <FormInputIdentifiantReservation
        onChange={updateUserFormData("identifiant")}
        onChangeType={updateUserFormData("identifiant_type")}
        error={errorUserFormData.identifiant}
      />
      <FormControlLabel
        onChange={(e, bool) => {
          updateUserFormData("saveUserChecked")(bool);
        }}
        control={
          <Checkbox
            defaultChecked={checked}
            sx={{
              color: primaryColor,
              "&.Mui-checked": {
                color: primaryColor,
              },
            }}
          />
        }
        label="Créer votre compte, vous pourrez suivre toutes vos réservations"
      />
    </div>
  );
};

export default FormReservationUser;
