import React from "react";
import Title from "../../components/sampleModels/title";

const DeleteAccount = () => {
  return (
    <div className="flex flex-1 w-full overflow-hidden items-start justify-center phone:px-5 px-10 py-10">
      <div className="flex w-[70%] phone:w-[100%] flex-col 2xl:w-[1536px] gap-[40px] items-center">
        <Title color="black">Delete my account</Title>
      </div>
    </div>
  );
};

export default DeleteAccount;
