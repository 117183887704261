import React from "react";
import { DestinationModel } from "../../models/models";

type Props = {
  item: DestinationModel;
};

const DestinationRegionalItem = ({ item }: Props) => {
  return (
    <div className="keen-slider__slide number-slide max-w-[330px] w-[31.5%] min-w-[285px] h-[350px] flex flex-col border rounded-[10px] overflow-hidden">
      <div className="flex flex-1">
        <div className="flex flex-1 relative text-primary-contrast1 font-inter">
          <img
            className="rounded-base w-full h-full object-cover"
            alt="urlimg"
            src={item.destination_image}
          />
          <span className="absolute text-[24px] top-[22px] left-[26px] font-semibold">
            {item.destination_name}
          </span>
          <div className="absolute flex flex-col top-[26px] right-[22px] gap-1">
            <div className="uppercase text-[13px]">à partir de</div>
            <span className="text-[21px]">{item.destination_cost}</span>
          </div>
          <a
            href="/reservation?id=1"
            className="cursor-pointer bg-coral absolute bottom-[20px] mx-[6%] w-[88%] rounded-[7px] py-[15px] flex items-center justify-center"
          >
            <span className="text-[19px] font-inter text-primary-contrast1 font-semibold">
              Réserver
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default DestinationRegionalItem;
