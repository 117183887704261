import React from "react";
import FirstBloc from "./firstBloc";
import SecondBloc from "./secondBloc";
import ThirdBloc from "./thirdBloc";
import FourthBloc from "./fourthBloc";
import FifthBlock from "./fifthBloc";
// import SixthBlock from "./sixthBloc";

const APropos = () => {
  return (
    <div className="flex flex-col flex-1 w-full overflow-hidden items-start justify-center">
      <FirstBloc />
      <SecondBloc />
      <ThirdBloc />
      <FourthBloc />
      <FifthBlock />
      {/* <SixthBlock /> */}
    </div>
  );
};

export default APropos;
