import React from "react";

const Voyageurslogo = ({...args}) => {
  return (
    <svg
      width="187"
      height="32"
      viewBox="0 0 187 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...args}
    >
      <path
        d="M21.6931 7.966C16.301 7.966 13.1518 11.6358 13.1518 17.1024C13.1279 18.4536 13.3613 19.7972 13.8398 21.0627H13.9271V21.2832C15.1951 24.2133 17.9868 25.8576 22.1421 25.8576C24.7404 25.8576 26.526 25.5361 28.4426 24.8603V21.0359C26.6237 21.7448 25.1624 22.0332 22.9216 22.0332C19.999 22.0332 18.44 20.8422 18.018 18.3634H29.2221C29.2553 17.5907 29.2553 16.7871 29.2553 16.0144C29.2595 10.5417 26.2703 7.966 21.6931 7.966ZM18.0554 15.2479C18.4711 12.9628 19.7766 11.8995 21.6266 11.8995C23.4454 11.8995 24.6469 12.8659 24.7779 15.2479H18.0554Z"
        fill={args.color??"currentColor"}
      />
      <path
        d="M12.2102 22.8183V22.5978H12.1187C11.9797 22.2457 11.8638 21.885 11.7716 21.5181H4.96804V0H0V25.8328H14.6484C13.5651 25.0761 12.7197 24.031 12.2102 22.8183Z"
        fill={args.color??"currentColor"}
      />
      <path
        d="M13.8398 20.5373C13.869 20.6115 13.9003 20.6856 13.9315 20.7578V20.5373H13.8398Z"
        fill={args.color??"currentColor"}
      />
      <path
        d="M38.2477 14.9203C34.8428 14.3413 34.2587 14.0116 34.2587 13.0844C34.2587 12.1571 35.1026 11.7017 37.2458 11.7017C39.2173 11.6978 41.1743 12.0353 43.0287 12.699V8.86027C41.2763 8.21533 39.4575 7.95776 37.0213 7.95776C32.1821 7.95776 29.6503 9.92144 29.6503 13.4305C29.6503 16.5213 31.5647 17.9637 35.3334 18.6127C38.5803 19.1608 39.2288 19.5153 39.2288 20.5455C39.2288 21.6706 38.3204 22.1218 35.8198 22.1218C33.7744 22.1218 31.5003 21.6706 29.6815 20.7701V24.7943C31.2405 25.4702 33.7079 25.8555 36.2085 25.8555C41.3075 25.8555 44.0036 23.9578 44.0036 20.3189C44.0015 16.9808 42.2471 15.5961 38.2477 14.9203Z"
        fill={args.color??"currentColor"}
      />
      <path
        d="M69.9787 7.966C64.4266 7.966 61.0154 11.539 61.0154 17.0447C61.0154 22.5174 64.2956 25.8637 69.8498 25.8637C75.3687 25.8637 78.7881 22.3238 78.7881 16.818C78.7881 11.3123 75.4664 7.966 69.9787 7.966ZM69.9143 21.8086C67.3804 21.8086 65.8546 20.0695 65.8546 16.9149C65.8546 13.7602 67.3804 12.0232 69.9143 12.0232C72.4087 12.0232 73.9407 13.7602 73.9407 16.9149C73.9407 20.0695 72.4149 21.8086 69.9143 21.8086Z"
        fill={args.color??"currentColor"}
      />
      <path
        d="M90.6969 7.95158L86.8326 19.5709L82.8062 7.95158H77.4142L84.3964 24.7861L81.7981 32H86.5728L88.9758 24.7531L95.8915 7.95158H90.6969Z"
        fill={args.color??"currentColor"}
      />
      <path
        d="M102.063 7.95158C99.9574 7.94039 97.8636 8.26601 95.8624 8.9159V12.9813C97.535 12.3219 99.3216 11.9936 101.121 12.0149C103.882 12.0149 104.986 12.8845 104.986 14.945V15.9093C103.753 15.524 102.552 15.3633 100.862 15.3633C96.8685 15.3633 94.4011 17.3909 94.4011 20.7062C94.4011 23.8939 96.511 25.8885 100.051 25.8885C102.389 25.8885 104.175 25.2126 105.505 24.0855L105.765 25.3733H109.794V15.2891C109.792 10.1728 107.713 7.95158 102.063 7.95158ZM104.986 21.0524C104.538 21.4932 104.006 21.84 103.42 22.0722C102.834 22.3045 102.207 22.4174 101.577 22.4041C100.18 22.4041 99.1738 21.856 99.1738 20.4734C99.1738 19.0908 100.213 18.4438 101.999 18.4438C103.011 18.4513 104.017 18.6033 104.986 18.895V21.0524Z"
        fill={args.color??"currentColor"}
      />
      <path
        d="M122.226 21.9879L117.192 21.6026C115.633 21.5057 115.211 21.1183 115.211 20.4425C115.215 20.2723 115.254 20.1046 115.325 19.9498C115.397 19.795 115.499 19.6563 115.627 19.542C116.571 19.7844 117.543 19.9035 118.518 19.8965C123.091 19.8965 125.758 17.5145 125.758 14.1023C125.758 12.5898 125.272 11.269 124.265 10.3356C125.238 10.3026 126.18 10.3026 127.188 10.3026V6.92337C124.812 6.95836 122.457 7.36017 120.206 8.11436C119.649 8.01144 119.084 7.95765 118.518 7.95364C113.905 7.95364 111.243 10.2388 111.243 13.9415C111.243 15.6786 111.795 17.0962 112.866 18.0935C111.827 18.8992 111.276 19.8965 111.276 21.1843C111.257 21.7513 111.396 22.3126 111.678 22.8065C111.959 23.3004 112.373 23.708 112.872 23.9845C111.313 24.8211 110.17 26.0451 110.17 27.848C110.17 30.6153 112.604 32 117.898 32C123.775 32 127.217 29.9065 127.217 26.3995C127.228 23.6301 125.313 22.2146 122.226 21.9879ZM118.526 11.3659C120.376 11.3659 121.382 12.2354 121.382 13.9415C121.382 15.6147 120.376 16.4842 118.526 16.4842C116.641 16.4842 115.635 15.6147 115.635 13.9415C115.635 12.2354 116.641 11.3659 118.526 11.3659ZM118.2 28.8804C115.277 28.8804 114.336 28.3013 114.336 27.0465C114.336 26.3068 114.791 25.6948 115.604 25.2435L120.863 25.6556C122.318 25.7525 122.941 26.0677 122.941 26.9435C122.941 28.2292 121.675 28.8762 118.2 28.8762V28.8804Z"
        fill={args.color??"currentColor"}
      />
      <path
        d="M141.974 24.8665V21.0359C140.155 21.7448 138.694 22.0332 136.453 22.0332C133.53 22.0332 131.971 20.8422 131.549 18.3634H142.753C142.784 17.5907 142.784 16.7871 142.784 16.0144C142.784 10.5417 139.797 7.966 135.218 7.966C129.828 7.966 126.679 11.6358 126.679 17.1086C126.679 22.5813 129.828 25.8637 135.673 25.8637C138.27 25.8637 140.055 25.5505 141.974 24.8665ZM135.154 11.8851C136.972 11.8851 138.174 12.8515 138.305 15.2335H131.58C131.996 12.9566 133.301 11.8934 135.152 11.8934L135.154 11.8851Z"
        fill={args.color??"currentColor"}
      />
      <path
        d="M149.796 25.8802C151.972 25.8802 153.791 25.2373 155.317 23.9165L155.577 25.3981H159.643V7.966H154.797V20.0531C154.344 20.5547 153.788 20.9552 153.166 21.2281C152.545 21.5009 151.872 21.6398 151.193 21.6355C149.374 21.6355 148.594 20.6692 148.594 18.3511V7.966H143.757V18.8641C143.757 23.659 145.347 25.8802 149.796 25.8802Z"
        fill={args.color??"currentColor"}
      />
      <path
        d="M170.807 7.43645C169.053 7.43645 167.46 8.1123 166.097 9.75248L165.739 7.95158H161.682V25.8637H166.488V14.4855C167.625 12.7464 169.15 12.0376 170.645 12.0376C171.053 12.0357 171.461 12.0709 171.863 12.1427C172.023 12.1709 172.181 12.2116 172.335 12.2643V7.62395C171.837 7.49054 171.323 7.42742 170.807 7.43645Z"
        fill={args.color??"currentColor"}
      />
      <path
        d="M181.261 14.9203C177.852 14.3413 177.266 14.0178 177.266 13.0844C177.266 12.1509 178.109 11.7017 180.255 11.7017C182.225 11.6983 184.18 12.0358 186.033 12.699V8.86027C184.281 8.21533 182.462 7.95776 180.026 7.95776C176.453 7.95776 174.141 9.03129 173.166 11.0053V10.9888C172.915 11.5119 172.756 12.0735 172.695 12.6496C172.855 12.6778 173.013 12.7185 173.166 12.7712V12.7876C173.013 12.734 172.855 12.6933 172.695 12.6661C172.664 12.9197 172.648 13.175 172.647 13.4305C172.647 16.5213 174.563 17.9637 178.33 18.6127C181.577 19.1608 182.227 19.5153 182.227 20.5455C182.227 21.6706 181.317 22.1218 178.816 22.1218C176.771 22.1218 174.497 21.6706 172.68 20.7701V24.7943C174.237 25.4702 176.706 25.8555 179.207 25.8555C184.304 25.8555 187 23.9578 187 20.3189C187 16.9808 185.246 15.5961 181.261 14.9203Z"
        fill={args.color??"currentColor"}
      />
      <path
        d="M64.3206 3.36278L65.6447 0H59.7308L58.5938 3.25151C60.503 3.15626 62.4166 3.19344 64.3206 3.36278Z"
        fill={args.color??"currentColor"}
      />
      <path
        d="M46.7703 0H40.5342L43.3051 7.09028C45.0081 6.28241 46.7661 5.59388 48.5662 5.02975L46.7703 0Z"
        fill={args.color??"currentColor"}
      />
      <path
        d="M55.719 11.2917C54.8064 13.8241 53.8939 16.7562 53.2724 18.8188C52.6155 16.7583 51.703 13.8241 50.7904 11.2917L49.6097 7.97218C47.8886 8.67286 46.2048 9.46033 44.5648 10.3315L50.8008 25.7051H55.985L63.8154 4.45486C61.7859 4.61509 59.771 4.92364 57.7873 5.37798L55.719 11.2917Z"
        fill={args.color??"currentColor"}
      />
      <path
        d="M63.8154 4.45486C67.3783 4.18699 70.9931 4.43632 74.4396 5.46452C71.1353 4.38492 67.7184 3.67984 64.254 3.36278L63.8154 4.45486Z"
        fill={args.color??"currentColor"}
      />
    </svg>
  );
};

export default Voyageurslogo;
