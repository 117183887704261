import React from "react";

const FirstBloc = () => {
  return (
    <div className="flex w-full py-8 bg-coral justify-center px-10 2xl:px-0 text-white">
      <div className="flex flex-row w-full 2xl:w-[1536px] items-center justify-between">
        <div className="flex flex-col max-w-[50%] gap-5">
          <span className="flex text-[31.5px] font-extrabold leading-[1.5]">
            Maximisez vos ventes et offrez une expérience de réservation
            exceptionnelle avec LesVoyageurs.com
          </span>
          <span className="flex flex-col text-left text-[20px] leading-[1.5]">
            <span className="">
              L'inscription est gratuite et peut prendre seulement 5 minutes.
            </span>
            <span className="font-bold">Commencez dès aujourd'hui !</span>
          </span>
          <div className="flex flex-row px-[10%] gap-[10%]">
            <img src="../bus.svg" alt="" />
            <img src="../metro.svg" alt="" />
            <img src="../bateau.svg" alt="" />
          </div>
        </div>
        <div className="flex flex-1 justify-center">
          <div className="flex flex-col w-[70%] rounded-xl bg-white p-10 pb-6 pt-8 gap-4 text-black">
            <span className="flex text-[25px] font-extrabold leading-[1.2]">
              Pourquoi vous inscrire des maintenant ?
            </span>
            <span className="flex text-[16px] leading-[1.2]">
              Ecoulez rapidement les billets de voyages
            </span>
            <span className="flex text-[16px] leading-[1.2]">
              Réduisez au maximum les temps d'attentes dans vos gares
            </span>
            <span className="flex text-[16px] leading-[1.2]">
              Accédez à des données précieuses
            </span>
            <span className="flex text-[16px] leading-[1.2]">
              Sécurisation des moyens de paiements et transparence de la
              trésorerie
            </span>
            <div className="flex w-full h-[1px] my-[10px] bg-black/40" />
            <span className="flex text-[25px] font-extrabold leading-[1.2]">
              Créez un compte business pour commencer :
            </span>
            <span className="flex text-[12px] leading-[1.2]">
              En continuant, vous acceptez que LesVoyageurs vous envoie des
              e-mails concernant l'inscription de votre établissement.
            </span>
            <button className="flex w-full items-center justify-center py-3 bg-[#525252] hover:bg-hover">
              <a href="https://business.lesvoyageurs.ci/signup" className="flex text-white text-[18px] leading-[1.2] font-medium">Lancez-vous</a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstBloc;
