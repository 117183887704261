import React from "react";
import { useSearchStore } from "../../configs/stores/search";
import BusIcon from "../svgJsx/bus";
import SubwayIcon from "../svgJsx/subway";
import BoatIcon from "../svgJsx/boat";
import { isAndroid, primaryColor } from "../../constants";

const VehiculeToggler = () => {
  const { vehicule, updateVehicule } = useSearchStore();
  return (
    <div className={`flex flex-row flex-wrap ${isAndroid?"phone:gap-[10px]":""} gap-4 items-center phone:items-stretch`}>
      <span className="flex phone_mini:hidden phone:hidden tablet_mini:hidden tablet:hidden text-[20px] font-bold text-[#212427] leading-tight">
        Transport en :
      </span>
      <button
        className={`flex px-[20px] phone:px-[15px] py-2 transition-all duration-100 ${
          vehicule === "bus"
            ? "bg-primary hover:bg-hover phone:hover:bg-primary active:bg-primary"
            : "bg-slate-200 hover:bg-slate-200/80 phone:hover:bg-slate-200/80"
        } mb-1 items-center justify-center rounded-[6px] gap-3`}
        onClick={() => updateVehicule("bus")}
      >
        <BusIcon color={vehicule === "bus" ? "white" : primaryColor} />
        <span
          className={`flex phone:${
            vehicule === "bus" ? "" : "hidden"
          } text-[16px] font-medium ${
            vehicule === "bus" ? "text-white" : "text-gray-700"
          }`}
        >
          Bus
        </span>
      </button>
      <button
        className={`flex px-[20px] phone:px-[15px] py-2 transition-all duration-100 ${
          vehicule === "subway"
            ? "bg-primary hover:bg-hover phone:hover:bg-primary active:bg-primary"
            : "bg-slate-200 hover:bg-slate-200/80 phone:hover:bg-slate-200/80"
        } mb-1 items-center justify-center rounded-[6px] gap-3`}
        onClick={() => updateVehicule("subway")}
      >
        <SubwayIcon color={vehicule === "subway" ? "white" : primaryColor} />
        <span
          className={`hidden phone:flex phone:${
            vehicule === "subway" ? "" : "hidden"
          } text-[16px] font-medium ${
            vehicule === "subway" ? "text-white" : "text-gray-700"
          }`}
        >
          Tr./Metro
        </span>
        <span
          className={`flex phone:hidden text-[16px] font-medium ${
            vehicule === "subway" ? "text-white" : "text-gray-700"
          }`}
        >
          Train/Metro
        </span>
      </button>
      <button
        className={`flex px-[20px] phone:px-[15px] py-2 transition-all duration-100 ${
          vehicule === "boat"
            ? "bg-primary hover:bg-hover phone:hover:bg-primary active:bg-primary"
            : "bg-slate-200 hover:bg-slate-200/80 phone:hover:bg-slate-200/80"
        } mb-1 items-center justify-center rounded-[6px] gap-3`}
        onClick={() => updateVehicule("boat")}
      >
        <BoatIcon color={vehicule === "boat" ? "white" : primaryColor} />
        <span
          className={`flex phone:${
            vehicule === "boat" ? "" : "hidden"
          } text-[16px] font-medium ${
            vehicule === "boat" ? "text-white" : "text-gray-700"
          }`}
        >
          Bateau
        </span>
      </button>
    </div>
  );
};

export default VehiculeToggler;
