import React from "react";
import { ModalContainer } from "../../provider";
import { useModalsHook } from "../../hooks";
import { useModalHook } from "../../../../functions/useModalHook";
import RoundedButton from "../../../../components/buttons/roundedButton";
import FormInput from "../../../../components/inputs/formInput";
import VisaForm from "./paymentsForms/visa";
import MasterCardForm from "./paymentsForms/masterCard";
import OrangeMoneyForm from "./paymentsForms/orangeMoney";
import WaveForm from "./paymentsForms/wave";
import MtnForm from "./paymentsForms/mtn";
import MoovForm from "./paymentsForms/moov";
import { primaryColor } from "../../../../constants";

const PaymentFrom = () => {
  const [selectedMethod, setSelectedMethod] = React.useState("visa");
  const { data } = useModalsHook();
  const { closeModal } = useModalHook();
  function onSubmitForm() {
    data.confirmAction();
    closeModal();
  }

  //   React.useEffect(() => {
  //     if (!data) {
  //       closeModal();
  //     }
  //   }, [closeModal, data]);

  const methods = [
    { slug: "visa", img: "./visa.svg" },
    { slug: "mastercard", img: "./mastercard.svg" },
    { slug: "wave", img: "./wave.png" },
    { slug: "orangemoney", img: "./orangemoney.svg" },
    { slug: "mtn", img: "./mtn.png" },
    // { slug: "moov", img: "./moov.png" },
  ];
  return (
    <div className="flex flex-col items-center py-6 px-14 gap-3 bg-white rounded-md w-[650px] shadow-md max-sm:w-full">
      <span className="flex text-[25px] font-semibold">
        Procéder à votre paiement
      </span>
      <div className="flex flex-row flex-wrap justify-between w-full items-center">
        {methods.map(function (mtd) {
          return (
            <button
              className="flex transition-all border border-gainsboro rounded-md px-[10px] py-2 relative"
              style={{
                borderColor:
                  mtd.slug === selectedMethod ? primaryColor : undefined,
                borderWidth: mtd.slug === selectedMethod ? 2 : undefined,
              }}
              onClick={function () {
                setSelectedMethod(mtd.slug);
              }}
            >
              <img src={mtd.img} className="flex h-[24px]" alt={mtd.slug} />
              {mtd.slug === selectedMethod && (
                <div className="flex p-1 bg-coral items-center justify-center rounded-full absolute -right-2 -top-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={3}
                    stroke="white"
                    className="w-2 h-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                </div>
              )}
            </button>
          );
        })}
        <button
          className="flex transition-all border border-gainsboro rounded-md px-[5px] py-1 relative"
          style={{
            borderColor: "moov" === selectedMethod ? primaryColor : undefined,
            borderWidth: "moov" === selectedMethod ? 2 : undefined,
          }}
          onClick={function () {
            setSelectedMethod("moov");
          }}
        >
          <img src={"./moov.png"} className="flex h-[38px]" alt={"moov"} />
          {"moov" === selectedMethod && (
            <div className="flex p-1 bg-coral items-center justify-center rounded-full absolute -right-2 -top-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="white"
              className="w-2 h-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </div>
          )}
        </button>
      </div>
      {SwitchPaymentForm(selectedMethod)}
    </div>
  );
};

function SwitchPaymentForm(method: string) {
  switch (method) {
    case "visa":
      return <VisaForm />;
    case "mastercard":
      return <MasterCardForm />;
    case "orangemoney":
      return <OrangeMoneyForm />;
    case "wave":
      return <WaveForm />;
    case "mtn":
      return <MtnForm />;
    case "moov":
      return <MoovForm />;

    default:
      return <VisaForm />;
  }
}
const PaymentFromModel = ({ size = { h: 0, w: 0 } }) => {
  return (
    <ModalContainer {...{ size }}>
      <PaymentFrom />
    </ModalContainer>
  );
};

export default PaymentFromModel;
