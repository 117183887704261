

const Paragraphe = ({
  children = Object(),
  fontSize = 17,
  color = "black",
}) => {
  return (
    <span
      className="flex font-normal leading-relaxed"
      style={{
        fontSize,
        color,
      }}
    >
      {children}
    </span>
  );
};

export default Paragraphe;
