import React from "react";
import { TravelResultModel } from "../../hooks/useResults";
import moment from "moment";
import { TravelTypeLabel } from "../../configs/stores/search";
import { useReservationSessionStore } from "../../configs/stores/reservationSession";

type Props = {
  item: TravelResultModel;
};

const ResultItem = ({ item }: Props) => {
  const {
    travel,
    start: { hour, number },
  } = item;
  const { add } = useReservationSessionStore();
  const {
    travel_company: company,
    travels: { travel_from: from, travel_to: to },
  } = travel;
  const duration = moment.duration(hour).add(travel.travels.travel_duration);
  const duration_hour = Math.floor(duration.asHours()).toString();
  const duration_minutes = (Math.floor(duration.asMinutes()) % 60).toString();
  const arrived_hour = `${
    duration_hour.length === 1 ? "0" + duration_hour : duration_hour
  }h${
    duration_minutes.length === 1 ? "0" + duration_minutes : duration_minutes
  }min`;
  function addReservations() {
    add(item);
  }
  return (
    <>
      <div className="flex flex-col w-[92%] gap-3 p-4 py-4 mx-5 border bg-white border-gainsboro rounded-xl">
        <div className="flex flex-row items-center gap-2">
          {company?.company_logo?.link && (
            <img
              className="flex max-w-[70px] max-h-[30px]"
              src={company?.company_logo?.link} //"./capture-decran-20230126-a-2112-7@2x.png"
              alt="logoEntre"
            />
          )}
          <span className="flex text-[14px] font-semibold">
            {company.company_name.length >= 25
              ? company.company_acronym
              : company.company_name}
          </span>
        </div>
        <div className="flex flex-col flex-1 items-center gap-[6px]">
          <div className="flex flex-row w-full items-end justify-between text-[16px]">
            <span className="flex font-semibold">{from.station_name}</span>
            <span className="flex font-light text-[14px]">
              {
                TravelTypeLabel.find((e) => e.value === travel.travel_type)
                  ?.label
              }
              , Départ {number}
            </span>
            <span className="flex font-semibold break-words">
              {to.station_name}
            </span>
          </div>
          <div className="flex flex-row w-full gap-3">
            <span className="flex text-coral text-[14px]">
              {hour.split(":").join("h") + "min"}
            </span>
            <div className="flex flex-1 flex-row items-center gap-1">
              <div className="flex border-2 border-coral min-h-[9px] min-w-[9px] rounded-full" />
              <div className="flex w-full border-b-2 border-coral border-dotted" />
              <div className="flex border-2 border-coral min-h-[9px] min-w-[9px] rounded-full" />
            </div>
            <span className="flex text-coral text-[14px]">{arrived_hour}</span>
          </div>
        </div>
        <div className="flex flex-row w-full justify-between items-center bg-transparent">
          <p className="flex font-bold text-[15px]">
            {travel.travels.travel_cost.toString()} FCFA
          </p>
          <a
            href={`/reservation?id=${item.id}&date=${item.start_date.toString()}`}
            onClick={addReservations}
            className="flex cursor-pointer text-[13px] py-[8px] px-3 transition-all duration-150 bg-primary min_pc_mini:hover:bg-hover min_pc_mini:active:bg-active text-white font-medium rounded-md"
          >
            Réserver le ticket
          </a>
        </div>
      </div>
    </>
  );
};

export default ResultItem;
