import React from "react";
import { useSearchStore } from "../../../configs/stores/search";
import BusIcon from "../../../components/svgJsx/bus";
import SubwayIcon from "../../../components/svgJsx/subway";
import BoatIcon from "../../../components/svgJsx/boat";
import { primaryColor } from "../../../constants";

const VehiculeFilter = () => {
  const { vehicule, updateVehicule } = useSearchStore();
  return (
    <div
      className={`flex flex-col gap-3`}
    >
      <span className="flex text-[16px] font-bold text-[#212427] leading-tight">
        Transport en
      </span>
      <div className="flex flex-col gap-[10px]">
        <button
          className={`flex px-[20px] phone:px-[15px] py-2 transition-all duration-100 ${
            vehicule === "bus"
              ? "bg-primary hover:bg-hover active:bg-primary"
              : "bg-slate-200 hover:bg-slate-200/80"
          } items-center rounded-[6px] gap-3`}
          onClick={() => updateVehicule("bus")}
        >
          <BusIcon color={vehicule === "bus" ? "white" : primaryColor} />
          <span
            className={`flex text-[16px] font-medium ${
              vehicule === "bus" ? "text-white" : "text-gray-700"
            }`}
          >
            Bus
          </span>
        </button>
        <button
          className={`flex px-[20px] phone:px-[15px] py-2 transition-all duration-100 ${
            vehicule === "subway"
              ? "bg-primary hover:bg-hover active:bg-primary"
              : "bg-slate-200 hover:bg-slate-200/80"
          } items-center rounded-[6px] gap-3`}
          onClick={() => updateVehicule("subway")}
        >
          <SubwayIcon color={vehicule === "subway" ? "white" : primaryColor} />
          <span
            className={`hidden text-[16px] font-medium ${
              vehicule === "subway" ? "text-white" : "text-gray-700"
            }`}
          >
            Tr./Metro
          </span>
          <span
            className={`flex phone:hidden text-[16px] font-medium ${
              vehicule === "subway" ? "text-white" : "text-gray-700"
            }`}
          >
            Train/Metro
          </span>
        </button>
        <button
          className={`flex px-[20px] phone:px-[15px] py-2 transition-all duration-100 ${
            vehicule === "boat"
              ? "bg-primary hover:bg-hover active:bg-primary"
              : "bg-slate-200 hover:bg-slate-200/80"
          } items-center rounded-[6px] gap-3`}
          onClick={() => updateVehicule("boat")}
        >
          <BoatIcon color={vehicule === "boat" ? "white" : primaryColor} />
          <span
            className={`flex text-[16px] font-medium ${
              vehicule === "boat" ? "text-white" : "text-gray-700"
            }`}
          >
            Bateau
          </span>
        </button>
      </div>
    </div>
  );
};

export default VehiculeFilter;
