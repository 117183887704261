import React from "react";

const FifthBlock = () => {
  return (
    <div className="flex w-full justify-center h-[361px] mt-10">
      <div className="flex flex-row w-full mx-10 2xl:w-[1336px] 2xl:px-0 gap-10 items-center bg-coral text-white">
        <div className="flex flex-col gap-5 p-10 w-full">
          <span className="flex flex-wrap text-[20px] font-medium leading-[1.5]">
            En choisissant LesVoyageurs.com, les passagers bénéficient d'un
            service de réservation fiable et convivial, tandis que les
            compagnies de transport profitent d'une solution efficace pour gérer
            leurs réservations et développer leur activité. Ensemble, nous
            façonnons l'avenir du transport en commun en Côte d'Ivoire et dans
            la sous-région ouest-africaine.
          </span>
        </div>
        <div className="flex h-full bg-black">
          <img
            src="../carBus.jpg"
            alt=""
            className="flex flex-1 object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default FifthBlock;
