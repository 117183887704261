import React from "react";
import SearchVoyage from "../../components/searchVoyage";
import DestinationCarrousel from "../../components/destinationCarrousel";
import DestinationRegional from "../../components/destinationRegional";

const Home = () => {
  return (
    <div className="flex flex-1 flex-col w-full">
      <SearchVoyage />
      <div className="flex flex-col py-0 items-center">
        <div className="flex flex-col py-0 w-full max-w-[1536px] px-20 phone:px-5 tablet_mini:px-6">
          <div className="flex flex-col gap-10 pb-10">
            <DestinationCarrousel />
            <DestinationRegional />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
