import React from "react";
import SearchInputDate from "./date";
import SubmitForm from "../../components/searchVoyage/submit";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/fr";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";
import { useSearchStore } from "../../configs/stores/search";
import ResultsFilter, { ResultsFilterMobile } from "./filter";
import RecentsPaiements from "./recents";
import { useSearchParams } from "react-router-dom";
import SearchInput from "../../components/searchVoyage/input";
import BackButton from "./back";
import ShareButton from "./share";
import { capitalizeFirstLetter, primaryColor } from "../../constants";
import moment from "moment";
import ResultsList from "./results";

const Results = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNaviLoadBoth;
  const {
    zone,
    vehicule,
    date,
    from,
    to,
    updateTrajetPoint,
    updateDate,
    updateFormError,
  } = useSearchStore();
  function checkForm() {
    let error = false;
    if (!from) {
      error = true;
      updateFormError("from", true);
    }
    if (!to) {
      error = true;
      updateFormError("to", true);
    }
    if (!date) {
      error = true;
      updateFormError("date", true);
    }
    return error;
  }
  function submit(e: any) {
    e.preventDefault();
    if (checkForm()) {
      console.error("error: Remplissez tous les champs");
      return;
    }
    navigate(
      "/results?zone=" +
        zone +
        "&vehicule=" +
        vehicule +
        "&date=" +
        date?.toString() +
        "&from=" +
        from +
        "&to=" +
        to
    );
  }
  React.useEffect(() => {
    const fromUrl = searchParams.get("from");
    const toUrl = searchParams.get("to");
    const dateUrl = searchParams.get("date");
    if (!!fromUrl && from !== fromUrl) {
      updateTrajetPoint("from", fromUrl);
    }
    if (!!toUrl && to !== toUrl) {
      updateTrajetPoint("to", toUrl);
    }
    if (!!dateUrl && date !== parseInt(dateUrl)) {
      updateDate(parseInt(dateUrl));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    updateFormError("from", false);
    updateFormError("to", false);
    updateFormError("date", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className={`flex flex-1 flex-col w-full gap-5 phone:gap-4`}>
        <div className="flex flex-col items-center pt-2 bg-white border-b border-slate-200">
          <div className="flex flex-col w-full max-w-[1536px] px-20 phone:px-5 tablet_mini:px-6">
            <div className="flex flex-col gap-7 py-7 phone:py-1 phone:gap-3">
              <div className="flex flex-row items-center justify-between gap-4">
                <div className="flex items-center gap-6 phone:gap-4 flex-row">
                  <BackButton />
                  <span className="line-clamp-1 font-bold phone:font-semibold text-center phone:text-[17px] text-[27px] text-black">
                    Résultats des recherches
                  </span>
                </div>
                <div className="flex phone:hidden">
                  <ShareButton />
                </div>
              </div>
              <form
                onSubmit={submit}
                className="flex phone:hidden flex-row flex-wrap w-full gap-4"
              >
                <SearchInput
                  placeholder="Lieu de départ"
                  trajetPoint={"from"}
                  value={from}
                />
                <SearchInput
                  placeholder="Lieu d'arrivée"
                  trajetPoint={"to"}
                  value={to}
                />
                <SearchInputDate placeholder="Jour du voyage" value={date} />
                <SubmitForm />
              </form>
              <div className="hidden phone:flex flex-col gap-2">
                <div className="flex flex-row justify-between items-center pr-1">
                  <div className="hidden phone:flex flex-col gap-2">
                    <div className="flex items-center text-primary flex-row gap-3 text-[19px] font-semibold">
                      <span className="line-clamp-1 max-w-[40vw] leading-none">
                        {from && capitalizeFirstLetter(from.split(",")[0])}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3 10a.75.75 0 0 1 .75-.75h10.638L10.23 5.29a.75.75 0 1 1 1.04-1.08l5.5 5.25a.75.75 0 0 1 0 1.08l-5.5 5.25a.75.75 0 1 1-1.04-1.08l4.158-3.96H3.75A.75.75 0 0 1 3 10Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="line-clamp-1 max-w-[40vw] leading-none">
                        {to && capitalizeFirstLetter(to.split(",")[0])}
                      </span>
                    </div>
                    <div className="flex flex-row gap-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill={primaryColor}
                        className="w-5 h-5"
                      >
                        <path d="M5.25 12a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75H6a.75.75 0 0 1-.75-.75V12ZM6 13.25a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 0 0 .75-.75V14a.75.75 0 0 0-.75-.75H6ZM7.25 12a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75H8a.75.75 0 0 1-.75-.75V12ZM8 13.25a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 0 0 .75-.75V14a.75.75 0 0 0-.75-.75H8ZM9.25 10a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75H10a.75.75 0 0 1-.75-.75V10ZM10 11.25a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 0 0 .75-.75V12a.75.75 0 0 0-.75-.75H10ZM9.25 14a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75H10a.75.75 0 0 1-.75-.75V14ZM12 9.25a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 0 0 .75-.75V10a.75.75 0 0 0-.75-.75H12ZM11.25 12a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75H12a.75.75 0 0 1-.75-.75V12ZM12 13.25a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 0 0 .75-.75V14a.75.75 0 0 0-.75-.75H12ZM13.25 10a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 .75.75v.01a.75.75 0 0 1-.75.75H14a.75.75 0 0 1-.75-.75V10ZM14 11.25a.75.75 0 0 0-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 0 0 .75-.75V12a.75.75 0 0 0-.75-.75H14Z" />
                        <path
                          fillRule="evenodd"
                          d="M5.75 2a.75.75 0 0 1 .75.75V4h7V2.75a.75.75 0 0 1 1.5 0V4h.25A2.75 2.75 0 0 1 18 6.75v8.5A2.75 2.75 0 0 1 15.25 18H4.75A2.75 2.75 0 0 1 2 15.25v-8.5A2.75 2.75 0 0 1 4.75 4H5V2.75A.75.75 0 0 1 5.75 2Zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="flex text-[15px] text-primary font-semibold">
                        {moment(date).format("LL")}
                      </span>
                    </div>
                  </div>
                  <ShareButton />
                </div>
                <ResultsFilterMobile />
              </div>
            </div>
          </div>
        </div>
        <div className="flex phone:hidden flex-col py-0 items-center">
          <div className="flex flex-col py-0 w-full max-w-[1536px] px-20 phone:px-5 tablet_mini:px-6">
            <div className="flex flex-col gap-10 pb-10">
              <div className="flex flex-row gap-5">
                <ResultsFilter />
                <ResultsList />
                <RecentsPaiements />
              </div>
            </div>
          </div>
        </div>
        <div className="hidden phone:flex flex-col">
          <div className="flex flex-col py-0 w-full ">
            <div className="flex flex-col gap-10 pb-10">
              <ResultsList />
            </div>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default Results;
