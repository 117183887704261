import React from "react";
import FormInput from "../../../../../components/inputs/formInput";
import { useNaviLoadBoth } from "../../../../../functions/useNaviLoadBoth";

const VisaForm = () => {
  const navigate = useNaviLoadBoth;
  return (
    <div className="flex flex-col gap-3 w-full items-center">
      <FormInput title="Nom & Prenom" important />
      <FormInput title="Numero de la carte" important />
      <div className="flex flex-row w-full gap-5">
        <FormInput title={"Date d'expiration"} important />
        <FormInput title={"CCV / CVV"} important />
      </div>
      <button
        onClick={function () {
          navigate("/reservation/success?id=knvr8vh0z54vz487zv08v0");
        }}
        className="flex cursor-pointer items-center justify-center font-bold bg-coral text-white px-8 py-4 rounded-md mt-2"
      >
        Payer maintenant
      </button>
    </div>
  );
};

export default VisaForm;
