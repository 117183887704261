import React from "react";
import { useSearchStore } from "../../configs/stores/search";
import { isAndroid } from "../../constants";
import { useBasicsStore } from "../../configs/stores/basics";

const ZoneToggler = () => {
  const { userCountry } = useBasicsStore();
  const { zone, updateZone } = useSearchStore();
  React.useEffect(() => {
    if (!userCountry && zone === "local") {
      updateZone("international");
    }
  }, [updateZone, userCountry, zone]);
  return (
    <div
      className={`flex w-fit ${
        isAndroid ? "phone:gap-[10px] phone:text-[16px]" : "phone:text-[17px]"
      } gap-4 items-center text-[18px] overflow-hidden border-black/5 bg-white`}
    >
      <ZoneItem
        zone={{
          label: "Locale",
          value: "local",
        }}
      />
      <ZoneItem
        zone={{
          label: "Internationnale",
          value: "international",
        }}
      />
      {!!userCountry && zone === "local" && (
        <span className="line-clamp-1 phone_mini:hidden phone:hidden tablet_mini:hidden tablet:hidden text-[20px] font-bold text-[#212427] leading-tight">
          Pays : {userCountry?.address.country}
        </span>
      )}
    </div>
  );
};

type ZoneItemProps = {
  zone: {
    label: string;
    value: string;
  };
};
function ZoneItem({ zone }: ZoneItemProps) {
  const { userCountry } = useBasicsStore();
  const { zone: SelectedZoneValue, updateZone } = useSearchStore();
  const { label, value } = zone;
  const isSelected = React.useMemo(
    () => SelectedZoneValue === value,
    [SelectedZoneValue, value]
  );
  function updateZn() {
    if (!userCountry && value === "local") {
      return;
    }
    updateZone(value, userCountry?.address.country);
  }
  return (
    <button
      onClick={updateZn}
      className={`flex px-4 py-2 font-medium rounded-md border transition-all duration-100 ${
        isSelected
          ? "text-white bg-primary hover:bg-hover phone:hover:bg-primary active:bg-primary"
          : "text-gray-700 bg-slate-200 hover:bg-slate-200/80 phone:hover:bg-slate-200/80"
      }`}
    >
      {label}
    </button>
  );
}

export default ZoneToggler;
