import React from "react";
import BackgroundSearchImage from "../../assets/newBack.jpg";

const BackgroundSearch = () => {
  return (
    <>
      <div className="hidden select-none phone:flex phone:max-h-[635px] phone:min-h-[200px] h-[100vw] overflow-hidden">
        <img
          className="flex flex-1 object-cover scale-125"
          src={BackgroundSearchImage}
          alt=""
        />
      </div>
      <div className="flex select-none phone:hidden flex-1 h-[350px]">
        <img
          className="flex flex-1 object-cover"
          src={BackgroundSearchImage}
          alt=""
        />
      </div>
    </>
  );
};

export default BackgroundSearch;
