import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { produce } from "immer";
import { ZustandLocalStorageCrypted } from "./utils";
import {
  searchLocation,
  searchTicket,
  searchTrajet,
  searchTravelTrip,
} from "./functions/search";
import { TravelResultModel } from "../../hooks/useResults";

const LOCAL_DATASTORE_NAME = "lesvoyageurs-search";

export const TravelTypeLabel = [
  {
    value: "normal",
    label: "Normal",
  },
  {
    value: "express",
    label: "Express",
  },
];

export const TravelVehiculeLabel = [
  {
    value: "bus",
    label: "Bus",
  },
  {
    value: "train-subway",
    label: "Train/Metro",
  },
  {
    value: "boat",
    label: "Bateau",
  },
];

export const TravelDepartNumberLabel = [
  {
    value: "1",
    label: "Premier",
  },
  {
    value: "2",
    label: "Deuxième",
  },
  {
    value: "3",
    label: "Troisième",
  },
  {
    value: "4",
    label: "Quatrième",
  },
  {
    value: "5",
    label: "Cinquième",
  },
  {
    value: "6",
    label: "Sixième",
  },
  {
    value: "7",
    label: "Septième",
  },
  {
    value: "8",
    label: "Huitième",
  },
  {
    value: "9",
    label: "Neuvième",
  },
  {
    value: "10",
    label: "Dixième",
  },
  {
    value: "11",
    label: "Onzième",
  },
  {
    value: "12",
    label: "Douzième",
  },
  {
    value: "13",
    label: "Treizième",
  },
  {
    value: "14",
    label: "Quatorzième",
  },
  {
    value: "15",
    label: "Quinzième",
  },
  {
    value: "16",
    label: "Seizième",
  },
  {
    value: "17",
    label: "Dix-septième",
  },
  {
    value: "18",
    label: "Dix-huitième",
  },
  {
    value: "19",
    label: "Dix-neuvième",
  },
  {
    value: "20",
    label: "Vingtième",
  },
  {
    value: "21",
    label: "Vingt et unième",
  },
  {
    value: "22",
    label: "Vingt-deuxième",
  },
  {
    value: "23",
    label: "Vingt-troisième",
  },
  {
    value: "24",
    label: "Vingt-quatrième",
  },
  {
    value: "25",
    label: "Vingt-cinquième",
  },
  {
    value: "26",
    label: "Vingt-sixième",
  },
  {
    value: "27",
    label: "Vingt-septième",
  },
  {
    value: "28",
    label: "Vingt-huitième",
  },
  {
    value: "29",
    label: "Vingt-neuvième",
  },
  {
    value: "30",
    label: "Trentième",
  },
];

export type OpenStreetMapResultType = {
  label: string;
};

type ContextProps = {
  zone: string;
  updateZone: (zone: string, userCountry?: string | null) => void;
  vehicule: string;
  updateVehicule: (vehicule: string) => void;
  from: string | null;
  to: string | null;
  updateTrajetPoint: (slug: string, value: string) => void;
  clearTrajetPoint: (slug: string) => void;
  date: number | null;
  updateDate: (date: number) => void;
  clearDate: () => void;
  submitFormError: {
    from: boolean;
    to: boolean;
    date: boolean;
  };
  updateFormError: (slug: string, bool: boolean) => void;
  searchLocality: (
    query: string,
    countrycode?: string
  ) => Promise<
    Array<{
      label: string;
      value: string;
    }>
  >;
  results_filter: {
    price_intervalle: {
      min: number;
      max: number;
    };
    limit: number;
  };
  updateFilter: (filter: any) => Promise<void>;
  searchTrajet: (
    userCountry?: string | null
  ) => Promise<Array<TravelResultModel>>;
  searchTravelTrip: (id: string, date: number) => Promise<any>;
  searchTicket: (id: string) => Promise<any>;
};

export const useSearchStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      zone: "international",
      updateZone: (zone: string, userCountry) => {
        set(
          produce((state: ContextProps) => {
            const from = state.from;
            const to = state.to;
            // if (zone === "local" && userCountry) {
            //   if (from && from.raw.address.country !== userCountry) {
            //     state.from = null;
            //   }
            //   if (to && to.raw.address.country !== userCountry) {
            //     state.to = null;
            //   }
            // }
            if (zone === "local" && userCountry) {
              if (
                from &&
                !from
                  .toLocaleLowerCase()
                  .includes(userCountry.toLocaleLowerCase())
              ) {
                state.from = null;
              }
              if (
                to &&
                !to
                  .toLocaleLowerCase()
                  .includes(userCountry.toLocaleLowerCase())
              ) {
                state.to = null;
              }
            }
            state.zone = zone;
          })
        );
      },
      vehicule: "bus",
      updateVehicule: (vehicule: string) => {
        set(
          produce((state: ContextProps) => {
            state.vehicule = vehicule;
          })
        );
      },
      from: null,
      to: null,
      updateTrajetPoint: (slug: string, value: string) => {
        set(
          produce((state: ContextProps) => {
            if (slug === "from") {
              state.submitFormError.from = false;
              state.from = value;
            }
            if (slug === "to") {
              state.submitFormError.to = false;
              state.to = value;
            }
          })
        );
      },
      clearTrajetPoint: (slug: string) => {
        set(
          produce((state: ContextProps) => {
            if (slug === "from") {
              state.from = null;
            }
            if (slug === "to") {
              state.to = null;
            }
          })
        );
      },
      date: null,
      updateDate: (date: number) => {
        set(
          produce((state: ContextProps) => {
            state.submitFormError.date = false;
            state.date = date;
          })
        );
      },
      clearDate: () => {
        set(
          produce((state: ContextProps) => {
            state.date = null;
          })
        );
      },
      submitFormError: {
        from: false,
        to: false,
        date: false,
      },
      updateFormError: (slug: string, bool: boolean) => {
        set(
          produce((state: ContextProps) => {
            if (slug === "from") {
              state.submitFormError[slug] = bool;
            }
            if (slug === "to") {
              state.submitFormError[slug] = bool;
            }
            if (slug === "date") {
              state.submitFormError[slug] = bool;
            }
          })
        );
      },
      searchLocality: async (query, countrycode) => {
        const res = await searchLocation({ query, countrycode });
        const data = res.data ?? [];
        return data;
      },
      results_filter: {
        price_intervalle: { min: 500, max: 30000 },
        limit: 15,
      },
      updateFilter: async (filter: any) => {
        set(
          produce((state: ContextProps) => {
            state.results_filter = filter;
          })
        );
      },
      searchTrajet: async (userCountry) => {
        const state = get();
        const data = {
          from: state.from,
          to: state.to,
          date: state.date ?? 0,
          zone: state.zone ?? "",
          vehicule: state.vehicule ?? "",
          country: userCountry,
          filter: state.results_filter,
        };
        const res = await searchTrajet(data);
        const results = (res.data ?? []) as Array<TravelResultModel>;
        return results;
      },
      searchTravelTrip: async (id: string, date: number) => {
        const res = await searchTravelTrip(id, date);
        return res;
      },
      searchTicket: async (id: string) => {
        const res = await searchTicket(id);
        return res;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandLocalStorageCrypted("dat_srh")),
    }
  )
);
