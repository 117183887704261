import { MD5 } from "crypto-js";
import { ToastPosition, toast } from "react-toastify";
export const primaryColor = "#f56b2a";
export const hoverColor = "rgb(249 154 14)";
export const isAndroid = !!navigator.userAgent.match(/Android/i);
export const isIphone = !!navigator.userAgent.match(/iPhone/i);
export function colorParamsRegulatorFunc(
  color: string,
  prefix: string,
  defaultColor: string
) {
  return color.length !== 0
    ? color.startsWith(`${prefix}-`)
      ? color.trim()
      : color.startsWith("#") ||
        color.startsWith("rgb") ||
        color.startsWith("Hex")
      ? `${prefix}-[${color.trim()}]`
      : `${prefix}-${color.trim()}`
    : defaultColor;
}

export const ANY = Object();

export const compareTwoValueIsEqual = function (a: any, b: any) {
  function hash(val: any) {
    if (typeof val === "object") {
      return MD5(JSON.stringify(val)).toString();
    }
    return MD5(String(val)).toString();
  }

  return hash(a) === hash(b);
};

export const isEmail = (string: string) =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
    string
  );

export const isPhoneNumber = (phoneNumber: string) =>
  /^\d{12,14}$/.test(phoneNumber);

export const isNumber = (string: string) => /^[0-9]+$/.test(string);

export const isPassword = (string: string) =>
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{10,}$/.test(string);

export const isFullName = (fullname: string) =>
  /^[a-zA-ZÀ-ÿ'\s\-0-9]+$/.test(fullname);

export function generateSlug(title = "") {
  let slug = title.toLowerCase();
  // remplacement des caractères accentués
  slug = slug.replace(/[àáâãäå]/g, "a");
  slug = slug.replace(/[èéêë]/g, "e");
  slug = slug.replace(/[ìíîï]/g, "i");
  slug = slug.replace(/[òóôõö]/g, "o");
  slug = slug.replace(/[ùúûü]/g, "u");
  slug = slug.replace(/[ýÿ]/g, "y");
  slug = slug.replace(/[ñ]/g, "n");
  slug = slug.replace(/[ç]/g, "c");
  // remplacement des caractères spéciaux
  slug = slug.replace(/[^\w\s-]/g, "");
  // remplacement des espaces et des tirets multiples
  slug = slug.replace(/[-\s]+/g, "-");
  return slug;
}

export const ToastErrorNotifier = ({
  message = "",
  duration = 5000,
  position = "top-center",
}) => {
  toast.error(message, {
    position: position as ToastPosition,
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const ToastSuccessNotifier = ({
  message = "",
  duration = 5000,
  position = "top-center",
}) => {
  toast.success(message, {
    position: position as ToastPosition,
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const ToastWarnNotifier = ({
  message = "",
  duration = 5000,
  position = "top-center",
}) => {
  toast.warn(message, {
    position: position as ToastPosition,
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeAllWords(string: string) {
  return string.replace(/(^|\s)\S/g, function (char) {
    return char.toUpperCase();
  });
}
