import React from "react";
import FirstBloc from "./firstBloc";
import SecondBloc from "./secondBloc";
import ThirdBloc from "./thirdBloc";
import FourthBloc from "./fourthBloc";

const Entreprise = () => {
  return (
    <div className="flex flex-col flex-1 w-full overflow-hidden items-start justify-start">
      <FirstBloc />
      <SecondBloc />
      <ThirdBloc />
      <FourthBloc />
    </div>
  );
};

export default Entreprise;
