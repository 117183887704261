import { useSearchStore } from "../configs/stores/search";
import React from "react";
import { useBasicsStore } from "../configs/stores/basics";
import { useDebounce } from "@uidotdev/usehooks";
// import axios from "axios";

// Voici une liste des pays d'Afrique de l'Ouest et d'Afrique centrale avec leurs codes ISO 3166-1 alpha-2 correspondants :
// BJ : Bénin
// BF : Burkina Faso
// CV : Cap-Vert
// CI : Côte d'Ivoire
// GM : Gambie
// GH : Ghana
// GN : Guinée
// GW : Guinée-Bissau
// LR : Libéria
// ML : Mali
// MR : Mauritanie
// NE : Niger
// NG : Nigeria
// SN : Sénégal
// SL : Sierra Leone
// TG : Togo
// CM : Cameroun
// CF : République centrafricaine
// TD : Tchad
// CG : Congo
// CD : République démocratique du Congo
// GQ : Guinée équatoriale
// GA : Gabon
// RW : Rwanda
// BI : Burundi
// DJ : Djibouti
// ER : Érythrée
// ET : Éthiopie
// KE : Kenya
// KM : Comores
// MG : Madagascar
// MW : Malawi
// MU : Maurice
// MZ : Mozambique
// RE : La Réunion
// SC : Seychelles
// SD : Soudan
// SO : Somalie
// SS : Soudan du Sud
// TZ : Tanzanie
// UG : Ouganda
// YT : Mayotte
// ZM : Zambie
// ZW : Zimbabwe

export const AllowedCountries =
  "BJ,BF,CV,CI,GM,GH,GN,GW,LR,ML,MR,NE,NG,SN,SL,TG,CM,CF,TD,CG,CD,GQ,GA,RW,BI,DJ,ER,ET,KE,KM,MG,MW,MU,MZ,RE,SC,SD,SO,SS,TZ,UG,YT,ZM,ZW";

export function useOpenStreetMapHook() {
  const { zone, searchLocality } = useSearchStore();
  const { userCountry } = useBasicsStore();
  const [isLoading, setIsLoading] = React.useState(false);
  const [results, setResults] = React.useState<Array<any>>([]);
  const [query, setQuery] = React.useState("");
  const debouncedQuery = useDebounce(query, 700);
  function search(q: string = "") {
    if (q.length < 2) {
      return;
    }
    setIsLoading(true);
    setQuery(q.toLocaleLowerCase());
  }
  function searchQuery(q: string = "") {
    searchLocality(
      q,
      zone === "local" ? userCountry?.address.country_code : undefined
    )
      .then((results) => {
        setResults(results);
        setIsLoading(false);
      })
      .catch((er) => {
        console.error(er);
      });
  }
  function clearResults() {
    setResults([]);
  }
  React.useEffect(() => {
    const searchHN = () => {
      debouncedQuery.length !== 0 && searchQuery(debouncedQuery);
    };
    searchHN();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);
  return { results, isLoading, clearResults, search };
}
