import clsx from "clsx";
import React from "react";

type Props = {
  title: string;
  defaultValue?: string;
  placeholder?: string;
  error?: { state: boolean; msg: string };
  onChange?: (value: string) => void;
};

const FormInputReservation = ({
  title = "",
  defaultValue = "",
  placeholder = "",
  onChange,
  error = { state: false, msg: "" },
}: Props) => {
  return (
    <div className="flex flex-col gap-2 w-full">
      <span className="flex text-[14px]">
        {title} <span className="flex text-coral pl-2">*</span>
      </span>
      <input
        type="text"
        placeholder={placeholder}
        defaultValue={defaultValue}
        className={clsx(
          "flex w-full text-[16px] font-normal rounded-md border border-gainsboro bg-white py-[8px] outline-none px-3",
          error.state
            ? "caret-red-500 border-red-500 outline-none text-red-500 placeholder:text-red-500/70"
            : "border-slate-300 outline-none focus:border-primary"
        )}
        onChange={(e) => onChange && onChange(e.target.value.trim())}
      />
      {error.msg.length !== 0 && (
        <span className="flex select-none pl-[6px] text-[13px] text-red-500">
          {error.msg}
        </span>
      )}
    </div>
  );
};

export default FormInputReservation;
