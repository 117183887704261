import React from "react";

const SubmitForm = () => {
  return (
    <button
      type="submit"
      className="flex flex-1 py-[10px] phone:py-3 pc_mini:px-7 min-w-[150px] bg-primary hover:bg-hover phone:hover:bg-primary active:bg-active rounded-[10px] items-center justify-center font-semibold text-white"
    >
      <span className="flex text-[18px]">Rechercher</span>
    </button>
  );
};

export default SubmitForm;
