import React from "react";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";

type Props = {
  color?: string;
  size?: number;
  href?: string;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
};

const BusIcon = ({
  color = "#828282",
  size = 14,
  href,
  onClick,
}: Props) => {
  const Ratio_SVG = 26 / 14;
  const navigate = useNaviLoadBoth;
  const heigth = size.toString();
  const width = (Ratio_SVG * size).toString();
  const clickable = !!onClick || !!href;
  function click(e: any) {
    onClick && onClick(e);
    href && navigate(href);
  }
  return (
    <svg
      width={width}
      height={heigth}
      viewBox="0 0 26 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clickable ? "cursor-pointer" : undefined}
      onClick={click}
    >
      <path
        d="M5.76445 9.37262C5.28684 9.37262 4.81996 9.50832 4.42284 9.76255C4.02572 10.0168 3.7162 10.3781 3.53343 10.8009C3.35066 11.2237 3.30283 11.6889 3.39601 12.1377C3.48919 12.5865 3.71918 12.9988 4.0569 13.3223C4.39462 13.6459 4.82491 13.8663 5.29334 13.9555C5.76177 14.0448 6.24732 13.999 6.68857 13.8239C7.12982 13.6488 7.50697 13.3522 7.77232 12.9717C8.03766 12.5912 8.17929 12.1439 8.17929 11.6863C8.17929 11.0727 7.92487 10.4842 7.472 10.0503C7.01913 9.61638 6.40491 9.37262 5.76445 9.37262ZM5.76445 12.6912C5.55702 12.6912 5.35424 12.6322 5.18177 12.5218C5.00929 12.4114 4.87486 12.2545 4.79548 12.0709C4.7161 11.8872 4.69533 11.6852 4.7358 11.4903C4.77627 11.2953 4.87616 11.1163 5.02283 10.9758C5.16951 10.8352 5.35639 10.7395 5.55984 10.7007C5.76329 10.662 5.97417 10.6819 6.16581 10.7579C6.35746 10.834 6.52126 10.9628 6.6365 11.128C6.75174 11.2933 6.81326 11.4876 6.81326 11.6863C6.8124 11.9526 6.70163 12.2077 6.50512 12.396C6.30862 12.5842 6.04235 12.6904 5.76445 12.6912Z"
        fill={color}
      />
      <path
        d="M19.9461 9.37262C19.4685 9.37262 19.0016 9.50832 18.6045 9.76255C18.2074 10.0168 17.8978 10.3781 17.7151 10.8009C17.5323 11.2237 17.4845 11.6889 17.5777 12.1377C17.6708 12.5865 17.9008 12.9988 18.2385 13.3223C18.5763 13.6459 19.0065 13.8663 19.475 13.9555C19.9434 14.0448 20.429 13.999 20.8702 13.8239C21.3115 13.6488 21.6886 13.3522 21.954 12.9717C22.2193 12.5912 22.3609 12.1439 22.3609 11.6863C22.3609 11.0727 22.1065 10.4842 21.6536 10.0503C21.2008 9.61638 20.5865 9.37262 19.9461 9.37262ZM19.9461 12.6912C19.7387 12.6912 19.5359 12.6322 19.3634 12.5218C19.1909 12.4114 19.0565 12.2545 18.9771 12.0709C18.8977 11.8872 18.877 11.6852 18.9174 11.4903C18.9579 11.2953 19.0578 11.1163 19.2045 10.9758C19.3512 10.8352 19.538 10.7395 19.7415 10.7007C19.9449 10.662 20.1558 10.6819 20.3475 10.7579C20.5391 10.834 20.7029 10.9628 20.8181 11.128C20.9334 11.2933 20.9949 11.4876 20.9949 11.6863C20.994 11.9526 20.8833 12.2077 20.6868 12.396C20.4903 12.5842 20.224 12.6904 19.9461 12.6912Z"
        fill={color}
      />
      <path
        d="M24.5563 0H2.88745C2.48389 0.0136462 2.0961 0.153539 1.78336 0.398292C1.47062 0.643045 1.25014 0.97919 1.15563 1.35534L0.288098 4.38547C0.112205 5.29213 0.0158363 6.2113 0 7.13336L0 10.3031C0.00170325 10.6694 0.154356 11.0203 0.424739 11.2794C0.695122 11.5384 1.06135 11.6847 1.44373 11.6863H2.91335C2.91335 10.9625 3.21347 10.2683 3.74768 9.75641C4.2819 9.24457 5.00645 8.95702 5.76195 8.95702C6.51745 8.95702 7.242 9.24457 7.77622 9.75641C8.31044 10.2683 8.61056 10.9625 8.61056 11.6863H17.0981C17.0981 10.9625 17.3982 10.2683 17.9324 9.75641C18.4667 9.24457 19.1912 8.95702 19.9467 8.95702C20.7022 8.95702 21.4268 9.24457 21.961 9.75641C22.4952 10.2683 22.7953 10.9625 22.7953 11.6863H24.5563C24.9386 11.6847 25.3049 11.5384 25.5753 11.2794C25.8456 11.0203 25.9983 10.6694 26 10.3031V1.38325C25.9983 1.01689 25.8456 0.666005 25.5753 0.406948C25.3049 0.147891 24.9386 0.00163191 24.5563 0ZM5.99826 5.83385H2.63496L3.16907 4.62738C3.21324 4.52534 3.21352 4.41071 3.16987 4.30846C3.12621 4.20622 3.04215 4.12465 2.93601 4.08152C2.88355 4.06021 2.8272 4.04908 2.77022 4.04878C2.71324 4.04848 2.65677 4.05903 2.60408 4.0798C2.55138 4.10057 2.50352 4.13116 2.46325 4.16979C2.42299 4.20841 2.39113 4.25432 2.36952 4.30483L1.72211 5.80284C1.50351 5.74995 1.31527 5.61702 1.19785 5.43261C1.08043 5.24819 1.04319 5.02701 1.09412 4.81657L1.85807 1.86088C1.92382 1.57662 2.08708 1.32166 2.32178 1.1367C2.55648 0.951748 2.84909 0.847463 3.15289 0.840496H6.0112L5.99826 5.83385ZM12.4238 5.83385H6.86579V0.82809H12.4238V5.83385ZM18.8461 5.83385H13.2881V0.82809H18.8461V5.83385ZM25.1163 4.79796C25.1154 5.07298 25.0008 5.33646 24.7975 5.53063C24.5943 5.72481 24.3189 5.83385 24.0319 5.83385H19.7104V0.82809H24.0319C24.3181 0.828896 24.5925 0.93777 24.7955 1.1311C24.9985 1.32444 25.1137 1.58665 25.1163 1.86088V4.79796Z"
        fill={color}
      />
    </svg>
  );
};

export default BusIcon;
