import React from "react";
import Icon from "../../../convenience-icons/icon";
import { primaryColor } from "../../../../constants";
import { ConvenienceModel } from "../../../../models/models";
import ModalCustom from "../../modal";

type Props = {
  handleClose: () => void;
  open: boolean;
  convenience: ConvenienceModel;
  closeOnFinsh?: boolean;
};

const ConvenienceShowModal = ({
  handleClose = () => {},
  open = false,
  closeOnFinsh = true,
  convenience,
}: Props) => {
  const [closable] = React.useState(true);
  return (
    <ModalCustom open={open} handleClose={closable ? handleClose : undefined}>
      <div className="flex flex-col p-1 pb-2 px-2 w-[250px] pt-0 space-y-4 items-center justify-evenly">
        <Icon name={convenience.convenience_name} color={primaryColor} size={40} />
        <span className="flex text-[20px] text-center font-medium">
          {convenience.convenience_title}
        </span>
        <span className="flex text-[15px] text-center font-medium">
          {convenience.convenience_description}
        </span>
      </div>
    </ModalCustom>
  );
};

export default ConvenienceShowModal;
