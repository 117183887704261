import React from "react";
import BackgroundSearch from "./backgroundsearch";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/fr";
import SearchInput from "./input";
import ZoneToggler from "./zone";
import VehiculeToggler from "./vehicule";
import SearchInputDate from "./date";
import { isAndroid } from "../../constants";
import SubmitForm from "./submit";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";
import { useSearchStore } from "../../configs/stores/search";

const SearchVoyage = () => {
  const navigate = useNaviLoadBoth;
  const { zone, vehicule, date, from, to, updateFormError } = useSearchStore();
  function checkForm() {
    let error = false;
    if (!from) {
      error = true;
      updateFormError("from", true);
    }
    if (!to) {
      error = true;
      updateFormError("to", true);
    }
    if (!date) {
      error = true;
      updateFormError("date", true);
    }
    return error;
  }
  function submit(e: any) {
    e.preventDefault();
    if (checkForm()) {
      console.error("error: Remplissez tous les champs");
      return;
    }
    navigate(
      "/results?zone=" +
        zone +
        "&vehicule=" +
        vehicule +
        "&date=" +
        date?.toString() +
        "&from=" +
        from +
        "&to=" +
        to
    );
  }
  React.useEffect(() => {
    updateFormError("from", false);
    updateFormError("to", false);
    updateFormError("date", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className="flex flex-col w-full h-[500px] mb-[20px] phone:mb-[120px] tablet::mb-[80px] pc_mini:mb-[50px] relative items-center">
        <BackgroundSearch />
        <div className="flex flex-1 h-[150px]" />
        <div className="flex flex-col absolute top-[150px] phone:top-[30px] tablet_mini:top-[50px] tablet:top-[100px] w-full max-w-[1536px] px-20 phone:px-5 tablet_mini:px-6">
          <div className="flex flex-col gap-5 py-7 px-7 h-full w-auto bg-white border rounded-[15px] shadow-lg">
            <span
              className={`flex text-[30px] ${
                isAndroid ? "phone:text-[24px]" : "phone:text-[26px]"
              } font-bold text-[#212427] leading-tight`}
            >
              Réservez votre billet de voyage dès maintenant !
            </span>
            <ZoneToggler />
            <VehiculeToggler />
            <form
              onSubmit={submit}
              className="flex w-full flex-row flex-wrap gap-4"
            >
              <SearchInput
                placeholder="Lieu de départ"
                trajetPoint={"from"}
                value={from}
              />
              <SearchInput
                placeholder="Lieu d'arrivée"
                trajetPoint={"to"}
                value={to}
              />
              <SearchInputDate placeholder="Jour du voyage" value={date} />
              <SubmitForm />
            </form>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default SearchVoyage;
