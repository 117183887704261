import React from "react";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";

type Props = {
  color?: string;
  size?: number;
  href?: string;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
};

const SubwayIcon = ({
  color = "#828282",
  size = 16,
  href,
  onClick,
}: Props) => {
  const Ratio_SVG = 28 / 16;
  const navigate = useNaviLoadBoth;
  const heigth = size.toString();
  const width = (Ratio_SVG * size).toString();
  const clickable = !!onClick || !!href;
  function click(e: any) {
    onClick && onClick(e);
    href && navigate(href);
  }
  return (
    <svg
      width={width}
      height={heigth}
      viewBox="0 0 28 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clickable ? "cursor-pointer" : undefined}
      onClick={click}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33748 13.1824H28V13.7844H13.75L13.2673 14.0259H15.0317L14.2524 14.4597H12.3949L11.4588 14.9225H13.6643L12.8825 15.3588H10.5864L9.29984 15.9911H7.12988L8.54507 15.3588H3.4565L2.17241 15.9911H0L1.41519 15.3588H0.841764L1.62104 14.9225H2.37581L3.40627 14.4559H2.2153L2.99457 14.0221H4.36933L4.9011 13.7844H4.33748V13.1837V13.1824ZM18.0189 5.01296V8.5539C17.344 8.28532 16.6834 7.97969 16.0401 7.63832V3.27411C16.6503 3.91111 17.3123 4.49286 18.0189 5.01296ZM11.7884 9.59595H13.7341V10.6317H11.7884V9.59595ZM5.04078 9.59595H6.98407V10.6317H5.04078V9.59595ZM6.06634 5.64654H13.5111L13.8456 1.05849H7.38351C6.65937 2.49131 6.26238 4.03541 6.06634 5.64654ZM26.9034 9.07619V10.6671C26.5713 10.6393 26.2393 10.6051 25.9134 10.5659V8.87132C26.2503 8.95479 26.5811 9.02181 26.9034 9.07619ZM25.0655 8.63611V10.4521C24.666 10.3931 24.2715 10.3273 23.8818 10.2548V8.23143C24.2813 8.38149 24.6758 8.51639 25.0655 8.63611ZM22.8477 7.81031V10.0424C22.3576 9.93361 21.8846 9.816 21.4252 9.69206V7.13373C21.8957 7.37401 22.3711 7.60291 22.8477 7.81031ZM20.5197 6.64559V9.43535C19.945 9.26083 19.3961 9.07619 18.8864 8.89029V5.63389C19.4059 5.98672 19.9548 6.3269 20.5197 6.64559ZM14.1666 12.7019L27.5773 12.6058V8.45653C22.9004 7.53588 18.6904 4.46538 14.7033 0.332596C14.6188 0.229242 14.5136 0.146 14.3949 0.0885879C14.2763 0.0311757 14.147 0.000954343 14.0159 0L7.7756 0C7.27814 0 6.72554 0.316156 6.33345 0.939614C4.28846 4.44262 4.28111 8.09358 3.96744 11.7774C4.00053 12.3667 4.37668 12.7145 4.87415 12.7145H14.0159C14.0664 12.7143 14.1168 12.7101 14.1666 12.7019ZM6.13741 14.0247H11.4968L12.0298 13.7844H6.62261L6.1325 14.0259L6.13741 14.0247ZM10.5374 14.4584H5.26869L4.3289 14.9225H9.50569L10.5374 14.4584Z"
        fill={color}
      />
    </svg>
  );
};

export default SubwayIcon;
