import React from "react";
import { useScreenSizeDetail } from "../../functions/getScreenDetail";
import SmFooter from "./screens/sm";
import XxlFooter from "./screens/2xl";
import XlFooter from "./screens/xl";
import LgFooter from "./screens/lg";
import MdFooter from "./screens/md";

const Footer = () => {
  const [type] = useScreenSizeDetail();
  // alert(type)
  switch (type) {
    case "sm":
      return <SmFooter />;
    case "md":
      return <MdFooter />;
    case "lg":
      return <LgFooter />;
    case "xl":
      return <XlFooter />;
    case "2xl":
      return <XxlFooter />;
    default:
      return <div />;
  }
};

export default Footer;
