import React from "react";
import Title from "../../components/sampleModels/title";
import Paragraphe from "../../components/sampleModels/paragraphe";


const EngagementQualite = () => {
  return (
    <div className="flex flex-1 w-full overflow-hidden items-start justify-center phone:px-5 px-10 py-10">
      <div className="flex w-[70%] phone:w-[100%] flex-col 2xl:w-[1536px] gap-[40px] items-center">
        <Title color="black">Notre engagement de qualité</Title>
        <div className="flex flex-col w-full justify-start gap-4">
          <Paragraphe>
            Chez LesVoyageurs, notre engagement de qualité repose sur les valeurs fondamentales qui définissent notre plateforme et notre service. Nous nous engageons à offrir à nos clients et partenaires une expérience exceptionnelle en nous appuyant sur ces principes :
            <br></br><br></br>
            1. Facilité d'utilisation : Nous nous engageons à fournir une plateforme intuitive et conviviale, permettant aux clients de rechercher, comparer et réserver des billets de transport en commun en toute simplicité et rapidité.
            <br></br><br></br>

            2. Sécurité et confidentialité : La protection des données de nos clients et partenaires est primordiale. Nous nous engageons à respecter les normes de sécurité les plus strictes et à protéger la confidentialité des informations personnelles et financières.
            <br></br><br></br>

            3. Service client irréprochable : Nous mettons à disposition un service client dévoué et compétent pour répondre aux questions, résoudre les problèmes et accompagner nos clients et partenaires tout au long de leur expérience sur LesVoyageurs.
            <br></br><br></br>

            4. Sélection rigoureuse des partenaires : Nous nous engageons à établir des partenariats uniquement avec des compagnies de transport en commun fiables et réputées, garantissant ainsi à nos clients des services de qualité et une expérience de voyage sécurisée et confortable.
            <br></br><br></br>

            5. Innovation et amélioration continue : Nous nous engageons à investir constamment dans l'innovation et l'amélioration de notre plateforme, afin de proposer les meilleurs services possibles et de rester à la pointe des tendances et des besoins du marché.
            <br></br><br></br>

            6. Responsabilité et transparence : Chez LesVoyageurs, nous prenons notre responsabilité envers nos clients, partenaires et la communauté très au sérieux. Nous nous engageons à être transparents dans nos pratiques commerciales, nos tarifs et nos politiques, et à maintenir un dialogue ouvert avec toutes les parties prenantes.
            <br></br><br></br>

            En faisant confiance à LesVoyageurs, vous bénéficiez d'un service de réservation de billets de transport en commun de qualité supérieure, qui place vos besoins et votre satisfaction au cœur de notre mission.
          </Paragraphe>
        </div>

      </div>
    </div>
  );
};

export default EngagementQualite;
