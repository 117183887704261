import React from "react";

const ThirdBloc = () => {
  return (
    <div className="flex w-full justify-center mt-4">
      <div className="flex flex-row w-full px-10 2xl:w-[1536px] 2xl:px-0 gap-10 items-center">
        <div className="flex flex-col gap-5 py-4 w-full">
          <span className="flex text-[35px] font-extrabold leading-[1.2]">
            Une plateforme innovante de reservation de billets de transport en
            commun (bus, metro et bateaux)
          </span>
          <span className="flex text-[20px] font-medium leading-[1.5]">
            Notre objectif est de faciliter les déplacements des voyageurs en
            leur offrant une solution simple, rapide et sécurisée pour réserver
            leurs billets en ligne, tout en aidant les compagnies de transport à
            améliorer leur visibilité et à optimiser leur gestion des
            réservations.
          </span>
        </div>
        <div className="flex w-full">
          <img src="../hommeBus.jpg" alt="" className="flex flex-1 object-cover" />
        </div>
      </div>
    </div>
  );
};

export default ThirdBloc;
