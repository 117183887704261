import React from "react";
import Title from "../../components/sampleModels/title";
import Paragraphe from "../../components/sampleModels/paragraphe";

const CGV = () => {
  return (
    <div className="flex flex-1 w-full overflow-hidden items-start justify-center px-10 py-10">
      <div className="flex w-[70%] flex-col 2xl:w-[1536px] gap-[40px] items-center">
        <Title color="black">Condition générale de vente</Title>
        <div className="flex flex-col w-full justify-start gap-4">
          <Title>Qui sommes-nous ?</Title>
          <Paragraphe>
            Le site www.lesvoyageurs.ci (ci-après dénommé « le Site LesVoyageurs
            »), est édité par la Société Awanze, EU au capital de X, dont le
            Siège Social est Abidjan, identifiée au Registre de commerce sous le
            numéro : 000 000, Numero de compte contribuable : 000000 (ci-après
            dénommée « la Société »). Directeur de publication : M. Terrassone
            TANOU.
          </Paragraphe>
          <Paragraphe>
            Plus de détails sont disponibles dans la rubrique Mentions Légales.
          </Paragraphe>
          <Paragraphe>
            Toutes les commandes effectuées sur le Site LesVoyageur sont soumises aux
            présentes Conditions Générales de Vente. La Société se réserve le
            droit d’adapter ou de modifier à tout moment les présentes, la
            version des conditions générales de vente applicable à toute
            transaction étant celle figurant en ligne sur le Site LesVoyageur au
            moment du paiement.
          </Paragraphe>
        </div>

        <div className="flex flex-col w-full justify-start gap-4">
          <Title>Définitions</Title>
          <Paragraphe>
            Client : Un Utilisateur qui achète un Billet sur le Site LesVoyageur pour
            soi-même et/ou pour plusieurs personnes (voir “Passagers”).
          </Paragraphe>

          <Paragraphe>
            Billet : Titre de transport fourni par LesVoyageur pour effectuer un
            Trajet. Selon les conditions des Opérateurs, le Billet peut n’être
            disponible que quelques jours avant la date de départ.
          </Paragraphe>

          <Paragraphe>
            Utilisateur : Individu utilisant le Site LesVoyageur et les services
            proposées par le Site LesVoyageur.
          </Paragraphe>

          <Paragraphe>
            Compte Personnel : Un Utilisateur peut créer un compte sur le Site
            LesVoyageur et stocker ses Données Personnelles sur son Compte Personnel.
            Chaque Compte Personnel est accessible grâce à une adresse mail et
            un mot de passe. L’adresse mail sera utilisée pour les réservations
          </Paragraphe>

          <Paragraphe>
            Données Personnelles : Ce sont toutes les informations nécessaires à
            la création d’un Compte Personnel et à la réservation d’un Trajet.
            Plus de détails sur la composition des Données Personnelles sont
            disponibles dans ces présentes CGV. Plus de détails sur le stockage
            des informations sont disponibles dans la rubrique Confidentialité.
          </Paragraphe>

          <Paragraphe>
          LesVoyageurs :  Marque de la société ARTEA utilisée pour communiquer sur le Site LesVoyageur. 
          </Paragraphe>
          <Paragraphe>
          Opérateur : Société qui effectue le transport du ou des Passager(s). LesVoyageur n’est pas responsable du transport. 
          </Paragraphe>

          <Paragraphe>
          Passager :  Lors d’un achat, le Client réserve des Billets pour les Passagers qui effectueront le Trajet. Le Client peut faire partie des Passagers. 
          L’ensemble de la commande sera au nom du Client mais chaque Passager aura son titre de transport personnel.          
          </Paragraphe>

          <Paragraphe>
          Prix : Montant payé par le Client pour réserver un Billet.
          </Paragraphe>

          <Paragraphe>
          Trajet : Déplacement qui s’effectue avec un moyen de transport de passagers dont l’accès est permis grâce au(x) Billet(s) acheté(s) par le Client sur le Site LesVoyageur. 
          </Paragraphe>

        </div>


        <div className="flex flex-col w-full justify-start gap-4">
          <Title>Accès au Site LesVoyageurs </Title>
          <Paragraphe>
          Le Site LesVoyageurs est accessible sur ordinateur, tablette, téléphone et via 
          l’application mobile. Il est possible d’accéder au Site Kombo 24h/24 et 7j/7, 
          uniquement avec une connexion Internet. 
          </Paragraphe>
          <Paragraphe>
          Le Site LesVoyageurs est régulièrement mis à jour et son contenu peut être modifié à tout moment.
          </Paragraphe>
          <Paragraphe>
          Le Site LesVoyageurs est accessible en Français et Anglais.
          </Paragraphe>

          <Paragraphe>
          L’utilisation du Site LesVoyageurs est limitée aux usages décrits dans le paragraphe 12. 
          </Paragraphe>

          <Paragraphe>
          Le Site LesVoyageurs se réserve le droit de suspendre l’accès au Site ou à l’application mobile à  l’Utilisateur qui ne respecte pas les conditions d'utilisation. 
          </Paragraphe>
        </div>
        

        <div className="flex flex-col w-full justify-start gap-4">
          <Title>Nous contacter </Title>
          <Paragraphe>
          Le service client du Site LesVoyageurs est disponible pour assister et accompagner 
          l'Utilisateur avant, pendant et après la réservation d’un ou plusieurs Billet(s). 
          Le service client du Site LesVoyageurs répond 7 jours sur 7 de 9h à 23h via le formulaire de contact. 
          A titre expérimental, le service client est également disponible  par téléphone, 
          uniquement en français, au +225 07 49 98 52 52 du lundi au vendredi de 9h à 13h.  
          </Paragraphe>
        </div>

        <div className="flex flex-col w-full justify-start gap-4">
          <Title>Cartes de réduction </Title>
          <Paragraphe>
          Le site LesVoyageurs permet d’acheter des cartes de réduction de différentes compagnies
          (UTB, SBTA, etc). La vente de ces cartes est soumise aux Conditions Générales 
          de Vente détaillées de l’Opérateur.
          </Paragraphe>
        </div>

        <div className="flex flex-col w-full justify-start gap-4">
          <Title>Droit de rétractation </Title>
          <Paragraphe>
          En accord avec les articles L. 221-2 et L. 221-28 du Code de la consommation, 
          le Client est informé que les Billets vendus par la Société ne sont pas soumis à 
          l’application d’un droit de rétractation. 
          </Paragraphe>
        </div>


      </div>
    </div>
  );
};

export default CGV;
