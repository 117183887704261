import React from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";

const styleBox = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "0px solid transparent",
  radiusBorder: 8,
  boxShadow: 1,
};

const ModalCustom = ({
  open = false,
  closable = true,
  handleClose = () => {},
  children = <div />,
  zeroPadding = false,
  hideCloseButton = false,
  backdropFilter = 2,
  style = {},
}) => {
  return (
    <Modal
      open={open}
      onClose={closable ? handleClose : undefined}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          style: { backdropFilter: `blur(${backdropFilter.toString()}px)` },
          timeout: 250,
        },
      }}
    >
      {open ? (
        <Fade in={open}>
          <Box
            sx={{
              ...styleBox,
              minWidth: 200,
              borderRadius: 2,
              outline: "none",
            }}
          >
            <div className="flex relative">
              {!zeroPadding && (
                <div className={`flex p-2 ${closable ? "pt-5" : ""}`}>
                  {children}
                </div>
              )}
              {zeroPadding && (
                <div className="flex" style={style}>
                  {children}
                </div>
              )}
              {closable && (
                <button
                  style={{
                    display: !hideCloseButton ? "flex" : "none",
                  }}
                  className="flex transition-colors absolute p-1 bg-whitegrey hover:bg-darkgrey/20 rounded-full top-2 right-2"
                  onClick={handleClose}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="black"
                    className="w-4 h-4"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              )}
            </div>
          </Box>
        </Fade>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default ModalCustom;
