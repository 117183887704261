import React from "react";
import LangIcon from "../svgJsx/lang";
import ArrowDownMiniIcon from "../svgJsx/arrowdownmini";
import { usePopUpHook } from "../../functions/popuphook";
import { useBasicsStore } from "../../configs/stores/basics";
import { LangModel } from "../../models/models";
import { primaryColor } from "../../constants";

const HeaderLang = () => {
  const { langs, selectedLang } = useBasicsStore();
  const { refInput, refPopUp, open, closeM, switchMenu } = usePopUpHook<
    HTMLButtonElement,
    HTMLDivElement
  >();
  function onClick() {
    switchMenu();
  }
  function close() {
    closeM();
  }
  return (
    <>
      <button
        ref={refInput}
        onClick={onClick}
        className="transition-all duration-150 flex h-[50px] px-3 py-1 items-center justify-center rounded-[10px] flex-row gap-2 hover:bg-black/15 active:bg-black/10"
      >
        <ArrowDownMiniIcon color={open ? primaryColor : undefined} />
        <div className="hidden tablet_mini:flex">
          <LangIcon size={22} stroke={1.8} />
        </div>
        <div className="flex tablet_mini:hidden">
          <LangIcon size={25} stroke={1.8} />
        </div>
        <span className="flex text-[15px] font-medium">
          {selectedLang?.lang_code2.toUpperCase()}
        </span>
      </button>
      <div
        ref={refPopUp}
        className="absolute select-none z-[700px] flex-col top-[80px] w-[200px] max-h-[320px] p-1 overflow-y-auto rounded-lg bg-white border overflow-hidden shadow-md shadow-black/5 text-[16px]"
        style={{
          visibility: open ? "visible" : "hidden",
        }}
      >
        {langs.map((item) => {
          return <ButtonLang key={item.lang_id} item={item} close={close} />;
        })}
      </div>
    </>
  );
};

export const HeaderLangDrawer = () => {
  const { langs, selectedLang } = useBasicsStore();
  const { refInput, refPopUp, open, closeM, switchMenu } = usePopUpHook<
    HTMLButtonElement,
    HTMLDivElement
  >();
  function onClick() {
    switchMenu();
  }
  function close() {
    closeM();
  }
  return (
    <div className="flex relative w-full">
      <button
        ref={refInput}
        onClick={onClick}
        className="transition-all relative w-full duration-150 flex h-[50px] px-3 py-1 items-center justify-center rounded-[10px] flex-row gap-2 bg-black/10 active:bg-black/10"
      >
        <div className="flex">
          <LangIcon size={25} stroke={1.8} />
        </div>
        <div className="flex flex-1 px-2">
          <span className="line-clamp-1 text-[15px] font-medium">
            {selectedLang?.lang_name.toUpperCase()}
          </span>
        </div>
        <ArrowDownMiniIcon color={open ? primaryColor : undefined} />
      </button>
      <div
        ref={refPopUp}
        className="absolute select-none z-[700] flex-col top-[60px] w-full max-h-[320px] p-1 overflow-y-auto rounded-lg bg-white border border-black/5 overflow-hidden shadow-md shadow-black/5 text-[16px]"
        style={{
          visibility: open ? "visible" : "hidden",
        }}
      >
        {langs.map((item) => {
          return <ButtonLang key={item.lang_id} item={item} close={close} />;
        })}
      </div>
    </div>
  );
};

type ButtonLangProps = {
  item: LangModel;
  close: () => void;
};

function ButtonLang({ item, close }: ButtonLangProps) {
  const { selectLang, selectedLang } = useBasicsStore();
  function onclick() {
    selectLang(item);
    close();
  }
  const isSelected = item.lang_id === selectedLang?.lang_id;
  return (
    <button
      onClick={onclick}
      className="flex w-full px-3 py-1 h-[40px] text-[18px] font-medium items-center hover:bg-black/15 active:bg-black/10 rounded-[10px]"
    >
      <span
        className={isSelected ? "text-primary line-clamp-1" : "line-clamp-1"}
      >
        {item.lang_name}
      </span>
    </button>
  );
}

export default HeaderLang;
