import React from "react";
import ReactDOM from "react-dom/client";
import "./global.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./contexts/auth/provider";
import ModalsProvider from "./contexts/modals/provider";
import BasicsProvider from "./contexts/basics/provider";
import { ThemeProvider } from "@mui/material/styles";
import { themeMui } from "./themes/mui";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={themeMui}>
        <BasicsProvider>
          <AuthProvider>
            <ModalsProvider>
              <App />
            </ModalsProvider>
          </AuthProvider>
        </BasicsProvider>
        <ToastContainer />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
