import React from "react";
import MoyenPayment from "./moyenPayment";
import { useReservationSessionStore } from "../../configs/stores/reservationSession";
import { useAuthStore } from "../../configs/stores/auth";
import { TravelResultModel } from "../../hooks/useResults";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";

type Props = {
  start: TravelResultModel;
};

const ReservationCard = ({ start }: Props) => {
  const navigate = useNaviLoadBoth;
  const { isAuthenticated, user } = useAuthStore();
  const {
    userFormData,
    verifyTravelTripDisponibilityAndRegister,
    checkUserFormData,
    validateVoucher,
    clearVoucher,
    voucher,
  } = useReservationSessionStore();
  const {
    travel,
    start: { id: startId },
  } = start;
  const [isOpenBon, setisOpenBon] = React.useState(false);
  const [verification, setVerification] = React.useState(false);
  const [validating, setValidating] = React.useState(false);
  const [errorCode, setErrorCode] = React.useState({
    state: false,
    message: "",
  });
  const [code, setCode] = React.useState("");
  function onCodeChange(e: any) {
    e.preventDefault();
    let str = e.target?.value;
    if (errorCode.state) {
      setErrorCode({
        state: false,
        message: "",
      });
    }
    if (str) {
      setCode(str.toUpperCase());
    } else {
      setCode("");
    }
  }
  function ValidateVoucher() {
    setValidating(true);
    validateVoucher(code, travel.travel_id, startId).then((res) => {
      setValidating(false);
      if (res.success) {
        setCode("");
      }
      if (!res.success) {
        setErrorCode({
          state: true,
          message: res.message.toString(),
        });
      }
    });
  }
  function voucherTypeViewer(type: string) {
    switch (type) {
      case "percent":
        return voucher?.voucher_rate.toString() + "%";
      case "fixed":
        return voucher?.voucher_rate.toString() + "Frcfa";
      default:
        return voucher?.voucher_rate.toString();
    }
  }
  function voucherCostApplication(voucherType: string | undefined | null) {
    const cost = travel.travels.travel_cost;
    if (voucher) {
      const voucherRate = voucher.voucher_rate;
      const percent = cost - (cost * voucherRate) / 100;
      const diff = cost - voucherRate;
      switch (voucherType) {
        case "percent":
          return percent;
        case "fixed":
          return diff >= 0 ? diff : 100;
        default:
          return cost;
      }
    }
    return cost;
  }
  function StartPayment() {
    if (!isAuthenticated && checkUserFormData()) {
      console.log("Champs incorrectes !");
      return null;
    }
    setVerification(true);
    const userData = {
      user_id: isAuthenticated ? user?.user_id : null,
      user_fullname: isAuthenticated
        ? user?.user_fullname
        : userFormData.fullname,
      user_identifiant_type: "phone",
      //  isAuthenticated
      //   ? user?.user_phone
      //     ? "phone"
      //     : "email"
      // : userFormData.identifiant_type,
      user_identifiant: isAuthenticated
        ? user?.user_phone
        : userFormData.identifiant,
      save_me: isAuthenticated ? false : userFormData.saveUserChecked,
    };
    verifyTravelTripDisponibilityAndRegister(start, userData, voucher).then(
      (res: any) => {
        setVerification(false);
        if (res.success) {
          navigate(res.data.ticket_payment_payload.link);
        }
      }
    );
  }
  return (
    <div className="flex w-[350px] max_tablet_min:w-full h-fit flex-col overflow-hidden border-gainsboro border rounded-[10px]">
      <div className="flex w-full items-center justify-center py-4 bg-gainsboro/50">
        <span className="flex font-medium text-[20px]">Votre commande</span>
      </div>
      <div className="flex flex-col items-center p-12 pb-7 px-8 bg-white gap-2">
        <span className="flex text-[16px] text-center text-black font-normal">
          Prix du voyage
        </span>
        <span className="flex font-bold text-[28px]">
          {voucherCostApplication(voucher?.voucher_type)} FCFA
        </span>
        {voucher && (
          <span className="flex text-[16px] text-center text-green-500 font-normal">
            Code Valide -{voucherTypeViewer(voucher.voucher_type)}
          </span>
        )}
        <MoyenPayment />
        <div className="flex flex-col w-full mt-7 gap-4">
          <span className="flex text-[10px] leading-relaxed">
            Dès validation du paiement, vous pourrez télécharger le ticket à QR
            Code directement sur la page suivante, ou aussi, vous recevrez un
            lien de téléchargement directement par sms et email pour conserver
            le billet jusqu’au jours du voyage
          </span>
          <div className="flex flex-col gap-[6px]">
            {voucher && (
              <div className="flex px-3 py-2 flex-row items-center gap-3 my-2 w-fit rounded-md bg-green-500/50">
                <div className="flex flex-col">
                  <span className="flex text-[16px] font-bold">
                    {voucher.voucher_code}
                  </span>
                  <span className="flex text-[14px]">
                    {voucherTypeViewer(voucher.voucher_type)}
                  </span>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="black"
                  className="w-5 h-5 hover:stroke-black/50 active:stroke-black/70"
                  onClick={clearVoucher}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </div>
            )}
            <div
              onClick={() => setisOpenBon(!isOpenBon)}
              className={`flex flex-row w-fit transition-all duration-150 cursor-pointer items-center gap-2 px-3 py-2 rounded-md hover:bg-slate-300/25 active:bg-slate-300/40 ${
                isOpenBon ? "ring-1 ring-primary" : ""
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"
                />
              </svg>
              <span className="flex text-[13px] select-none">
                Saisir le code de réduction
              </span>
              {!isOpenBon ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[18px] h-[18px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[18px] h-[18px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m4.5 15.75 7.5-7.5 7.5 7.5"
                  />
                </svg>
              )}
            </div>
            <div
              className={`flex flex-col gap-[2px] transition-all duration-300 overflow-hidden ${
                isOpenBon ? "h-[50px]" : "h-0"
              }`}
            >
              <div className={`flex flex-row gap-[6px] h-[35px]`}>
                <input
                  type={"text"}
                  value={code}
                  disabled={validating}
                  onChange={onCodeChange}
                  placeholder="Entrer le code"
                  className={`bg-slate-200/40 flex w-[170px] h-[35px] border ${
                    errorCode.state
                      ? "border-red-500 text-red-500"
                      : "border-black text-black"
                  } rounded-md text-[15px] font-medium py-2 px-3`}
                />
                <div
                  onClick={!validating ? ValidateVoucher : undefined}
                  className="flex px-3 py-2 cursor-pointer select-none transition-all duration-150 bg-primary hover:bg-hover active:bg-active rounded-md text-white"
                >
                  <span className="flex text-[15px]">
                    {validating ? "Valid..." : "Valider"}
                  </span>
                </div>
              </div>
              {errorCode.state && (
                <span className="flex text-[12px] text-red-500">
                  {errorCode.message}
                </span>
              )}
            </div>
          </div>
          <button
            onClick={!verification ? StartPayment : undefined}
            className="flex cursor-pointer items-center justify-center font-bold transition-all duration-150 bg-primary hover:bg-hover active:bg-activ text-white px-6 py-3 rounded-[15px]"
          >
            {!verification
              ? "Payer maintenant"
              : "Vérifions de disponibilité du ticket..."}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReservationCard;
