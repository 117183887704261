import React from "react";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";

type Props = {
  color?: string;
  size?: number;
  href?: string;
  stroke?: number;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
};

const UserIconOutlined = ({
  color = "#F2F2F2",
  stroke = 2,
  size = 22,
  href,
  onClick,
}: Props) => {
  const Ratio_SVG = 21 / 22;
  const navigate = useNaviLoadBoth;
  const heigth = size.toString();
  const width = (Ratio_SVG * size).toString();
  const clickable = !!onClick || !!href;
  function click(e: any) {
    onClick && onClick(e);
    href && navigate(href);
  }
  return (
    <svg
      width={width}
      height={heigth}
      viewBox="0 0 21 22" //Ratio_SVG
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clickable ? "cursor-pointer" : undefined}
      onClick={click}
      stroke={color}
      strokeWidth={stroke.toString()}
    >
      <path
        d="M14 6.625C14 8.558 12.433 10.125 10.5 10.125C8.567 10.125 7 8.558 7 6.625C7 4.692 8.567 3.125 10.5 3.125C12.433 3.125 14 4.692 14 6.625Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 12.75C7.11726 12.75 4.375 15.4923 4.375 18.875H16.625C16.625 15.4923 13.8827 12.75 10.5 12.75Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserIconOutlined;
