import { getUrl, postUrl } from "../../apiRequest";
import queryString from "query-string";

type searchTrajetProps = {
  from: any;
  to: any;
  date: number;
  zone: string;
  vehicule: string;
  country?: string | null;
  filter: any;
};

export function searchTrajet(props: searchTrajetProps) {
  return postUrl("/search/travel", props);
}

export function searchTravelTrip(id: string, date: number) {
  return getUrl("/search/travel/trip?id=" + id + "&date=" + date.toString());
}

export function searchTicket(id: string) {
  return getUrl("/search/ticket?id=" + id);
}

type searchLocationProps = {
  query: string;
  countrycode: any;
};

export function searchLocation(props: searchLocationProps) {
  // console.log(queryString.stringify(props));
  return getUrl("/search/locality?" + queryString.stringify(props));
}
