import React from "react";
import { primaryColor } from "../../constants";

const Title = ({
  children = Object(),
  fontSize = 28,
  color = primaryColor,
}) => {
  return (
    <span
      className="flex font-bold"
      style={{
        fontSize,
        color,
      }}
    >
      {children}
    </span>
  );
};

export default Title;
