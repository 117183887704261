import React from "react";
import ModalCustom from "../modal";

type Props = {
  message?: string;
  confirm: () => Promise<any>;
  handleClose: () => void;
  open: boolean;
  closeOnFinsh?: boolean;
};

const ConfirmActionModal = ({
  confirm,
  message = "evenement a confirmer ",
  handleClose = () => {},
  open = false,
  closeOnFinsh = true,
}: Props) => {
  const [closable] = React.useState(true);
  const [isLoading, setisLoading] = React.useState(false);
  function onConfirm() {
    setisLoading(true);
    confirm().then(() => {
      setisLoading(false);
      if (closeOnFinsh) {
        handleClose();
      }
    });
  }
  return (
    <ModalCustom open={open} handleClose={closable ? handleClose : undefined}>
      <div className="flex flex-col w-[300px] p-1 pt-0 space-y-4 items-center justify-center">
        <span className="flex text-[20px] font-medium">Confirmation</span>
        {message && (
          <span className="flex text-center text-[17px] font-normal">
            {message}
          </span>
        )}
        <div className="flex flex-row space-x-[10px] w-full">
          <button
            className={`flex flex-1 py-[8px] transition-all duration-150 ${
              isLoading ? "bg-primary" : "bg-slate-200"
            }  hover:bg-primary font-semibold hover:text-white rounded-md items-center justify-center`}
            onClick={!isLoading ? onConfirm : undefined}
          >
            {!isLoading ? (
              <span>Confirmer</span>
            ) : (
              <svg
                className="animate-spin h-5 w-5 text-black/50"
                xmlns="http://www.w3.org/2000/svg"
                fill={"transparent"}
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke={"white"}
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-75"
                  fill={"white"}
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
          </button>
          <button
            disabled={isLoading}
            className="flex font-semibold transition-all duration-150 flex-1 py-[8px] bg-slate-200 hover:bg-slate-200/75 active:bg-slate-300/85 rounded-md items-center justify-center"
            onClick={handleClose}
          >
            <span>Annuler</span>
          </button>
        </div>
      </div>
    </ModalCustom>
  );
};

export default ConfirmActionModal;
