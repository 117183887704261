import React from "react";
import { TicketModel } from "../../models/models";
import moment from "moment";
import QRCode from "qrcode.react";
import {
  TravelDepartNumberLabel,
  TravelTypeLabel,
  TravelVehiculeLabel,
} from "../../configs/stores/search";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

type Props = {
  ticket: TicketModel;
};

const DownloadTicketCard = ({ ticket }: Props) => {
  const duration = moment
    .duration(ticket.ticket_start_hour)
    .add(ticket.ticket_travel_duration);
  const arrived_hour = moment(duration.asMilliseconds()).format("HH:mm");
  const [loader, setLoader] = React.useState(false);
  const downloadPDF = () => {
    const capture = (document.getElementById("actual-receipt") ??
      {}) as HTMLElement;
    setLoader(true);
    html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL("img/png");
      const doc = new jsPDF("p", "mm");
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
      setLoader(false);
      doc.save(
        `LesVoyageurs-${moment(ticket.ticket_start_date)
          .format("LLLL")
          .split(" ")
          .reverse()
          .slice(1)
          .reverse()
          .join(" ")
          .toUpperCase()}.pdf`
      );
    });
  };


  return (
    <div
      id="actual-receipt"
      className="flex w-[400px] max_tablet:w-full h-fit flex-col rounded-md"
    >
      <div className="flex w-full items-center justify-center py-4 bg-coral  rounded-t-md">
        <span className="flex font-medium text-white text-[20px]">
          {loader === true ? "Votre ticket" : "Téléchargez votre ticket"}
        </span>
      </div>
      <div className="flex flex-col items-center p-8 bg-white gap-2">
        {loader === true && (
          <img
            src="../../verified.svg"
            alt=""
            className="flex mb-4 h-[160px] w-[160px]"
          />
        )}
        <img
          className="flex h-[30px]"
          src="../../voyageursBlack.svg"
          alt="voyageursLogoBlack"
        />
        <span className="flex text-[18px] flex-wrap mt-4 text-center text-black font-normal">
          <span className="pr-1">Bonjour,</span>
          <span className="flex font-semibold">
            {ticket.ticket_user_fullname}
          </span>
        </span>
        <span className="flex font-semibold text-[13px]">
          Votre voyage est prévu pour le
        </span>
        <span className="flex font-bold text-[13px]">
          {moment(ticket.ticket_start_date)
            .format("LLLL")
            .split(" ")
            .reverse()
            .slice(1)
            .reverse()
            .join(" ")
            .toUpperCase()}
        </span>
        <span className="flex font-semibold text-[13px]">
          Voici votre itineraire :
        </span>
        <div className="flex flex-col w-full items-center gap-3">
          <div className="flex flex-col gap-1 w-fit items-center border-primary/0">
            <span className="line-clamp-2 text-[14px] w-fit font-medium text-primary">
              Départ
            </span>
            <span className="line-clamp-2 text-[16px] w-fit font-medium">
              {ticket.ticket_from_station_name}
            </span>
            <span className="flex leading-tight text-center text-[13px] max-w-[80%]">
              ({ticket.ticket_from.station_locality})
            </span>
            <span className="flex">
              à {ticket.ticket_start_hour.split(":").join("h") + "min"}
            </span>
          </div>
          <div className="flex flex-col gap-1 w-fit items-center border-primary/0">
            <span className="line-clamp-2 text-[14px] w-fit font-medium text-primary">
              Arrivée
            </span>
            <span className="line-clamp-2 w-fit font-medium">
              {ticket.ticket_to_station_name}
            </span>
            <span className="flex leading-tight text-center text-[13px] max-w-[80%]">
              ({ticket.ticket_to.station_locality})
            </span>
            <span className="flex">estimée à {arrived_hour}</span>
          </div>
          <div className="flex flex-col gap-1 w-fit items-center border-primary/0">
            <span className="line-clamp-2 text-[14px] w-fit font-medium text-primary">
              Numéro du départ
            </span>
            <span className="line-clamp-2 text-[16px] w-fit font-medium">
              ({ticket.ticket_start_number.toString()}){" "}
              {
                TravelDepartNumberLabel.find(
                  (e) => e.value === ticket.ticket_start_number.toString()
                )?.label
              }{" "}
              Départ
            </span>
          </div>
        </div>
        <span className="flex flex-wrap items-center font-medium text-[17px] mt-1">
          <span className="pr-1">Avec la companie,</span>
        </span>
        <div className="flex flex-col gap-2 items-center">
          {ticket.ticket_company?.company_logo?.link && (
            <div className="flex items-start h-[50px] max-w-[80%] overflow-hidden">
              <img
                className="flex h-full w-fit object-contain"
                src={ticket.ticket_company.company_logo.link}
                alt={ticket.ticket_company_name}
              />
            </div>
          )}
          <span className="text-[20px] line-clamp-2 font-bold">
            {ticket.ticket_company.company_name.length >= 25
              ? ticket.ticket_company.company_acronym
              : ticket.ticket_company.company_name}
          </span>
        </div>
        <span className="flex pl-3 font-bold">
          Voyage:{" "}
          {TravelTypeLabel.find((e) => e.value === ticket.ticket_type)?.label}
        </span>
        <span className="flex pl-3 font-bold mb-3">
          Moyen de transport:{" "}
          {
            TravelVehiculeLabel.find((e) => e.value === ticket.ticket_vehicule)
              ?.label
          }
        </span>
        <div className="flex w-[220px] h-[220px] rounded-md overflow-hidden">
          <QRCode
            size={220}
            value={
              `${
                window.location.hostname.includes("lesvoyageurs")
                  ? "https://lesvoyageurs.ci"
                  : "http://" + window.location.hostname + ":5000"
              }/reservation/success?id=` + ticket.ticket_id
            }
          />
        </div>
        <div className="flex flex-col items-center mt-5 gap-2">
          <span className="flex text-[18px] font-medium">
            Code de validation
          </span>
          <span className="flex text-[20px] font-bold">
            {ticket.ticket_validation_code}
          </span>
        </div>
        <div className="flex flex-col items-center mt-5 gap-2">
          <span className="flex text-[18px] font-medium underline-offset-2 underline">
            Prix du voyage
          </span>
          <span className="flex text-[25px] font-bold">
            {ticket.ticket_cost} FRCFA
          </span>
        </div>
        {loader === false && (
          <div className="flex flex-col w-full mt-7 gap-6">
            <button
              onClick={downloadPDF}
              className="flex cursor-pointer text-[18px] items-center justify-center font-semibold bg-primary hover:bg-hover active:bg-active transitions-all duration-150 text-white px-5 py-5 rounded-md"
            >
              Télécharger
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DownloadTicketCard;
