import React from "react";
import { useResultsHook } from "../../hooks/useResults";
import ResultItem from "./item";
import { useSearchStore } from "../../configs/stores/search";
import { useBasicsStore } from "../../configs/stores/basics";

const ResultsList = () => {
  const { zone } = useSearchStore();
  const { userCountry } = useBasicsStore();
  const { results, isLoading } = useResultsHook();
  const specificZone =
    zone === "local" ? userCountry?.address.country : "International";
  return (
    <div className="flex flex-1 flex-col gap-4">
      {!isLoading && results.length !== 0 && (
        <div className="flex flex-row items-center gap-2">
          <span className="flex text-[17px] pl-5">
            {results.length} résultat(s) trouvé(s) -
          </span>
          <span className="flex text-[17px] font-semibold pr-5">
            {specificZone}
          </span>
        </div>
      )}
      <div className="flex flex-col gap-4">
        {!isLoading && results.length === 0 && (
          <div className="flex flex-col items-center justify-center gap-3 min-h-[200px]">
            <span className="flex text-[23px] phone:text-[20px]">
              Aucun résultat trouvé
            </span>
          </div>
        )}
        {!isLoading &&
          results.length !== 0 &&
          results.map((result) => {
            return <ResultItem key={result.id} item={result} />;
          })}
        {isLoading &&
          Array.from({ length: 15 }).map((_, index) => {
            return (
              <div
                key={index}
                className="flex w-[92%] mx-5 bg-[#ccc]/50 animate-pulse rounded-xl h-[130px]"
              />
            );
          })}
      </div>
    </div>
  );
};

export default ResultsList;
