import React from "react";

const MoyenPayment = () => {
  return (
    <div className="flex flex-col items-center mt-6 gap-4">
      <span className="flex font-normal text-[18px]">Moyens de paiement</span>
      <div className="flex flex-row w-full flex-wrap gap-4">
        <div className="flex flex-row w-full justify-between items-center">
          <img className="flex h-[20px]" src="./visa.svg" alt="" />
          <img className="flex h-[37px]" src="./mastercard.svg" alt="" />
          <img className="flex h-[34px]" src="./orangemoney.svg" alt="" />
        </div>
        <div className="flex flex-row w-full justify-between items-center">
          <img className="flex h-[39px] w" src="./wave.png" alt="" />
          <img className="flex h-[39px]" src="./mtn.png" alt="" />
          <img className="flex h-[49px]" src="./moov.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default MoyenPayment;
