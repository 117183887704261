import React from "react";
import Title from "../../components/sampleModels/title";
import Paragraphe from "../../components/sampleModels/paragraphe";

const Confidentialite = () => {
  return (
    <div className="flex flex-1 w-full overflow-hidden items-start justify-center phone:px-5 px-10 py-10">
      <div className="flex w-[70%] phone:w-[100%] flex-col 2xl:w-[1536px] gap-[40px] items-center">
        <Title color="black">
          Politique de Confidentialité
        </Title>
        <div className="flex flex-col w-full justify-start gap-4">
          <Title>Introduction</Title>
          <Paragraphe>
            Bienvenue chez LesVoyageurs. Nous nous engageons à protéger votre
            vie privée. Cette politique de confidentialité explique comment nous
            collectons, utilisons, partageons et protégeons les informations
            personnelles des utilisateurs de notre application Android.
          </Paragraphe>
        </div>

        <div className="flex flex-col w-full justify-start gap-4">
          <Title>1. Informations que Nous Collectons</Title>
          <Title fontSize={24}>1.1 Informations Personnelles</Title>
          <Paragraphe>
            Lorsque vous utilisez notre application, nous pouvons collecter des
            informations personnelles telles que :
          </Paragraphe>
          <div className="flex flex-col gap-y-2 pl-2">
            <Paragraphe>- Nom</Paragraphe>
            <Paragraphe>- Adresse e-mail</Paragraphe>
            <Paragraphe>- Numéro de téléphone</Paragraphe>
            <Paragraphe>- Informations de paiement</Paragraphe>
            <Paragraphe>
              - Informations de voyage (par ex. itinéraires, réservations)
            </Paragraphe>
          </div>
          <Title fontSize={24}>1.2 Informations de Connexion</Title>
          <Paragraphe>
            Avec votre consentement, nous pouvons collecter et traiter des
            informations sur votre localisation pour améliorer nos services (par
            ex. suggestions de trajets).
          </Paragraphe>
        </div>
        <div className="flex flex-col w-full justify-start gap-4">
          <Title>2. Utilisation des Informations</Title>
          <Paragraphe>Nous utilisons vos informations pour :</Paragraphe>
          <div className="flex flex-col gap-y-2 pl-2">
            <Paragraphe>- Fournir et gérer nos services</Paragraphe>
            <Paragraphe>- Traiter vos réservations et paiements</Paragraphe>
            <Paragraphe>
              - Communiquer avec vous sur vos réservations et notre application
            </Paragraphe>
            <Paragraphe>
              - Personnaliser votre expérience utilisateur
            </Paragraphe>
            <Paragraphe>- Améliorer et optimiser notre application</Paragraphe>
            <Paragraphe>
              - Assurer la sécurité et la prévention des fraudes
            </Paragraphe>
          </div>
        </div>

        <div className="flex flex-col w-full justify-start gap-4">
          <Title>3. Partage des Informations</Title>
          <Paragraphe>
            Nous ne partageons vos informations personnelles qu'avec :
          </Paragraphe>
          <div className="flex flex-col gap-y-2 pl-2">
            <Paragraphe>
              - Les compagnies de transport partenaires pour traiter vos
              réservations.
            </Paragraphe>
            <Paragraphe>
              - Les fournisseurs de services qui nous aident à exploiter notre
              application (par ex. services de paiement).
            </Paragraphe>
            <Paragraphe>
              - Les autorités légales si nécessaire pour respecter la loi ou
              protéger nos droits.
            </Paragraphe>
          </div>
        </div>

        <div className="flex flex-col w-full justify-start gap-4">
          <Title>4. Sécurité des Informations</Title>
          <Paragraphe>
            Nous mettons en place des mesures de sécurité appropriées pour
            protéger vos informations contre l'accès, la divulgation, la
            modification ou la destruction non autorisés. Cependant, aucune
            méthode de transmission sur Internet ou de stockage électronique
            n'est totalement sécurisée, et nous ne pouvons garantir une sécurité
            absolue.
          </Paragraphe>
        </div>

        <div className="flex flex-col w-full justify-start gap-4">
          <Title>5. Vos Droits</Title>
          <Paragraphe>Vous avez le droit de :</Paragraphe>
          <div className="flex flex-col gap-y-2 pl-2">
            <Paragraphe>- Accéder à vos informations personnelles</Paragraphe>
            <Paragraphe>
              - Rectifier vos informations si elles sont incorrectes
            </Paragraphe>
            <Paragraphe>
              - Demander la suppression de vos informations
            </Paragraphe>
            <Paragraphe>
              - Restreindre le traitement de vos informations
            </Paragraphe>
            <Paragraphe>
              - Porter plainte auprès d'une autorité de protection des données
            </Paragraphe>
          </div>
        </div>

        <div className="flex flex-col w-full justify-start gap-4">
          <Title>6. Liens vers des Sites Externes</Title>
          <Paragraphe>
            Notre application peut contenir des liens vers des sites externes.
            Nous ne sommes pas responsables des pratiques de confidentialité de
            ces sites. Nous vous encourageons à lire les politiques de
            confidentialité de chaque site que vous visitez.
          </Paragraphe>
        </div>

        <div className="flex flex-col w-full justify-start gap-4">
          <Title>7. Modifications de cette Politique</Title>
          <Paragraphe>
            Nous pouvons mettre à jour cette politique de confidentialité de
            temps en temps. Nous vous informerons de toute modification en
            publiant la nouvelle politique sur cette page. Votre utilisation
            continue de notre application après la publication des modifications
            constitue votre acceptation de ces modifications.
          </Paragraphe>
        </div>

        <div className="flex flex-col w-full justify-start gap-4">
          <Title>8. Nous Contacter</Title>
          <Paragraphe>
            Si vous avez des questions ou des préoccupations concernant cette
            politique de confidentialité, veuillez nous contacter à :
          </Paragraphe>
          <div className="flex flex-col gap-y-2 pl-2">
            <Paragraphe>- Email : support@lesvoyageurs.ci
            </Paragraphe>
            <Paragraphe>
              - Téléphone : +2250749985252
            </Paragraphe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confidentialite;
