import React from "react";
import { useNaviLoadBoth } from "../../../../../functions/useNaviLoadBoth";

const WaveForm = () => {
  const navigate = useNaviLoadBoth;
  return (
    <div className="flex flex-col gap-3 w-full items-center">
      <div className="flex p-4 rounded-md bg-[#1DC4FF] flex-row h-[200px] gap-4 w-full">
        <div className="flex p-2 w-[176px] rounded-md bg-white">
          <img src="../../qrcode.svg" className="flex flex-1" alt="" />
        </div>
        <div className="flex flex-1 flex-col items-center justify-center gap-3">
          <span className="flex text-center text-[35px] text-white font-bold">Scannez ici pour payer</span>
          <img src="../../wave.png" className="flex h-[40px]" alt="" />
        </div>
      </div>
      <button
        onClick={function () {
          navigate("/reservation/success?id=knvr8vh0z54vz487zv08v0");
        }}
        className="flex cursor-pointer items-center justify-center font-bold bg-coral text-white px-8 py-4 rounded-md mt-2"
      >
        Valider mon paiement
      </button>
    </div>
  );
};

export default WaveForm;
