import React from "react";
import LoginEmailInput from "./email-input";
import LoginPasswordInput from "./password-input";
import { getPropValue } from "../../functions/getPropValue";
import { isEmail } from "../../constants";
import { useAuthStore } from "../../configs/stores/auth";

const LoginEmailForm = () => {
  const isLoading = useAuthStore((e) => e.isLoadingLoginEmail);
  const loginEmail = useAuthStore((e) => e.loginEmail);
  const [formData, setFormData] = React.useState({
    login: "",
    password: "",
  });
  const [errorState, setErrorState] = React.useState({
    login: { status: false, msg: "" },
    password: { status: false, msg: "" },
    generalMsg: "",
  });

  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
      if (getPropValue(errorState, slug)) {
        setErrorState((e) => ({ ...e, [slug]: { status: false, msg: "" } }));
      }
    };
  }

  function checkFormData() {
    let errorOccured = false;
    // email
    if (formData.login.length === 0 || !isEmail(formData.login)) {
      errorOccured = true;
      let msg = "Adresse email incorrecte";
      setErrorState((e) => ({ ...e, login: { status: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, login: { status: false, msg: "" } }));
    }
    // password
    if (formData.password.length === 0) {
      errorOccured = true;
      let msg = "Mot de passe vide";
      setErrorState((e) => ({ ...e, password: { status: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, password: { status: false, msg: "" } }));
    }
    return errorOccured;
  }

  function submitForm(e: any) {
    e.preventDefault();
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    setErrorState((e) => ({
      ...e,
      generalMsg: "",
    }));
    loginEmail({
      login: formData.login,
      password: formData.password,
    }).then((res: any) => {
      if (!res.success) {
        setErrorState((e) => ({
          ...e,
          generalMsg: res.message.toString(),
        }));
      }
    });
  }
  return (
    <form className="flex flex-col gap-4" onSubmit={submitForm}>
      <LoginEmailInput
        error={errorState.login}
        onTextChange={onValueChange("login")}
      />
      <LoginPasswordInput
        error={errorState.password}
        onTextChange={onValueChange("password")}
      />
      <a href="/forgot-password" className="group flex">
        <span className="flex text-coral group-hover:text-hover font-medium text-[13px]">
          Mot de passe oublie ?
        </span>
      </a>
      {!!errorState.generalMsg && (
        <div className="flex w-full bg-red-500/15 pl-3 py-2 rounded-md">
          <span className="flex text-[15px] font-medium text-red-600">
            {errorState.generalMsg}
          </span>
        </div>
      )}
      <button
        type="submit"
        className={`flex rounded-[10px] ${
          !isLoading ? "bg-primary hover:bg-hover" : "bg-hover"
        }  items-center justify-center py-3`}
      >
        <span className="flex text-white font-semibold text-[18px]">
          {isLoading ? "Connexion...." : "Connexion"}
        </span>
      </button>
    </form>
  );
};

export default LoginEmailForm;
