import React from "react";
import { isNumber } from "../../constants";

type Props = {
  error?: { state: boolean; msg: string };
  onTextChange?: (value: string) => void;
  defaultValue?: string;
  placeholder?: string;
  disableEdit?: boolean;
  enableEdit?: () => void;
};

const PhoneInput = ({
  defaultValue = "",
  placeholder = "Tel Ex: 2250000000000",
  error = { state: false, msg: "" },
  onTextChange = (str) => {},
  disableEdit = false,
  enableEdit = () => {},
}: Props) => {
  const [inputVal, setinputVal] = React.useState(defaultValue ?? "");
  const inputRef = React.useRef<HTMLInputElement>(null);
  const handleInputChange = (e: any) => {
    const inputValue = e.currentTarget.value;
    if (isNumber(inputValue) || inputValue.length === 0) {
      setinputVal(inputValue);
      onTextChange(inputValue);
    }
  };
  return (
    <div className="flex flex-col relative gap-[6px]">
      <div className="flex flex-row gap-2 items-center">
        <div className="flex transition-all duration-150 justify-center absolute right-2 p-[6px] hover:bg-black/15 active:bg-black/10 rounded-full">
          {disableEdit && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill={"none"}
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-[22px] h-[22px]"
              onClick={enableEdit}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          )}
        </div>
        <input
          ref={inputRef}
          type="tel"
          disabled={disableEdit}
          placeholder={placeholder}
          maxLength={15}
          onChange={handleInputChange}
          value={inputVal}
          className={`flex transition-all duration-150 flex-1 rounded-[10px] text-black pl-4 min-w-0 border-[2.5px] caret-primary ${
            error.state
              ? "caret-red-500 border-red-500 outline-none text-red-500 placeholder:text-red-500"
              : "border-slate-300 outline-none focus:border-primary"
          } font-medium text-[19px] py-3 bg-slate-100`}
        />
      </div>
      {error.msg.length !== 0 && (
        <span className="flex select-none pl-[6px] text-[14px] text-red-500">
          {error.msg}
        </span>
      )}
    </div>
  );
};

export default PhoneInput;
