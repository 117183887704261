import { compress, decompress } from "lz-string";
import { encrypt, decrypt } from "../encryption";

const STORAGE_NAME = "storage";

function getItem(name, storage) {
  const storageEnc = sessionStorage.getItem(storage);
  if (!storageEnc) {
    return null;
  }

  let storageDec = JSON.parse(
    decrypt(
      JSON.parse(decompress(storageEnc)).data,
      process.env.REACT_APP_Key,
      process.env.REACT_APP_Iv
    )
  );

  if (!storageDec) {
    return null;
  }

  return storageDec[name];
}

function setItem(name, value, storage) {
  let storageDec = {};
  const storageEnc = sessionStorage.getItem(storage);

  if (storageEnc) {
    storageDec = JSON.parse(
      decrypt(
        JSON.parse(decompress(storageEnc)).data,
        process.env.REACT_APP_Key,
        process.env.REACT_APP_Iv
      )
    );
  }

  storageDec[name] = value;

  sessionStorage.setItem(
    storage,
    compress(
      JSON.stringify({
        data: encrypt(
          JSON.stringify(storageDec),
          process.env.REACT_APP_Key,
          process.env.REACT_APP_Iv
        ),
      })
    )
  );
}

function removeItem(name, storage) {
  const storageEnc = sessionStorage.getItem(storage);
  if (!storageEnc) {
    return;
  }

  let storageDec = JSON.parse(
    decrypt(
      JSON.parse(decompress(storageEnc)).data,
      process.env.REACT_APP_Key,
      process.env.REACT_APP_Iv
    )
  );

  storageDec[name] = undefined;

  sessionStorage.setItem(
    storage,
    compress(
      JSON.stringify({
        data: encrypt(
          JSON.stringify(storageDec),
          process.env.REACT_APP_Key,
          process.env.REACT_APP_Iv
        ),
      })
    )
  );
}

export const ZustandSessionStorageCrypted = (storage = STORAGE_NAME) => ({
  getItem: (name) => getItem(name, storage),
  setItem: (name, value) => setItem(name, value, storage),
  removeItem: (name) => removeItem(name, storage),
});
