import React from 'react'

const RecentsPaiements = () => {
  return (
    <div className="hidden pc:flex pc_large:flex flex-col w-[300px] min-h-[500px] rounded-[10px] overflow-hidden">
      <span className='flex text-[19px] font-semibold'>Récentes réservations</span>
      <div className='flex flex-col'></div>
    </div>
  )
}

export default RecentsPaiements