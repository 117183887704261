import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Header from "./components/header";
import { Forloadandnavigate } from "./functions/useNaviLoadBoth";
import Results from "./pages/results";
import Reservation from "./pages/reservation";
import ReserationSuccess from "./pages/reservation/success";
import Footer from "./components/footer/index";
import Confidentialite from "./pages/Confidentialite";
import APropos from "./pages/apropos";
import Carriere from "./pages/carriere";
// import ContactezNous from "./pages/contactezNous";
import EngagementQualite from "./pages/engagementQualite";
import Entreprise from "./pages/entreprise";
import FAQ from "./pages/faq";
import MentionLegal from "./pages/mentionLegale";
import MonEspace from "./pages/monEspace";
// import VoyageursPlus from "./pages/plus";
// import Presse from "./pages/presse";
import Pro from "./pages/pro";
import Publicite from "./pages/publicite";
import Reclamations from "./pages/reclamations";
import CGV from "./pages/cgv";
import SignIn from "./pages/signin";
import SignUp from "./pages/signup";
import MapPage from "./pages/map";
import DeleteAccount from "./pages/deleteAccount";

function App() {
  return (
    <div className="flex flex-col min-h-screen h-full w-full font-inter">
      <div className="flex flex-1 pt-[60px] bg-gradient-to-b from-[#f5f5f5] to-white bg-cover bg-primary-contrast1">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/results" element={<Results />} />
          <Route path="/map" element={<MapPage />} />
          <Route path="/reservation">
            <Route path="" element={<Reservation />} />
            <Route path="success" element={<ReserationSuccess />} />
          </Route>
          {/* FOOTER */}
          {/* liste 1 */}
          <Route path="/a-propos" element={<APropos />} />
          <Route path="/engagement-qualite" element={<EngagementQualite />} />
          <Route path="/mention-legale" element={<MentionLegal />} />
          {/* <Route path="/presse" element={<Presse />} /> */}
          <Route path="/carriere" element={<Carriere />} />
          {/* liste 2 */}
          {/* <Route path="/contactez-nous" element={<ContactezNous />} /> */}
          <Route path="/reclamations" element={<Reclamations />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/privacy-policy" element={<Confidentialite />} />
          <Route path="/publicite" element={<Publicite />} />
          {/* liste 3 */}
          {/* <Route path="/plus" element={<VoyageursPlus />} /> */}
          <Route path="/entreprises" element={<Entreprise />} />
          <Route path="/pro" element={<Pro />} />
          <Route path="/mon-espace" element={<MonEspace />} />
          <Route path="/cgv" element={<CGV />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
        </Routes>
      </div>
      <Header />
      <Forloadandnavigate />
      <Footer />
    </div>
  );
}

export default App;
