import React from "react";

const Carriere = () => {
  return (
    <div className="flex flex-1 w-full overflow-hidden items-start justify-center px-10 py-10">
      <div className="flex w-[70%] flex-col 2xl:w-[1536px] gap-[40px] items-center">
        <span className="flex font-bold text-[28px]">
          Carriere
        </span>
        <div className="flex flex-col w-full justify-start gap-4">
          <span className="flex font-bold text-[25px] text-coral ">
            {/* Titre */}
            Preambule
          </span>
          <span className="flex font-normal text-[17px]">
            {/* paragraphe */}
            La présente politique de confidentialité est applicable à compter du
            25 mai 2018 conformément à la Loi Informatique et Libertés du 6
            janvier 1978 modifiée et au Règlement (UE) 2016/679 du 27 avril 2016
            relatif à la protection des personnes physiques à l'égard du
            traitement des données à caractère personnel et à la libre
            circulation de ces données (« RGPD »). Elle s’applique à toutes les
            données à caractère personnel (toutes les informations relatives aux
            personnes physiques identifiées ou qui peuvent y être associées)
            traitées par Artea en sa qualité de responsable du traitement.
          </span>
          <span className="flex font-normal text-[17px]">
            {/* paragraphe */}
            Les termes “Kombo”, “nous” ou “notre/nos” utilisés dans le présent
            texte désignent la société par actions simplifiée Artea. Cette
            dernière édite les sites accessible sous les domaines et
            sous-domaines de kombo.co. Elle est domicilié au 12 rue Anselme
            93400 St Ouen et est immatriculée au RCS de Paris sous le numéro 820
            902 377.Les termes “vous” ou “vos.votre” renvoient aux visiteurs ou
            utilisateurs de nos sites internet.
          </span>
          <span className="flex font-normal text-[17px]">
            {/* paragraphe */}
            Certains liens hypertextes présents sur nos sites internet vous
            redirigent vers des sites internet n'appartenant pas à Artea, ce
            sont ceux de nos partenaires. Ceux-ci disposent de politiques de
            confidentialité ou protection des données qui leur sont propres et
            qui peuvent donc différer des nôtres, il vous appartient alors d’en
            prendre connaissance
          </span>
        </div>
      </div>
    </div>
  );
};

export default Carriere;
