import React from "react";
import { useNaviLoadBoth } from "../../../../../functions/useNaviLoadBoth";
import FormInput from "../../../../../components/inputs/formInput";

const OrangeMoneyForm = () => {
  const navigate = useNaviLoadBoth;
  return (
    <div className="flex flex-col gap-3 w-full items-center">
      <div className="flex flex-col w-full gap-3">
        <div className="flex flex-row gap-4 w-fit border-black/40 border p-2 items-center">
          <span className="flex text-[16px]">Ref. transaction :</span>
          <span className="flex text-[16px]">3213547657889588754567890</span>
        </div>
        <div className="flex flex-col gap-2">
          <span className="flex text-[16px]"># Premiere etape</span>
          <div className="flex flex-row gap-4">
            <FormInput placeholder="Numero de telephone" />
            <button
              onClick={function () {
                navigate("/reservation/success?id=knvr8vh0z54vz487zv08v0");
              }}
              className="flex flex-1 cursor-pointer items-center justify-center font-bold bg-coral text-white px-14 h-[47px] rounded-md"
            >
              Valider
            </button>
          </div>
          <span className="flex text-[16px] leading-[1.3] font-normal text-black/50">
            " Valider votre numero de telephone puis vous recevrez un message
            contenant le code de validation de la transaction valable 5min "
          </span>
        </div>
        <div className="flex flex-col gap-2">
          <span className="flex text-[16px]"># Deuxieme etape</span>
          <div className="flex flex-row gap-4">
            <FormInput placeholder="Code de validation de la transaction" />
          </div>
          <span className="flex text-[16px] leading-[1.3] font-normal text-black/50">
            " Saisissez le code de validation puis appuyer sur le bouton de validation du paiement "
          </span>
        </div>
      </div>
      <button
        onClick={function () {
          navigate("/reservation/success?id=knvr8vh0z54vz487zv08v0");
        }}
        className="flex cursor-pointer items-center justify-center font-bold bg-coral text-white px-8 py-4 rounded-md mt-2"
      >
        Valider mon paiement
      </button>
    </div>
  );
};

export default OrangeMoneyForm;
