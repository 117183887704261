import React from "react";
import { useBasicsStore } from "../../configs/stores/basics";
import { useOneEffect } from "../../hooks/useOnEffect";

const BasicsProvider = ({ children = <div /> }) => {
  const isLoading = useBasicsStore((e) => e.isLoading);
  const getAll = useBasicsStore((e) => e.getAll);
  const getDestinationCapitals = useBasicsStore((e) => e.getDestinationCapitals);
  const getDestinationFavourites = useBasicsStore(
    (e) => e.getDestinationFavourites
  );
  const getUserCountry = useBasicsStore((e) => e.getUserCountry);
  useOneEffect(() => {
    if (!isLoading) {
      getAll();
    }
    getUserCountry();
    getDestinationCapitals();
    getDestinationFavourites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <>{children}</>
    </React.Fragment>
  );
};

export default BasicsProvider;
