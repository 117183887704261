import React from "react";
import ReservationForm from "../../components/reservationForm";
import ReservationCard from "../../components/reservationCard";
import { useOneEffect } from "../../hooks/useOnEffect";
import { useSearchParams } from "react-router-dom";
import { TravelResultModel } from "../../hooks/useResults";
import { useSearchStore } from "../../configs/stores/search";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";
// import DestinationCarrousel from "../../components/destinationCarrousel";

const Reservation = () => {
  const navigate = useNaviLoadBoth;
  const { searchTravelTrip } = useSearchStore();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const date = searchParams.get("date");
  const [isLoading, setIsLoading] = React.useState(true);
  const [message, setMessage] = React.useState<string | null>(null);
  const [start, setStart] = React.useState<TravelResultModel | null>(null);
  function getStart(id: string, date: number) {
    setIsLoading(true);
    searchTravelTrip(id, date).then((res) => {
      setIsLoading(false);
      if (res.success) {
        setMessage(null);
        setStart(res.data);
      } else {
        setMessage(res.message);
      }
    });
  }
  useOneEffect(() => {
    setMessage(null);
    if (id && date) {
      getStart(id, parseInt(date));
    } else {
      navigate("/");
    }
  }, []);
  return (
    <div className="flex flex-1 w-full overflow-hidden items-start justify-center px-10 tablet_mini:px-6 phone:px-5 max_tablet_mini:py-10 py-14">
      <div className="flex w-full flex-col max-w-[1536px] gap-[40px] items-center">
        {!isLoading ? (
          start ? (
            <div className="flex flex-row flex-wrap gap-10 justify-center">
              <ReservationForm start={start} />
              <ReservationCard start={start} />
            </div>
          ) : (
            <div className="flex items-center gap-5 flex-col px-5 py-5">
              <span className="flex text-[25px] font-semibold">
                Finalisez votre réservation
              </span>
              <span className="flex text-[20px] font-semibold text-red-400">
                {message}
              </span>
              <span
                onClick={() => navigate("/")}
                className="flex text-[20px] cursor-pointer underline underline-offset-2 font-medium text-primary"
              >
                Retour a l'accueil
              </span>
            </div>
          )
        ) : (
          <div className="flex gap-5 flex-col px-5 py-5">
            <span className="flex text-[25px] font-semibold">
              Finalisez votre réservation
            </span>
            <span className="flex text-[23px] font-semibold">
              Chargement du ticket, patientez un instant...
            </span>
          </div>
        )}
        {/* <DestinationCarrousel /> */}
      </div>
    </div>
  );
};

export default Reservation;
