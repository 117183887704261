import React from "react";
import VehiculeFilter from "./filter/vehicule";
import CostFilter from "./filter/cost";
import FilterModal from "../../components/modals/models/filter";

const ResultsFilter = () => {
  return (
    <div className="hidden gap-2 result_show_pc_filter:flex flex-col w-[250px] min-h-[500px] rounded-[10px]">
      <span className="flex text-[19px] font-semibold">
        Filtrer les résultats
      </span>
      <div className="flex mt-2 h-px w-full bg-slate-200" />
      <div className="flex flex-col gap-5 pt-2">
        <VehiculeFilter />
        <CostFilter />
      </div>
    </div>
  );
};

export const ResultsFilterMobile = () => {
  const [isOpen, setisOpen] = React.useState(false);
  function closeFilter() {
    setisOpen(false);
  }
  return (
    <React.Fragment>
      <button
        onClick={() => setisOpen(true)}
        className="flex select-none w-auto justify-center bg-slate-200 px-2 active:bg-slate-300 transition-all duration-150 py-[6px] mt-1 mb-2 rounded-[8px] items-center flex-row gap-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-4 h-4"
        >
          <path d="M17 2.75a.75.75 0 0 0-1.5 0v5.5a.75.75 0 0 0 1.5 0v-5.5ZM17 15.75a.75.75 0 0 0-1.5 0v1.5a.75.75 0 0 0 1.5 0v-1.5ZM3.75 15a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-1.5 0v-1.5a.75.75 0 0 1 .75-.75ZM4.5 2.75a.75.75 0 0 0-1.5 0v5.5a.75.75 0 0 0 1.5 0v-5.5ZM10 11a.75.75 0 0 1 .75.75v5.5a.75.75 0 0 1-1.5 0v-5.5A.75.75 0 0 1 10 11ZM10.75 2.75a.75.75 0 0 0-1.5 0v1.5a.75.75 0 0 0 1.5 0v-1.5ZM10 6a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM3.75 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM16.25 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" />
        </svg>
        <span className="flex select-none text-[15px]">
          Filtrer les résultats
        </span>
      </button>
      <FilterModal open={isOpen} handleClose={closeFilter} />
    </React.Fragment>
  );
};

export default ResultsFilter;
