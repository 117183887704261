import React from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { useDimensions } from "../../hooks/useDimensions";
import { useBasicsStore } from "../../configs/stores/basics";
import DestinationFavouriteItem from "./item";

const DestinationCarrousel = () => {
  const destinationFavourites = useBasicsStore((e) => e.destinationFavourites);
  const [refDim, dimensions] = useDimensions<HTMLDivElement>();
  const [ref] = useKeenSlider<HTMLDivElement>({
    loop: false,
    mode: "snap",
    slides: {
      perView: "auto",
      spacing: 15,
    },
  });
  return (
    <div className="flex flex-col gap-4 w-full">
      <div className={`flex flex-col gap-2`}>
        <div className="font-semibold text-left text-[26px] text-black">
          Retrouvez toutes vos villes préférées sur nos destinations
        </div>
        <div className={`text-[18px] text-gray-100`}>
          La nouvelle façon de rendre votre plus sûre et plus agréable dans
          toute la côte d’Ivoire et dans la sous région.
        </div>
      </div>
      <div className="flex flex-row w-full">
        <div ref={refDim} className="flex flex-1 flex-col w-full">
          <div
            ref={ref}
            className="keen-slider flex flex-1"
            style={{ maxWidth: dimensions?.width ?? 10 ,width:dimensions?.width ?? 10}}
          >
            {destinationFavourites.length !== 0 &&destinationFavourites.map((dest) => {
              return (
                <DestinationFavouriteItem key={dest.destination_id} item={dest} />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DestinationCarrousel;
