import React from "react";
import Title from "../../components/sampleModels/title";
import Paragraphe from "../../components/sampleModels/paragraphe";

const Publicite = () => {
  return (
    <div className="flex flex-1 w-full overflow-hidden items-start justify-center px-10 py-10">
      <div className="flex w-[70%] flex-col 2xl:w-[1536px] gap-[40px] items-center">
        <Title color="black">Publicité sur LesVoyageurs</Title>
        <div className="flex flex-col w-full justify-start gap-4">
          <Paragraphe>
            Découvrez comment promouvoir efficacement votre entreprise ou votre service sur LesVoyageurs, la plateforme de réservation de billets de voyage en bus, métro et bateaux en Côte d'Ivoire et la sous-région. LesVoyageurs compte un nombre croissant d'utilisateurs, ce qui en fait un excellent canal publicitaire pour atteindre un public cible intéressé par les voyages et les services connexes.
          </Paragraphe>
        </div>

        <div className="flex flex-col w-full justify-start gap-4">
          <Title>Options de publicité</Title>
          <Paragraphe>
            1. Bannières publicitaires : Faites la promotion de votre marque ou de vos services avec des bannières publicitaires stratégiquement placées sur notre site web. Choisissez parmi différents formats et emplacements pour maximiser l'impact de votre message.
            <br></br><br></br>
            2. Contenu sponsorisé : Collaborez avec notre équipe éditoriale pour créer un contenu attractif et informatif sur votre entreprise ou vos services, et atteignez un public engagé grâce à nos articles sponsorisés ou nos guides de voyage.
            <br></br><br></br>
            3. Publicité dans les e-mails : Profitez de notre liste de diffusion pour toucher directement les utilisateurs intéressés par les voyages. Incluez votre message publicitaire dans nos newsletters ou envoyez des offres promotionnelles ciblées.
            <br></br><br></br>
            4. Partenariats et collaborations : Nous sommes ouverts à explorer des partenariats et des collaborations pour promouvoir conjointement nos marques et offrir des avantages exclusifs à nos clients respectifs.
            <br></br><br></br>
            5. Offres promotionnelles : Proposez des réductions ou des offres spéciales pour les utilisateurs de LesVoyageurs afin d'attirer l'attention sur votre entreprise et d'inciter les utilisateurs à essayer vos services.
          </Paragraphe>
        </div>

        <div className="flex flex-col w-full justify-start gap-4">
          <Title>Pourquoi choisir LesVoyageurs ?</Title>
          <Paragraphe>
            Audience ciblée : LesVoyageurs attire un public intéressé par les voyages et les services connexes, ce qui vous permet de maximiser l'efficacité de vos campagnes publicitaires.
            <br></br><br></br>Visibilité accrue : Notre plateforme attire un nombre croissant d'utilisateurs, offrant une excellente visibilité pour votre marque.
            <br></br><br></br>Flexibilité : Nous proposons différentes options de publicité pour répondre à vos besoins et votre budget.
            <br></br><br></br>Suivi des performances : Nous vous fournirons des rapports réguliers sur les performances de vos campagnes publicitaires, afin que vous puissiez ajuster vos stratégies en conséquence.


          </Paragraphe>
        </div>

        <div className="flex flex-col w-full justify-start gap-4">
          <Paragraphe>
            Si vous êtes intéressé par la publicité sur LesVoyageurs, veuillez nous contacter à ads@lesvoyageurs.ci ou remplir notre formulaire de contact en ligne. Un membre de notre équipe commerciale vous contactera pour discuter de vos besoins et élaborer une stratégie publicitaire adaptée à votre entreprise.          </Paragraphe>
        </div>

      </div>
    </div>
  );

};

export default Publicite;
