import React from "react";
import ModalCustom from "../../modal";
import VehiculeFilter from "./vehicule";
import CostFilter from "./cost";

const FilterModal = ({ handleClose = () => {}, open = false }) => {
  return (
    <ModalCustom open={open} handleClose={handleClose}>
      <div className="flex gap-2 flex-col w-[75vw] pb-4 overflow-y-auto px-3 rounded-[10px]">
        <span className="flex text-[19px] font-semibold">
          Filtrer les résultats
        </span>
        <div className="flex mt-2 h-px w-full bg-slate-200" />
        <div className="flex flex-col gap-5 pt-2">
          <VehiculeFilter />
          <CostFilter />
        </div>
      </div>
    </ModalCustom>
  );
};

export default FilterModal;
