import React from "react";
import FormReservationUser from "./form";
import moment from "moment";
import {
  TravelDepartNumberLabel,
  TravelTypeLabel,
  TravelVehiculeLabel,
} from "../../configs/stores/search";
import { useAuthStore } from "../../configs/stores/auth";
import { TravelResultModel } from "../../hooks/useResults";
import ConvenienceItem from "./convenienceItem";

type Props = {
  start: TravelResultModel;
};

const ReservationForm = ({ start }: Props) => {
  const { isAuthenticated } = useAuthStore();
  const {
    travel,
    start: { hour, number },
    start_date,
  } = start;
  const {
    travel_company: company,
    travels: { travel_from: from, travel_to: to },
  } = travel;
  const duration = moment.duration(hour).add(travel.travels.travel_duration);
  const arrived_hour = moment(duration.asMilliseconds()).format("HH:mm");
  function GoToMap() {}
  return (
    <div className="flex flex-col max-w-[550px] items-start gap-4">
      <span className="flex text-[25px] font-semibold">
        Finalisez votre réservation
      </span>
      <span className="flex text-[17px] text-primary font-normal">
        Résumé de votre réservation
      </span>
      <div className="flex flex-col items-start w-full gap-3 pt-3">
        {company?.company_logo?.link && (
          <div className="flex items-start h-[60px] max-w-[180px] overflow-hidden">
            <img
              className="flex h-full w-fit object-contain"
              src={company.company_logo.link}
              alt="Member_profile"
            />
          </div>
        )}
        <span className="flex text-[23px] font-semibold">
          {company.company_name.length >= 25
            ? company.company_acronym
            : company.company_name}
        </span>
        {/** Minimum Tablette */}
        <div className="hidden min_tablet:flex flex-row w-full gap-3 items-center">
          <div className="flex flex-col gap-1 w-fit max-w-[170px]">
            <span className="line-clamp-2 text-[16px] w-fit font-medium">
              {from.station_name}
            </span>
            <span className="flex">{hour.split(":").join("h") + "min"}</span>
          </div>
          <div className="flex flex-1 flex-row items-center gap-1">
            <div className="flex border-2 border-coral min-h-[9px] min-w-[9px] rounded-full" />
            <div className="flex w-full border-b-2 border-coral border-dotted" />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="coral"
              className="w-4 h-4"
            >
              <path
                fillRule="evenodd"
                d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="flex flex-col gap-1 w-fit max-w-[170px]">
            <span className="line-clamp-2 w-fit font-medium">
              {to.station_name}
            </span>
            <span className="flex">{arrived_hour}</span>
          </div>
        </div>
        {/** Maximum Tablette Mini */}
        <div className="hidden max_tablet_mini:flex flex-col w-full gap-3">
          <div className="flex flex-col gap-1 w-fit border-l-4 border-primary pl-2">
            <span className="line-clamp-2 text-[14px] w-fit font-medium text-primary">
              Départ
            </span>
            <span className="line-clamp-2 text-[16px] w-fit font-medium">
              {from.station_name}
            </span>
            <span className="flex">{hour.split(":").join("h") + "min"}</span>
          </div>
          <div className="flex flex-col gap-1 w-fit border-l-4 border-primary pl-2">
            <span className="line-clamp-2 text-[14px] w-fit font-medium text-primary">
              Arrivée
            </span>
            <span className="line-clamp-2 w-fit font-medium">
              {to.station_name}
            </span>
            <span className="flex">{arrived_hour}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 w-full justify-between text-[14px]">
        <span className="flex text-[15px] font-medium border-l-4 border-primary pl-2">
          Départ:{" "}
          {moment(start_date)
            .format("LLLL")
            .split(" ")
            .reverse()
            .slice(1)
            .reverse()
            .join(" ")
            .toUpperCase()}
        </span>
        <span className="flex text-[15px] font-medium border-l-4 border-primary pl-2">
          {
            TravelDepartNumberLabel.find((e) => e.value === number.toString())
              ?.label
          }{" "}
          Départ : {hour.split(":").join("h") + "min"}
        </span>
        <div className="flex flex-row gap-1 pl-[10px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 min-w-6 min-h-6 max_tablet_mini:w-5 max_tablet_mini:h-5 max_tablet_mini:min-w-5 max_tablet_mini:min-h-5 max_tablet_mini:mt-px"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
            />
          </svg>
          <div className="flex flex-col pt-[1px]">
            <span className="flex leading-relaxed">
              {from.station_name} situé à {from.station_locality}
            </span>
            <div
              onClick={GoToMap}
              className="flex flex-row w-fit cursor-pointer items-center gap-1 transition-all duration-150 text-primary active:text-active hover:text-hover stroke-primary active:stroke-active hover:stroke-hover"
            >
              <span className="flex text-[12px] font-semibold underline underline-offset-2">
                Afficher sur la carte
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke={"currentColor"}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M15 3h6v6" />
                <path d="M10 14 21 3" />
                <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
              </svg>
            </div>
          </div>
        </div>
        <span className="flex pl-3">
          Voyage:{" "}
          {TravelTypeLabel.find((e) => e.value === travel.travel_type)?.label}
        </span>
        <span className="flex pl-3">
          Moyen de transport:{" "}
          {
            TravelVehiculeLabel.find(
              (e) => e.value === travel.travels.travel_vehicule
            )?.label
          }
        </span>
      </div>
      {from.station_conveniences.length !== 0 && (
        <div className="flex flex-col gap-3 w-full justify-between text-[14px]">
          <span className="flex text-[15px] font-medium border-l-4 border-primary pl-2">
            Commodité de départ
          </span>
          <div className="flex flex-col gap-1 pl-[10px]">
            <div className="flex flex-row flex-wrap gap-4">
              {from.station_conveniences.map((e, index) => {
                return <ConvenienceItem key={index.toString()} item={e} />;
              })}
            </div>
          </div>
        </div>
      )}
      {!isAuthenticated && <FormReservationUser />}
    </div>
  );
};

export default ReservationForm;
