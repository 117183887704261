import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { TravelResultModel } from "../../hooks/useResults";
import { ZustandSessionStorageCrypted } from "./utils-session";
import { produce } from "immer";
import {
  validateVoucher,
  verifyTravelTripDisponibilityAndRegister,
} from "./functions/reservationSession";
import { VoucherModel } from "../../models/models";
import { getPropValue } from "../../functions/getPropValue";
import { isEmail, isFullName, isPhoneNumber } from "../../constants";

const LOCAL_DATASTORE_NAME = "lesvoyageurs-reservationSession";

type ContextProps = {
  reservations: Array<TravelResultModel>;
  voucher: VoucherModel | null;
  add: (reservation: TravelResultModel) => void;
  update: (id: string, update: TravelResultModel) => void;
  validateVoucher: (
    voucher: string,
    ticket: string,
    frequency: string
  ) => Promise<any>;
  clearVoucher: () => void;
  userFormData: {
    fullname: string;
    identifiant_type: string;
    identifiant: string;
    saveUserChecked: boolean;
  };
  errorUserFormData: {
    fullname: { state: boolean; msg: string };
    identifiant: { state: boolean; msg: string };
  };
  clearErrors: () => void;
  checkUserFormData: () => boolean;
  updateUserFormData: (slug: string) => (value: any) => void;
  verifyTravelTripDisponibilityAndRegister: (
    start: TravelResultModel,
    user: any,
    voucher: VoucherModel | null
  ) => Promise<any>;
};

export const useReservationSessionStore = create<
  ContextProps,
  [["zustand/persist", ContextProps]]
>(
  persist(
    (set, get) => ({
      reservations: [],
      voucher: null,
      userFormData: {
        fullname: "",
        identifiant_type: "phone", // phone || email
        identifiant: "",
        saveUserChecked: true,
      },
      errorUserFormData: {
        fullname: { state: false, msg: "" },
        identifiant: { state: false, msg: "" },
      },
      updateUserFormData: (slug: string) => {
        return (value: any) => {
          set(
            produce((state: ContextProps) => {
              state.userFormData = {
                ...state.userFormData,
                [slug]: value,
              };
              if (getPropValue(state.errorUserFormData, slug)) {
                state.errorUserFormData = {
                  ...state.errorUserFormData,
                  [slug]: { state: false, msg: "" },
                };
              }
              if (slug === "identifiant_type") {
                state.userFormData = {
                  ...state.userFormData,
                  identifiant: "",
                };
                state.errorUserFormData = {
                  ...state.errorUserFormData,
                  identifiant: { state: false, msg: "" },
                };
              }
            })
          );
        };
      },
      clearErrors: () => {
        set(
          produce((state: ContextProps) => {
            state.errorUserFormData = {
              fullname: { state: false, msg: "" },
              identifiant: { state: false, msg: "" },
            };
          })
        );
      },
      checkUserFormData: () => {
        let errorOccured = false;
        const state = get();
        if (
          state.userFormData.fullname.trim().length === 0 ||
          !isFullName(state.userFormData.fullname.trim())
        ) {
          errorOccured = true;
          let msg = "Nom & Prénom incorrecte Ex: John Doe";
          set(
            produce((state: ContextProps) => {
              state.errorUserFormData = {
                ...state.errorUserFormData,
                fullname: { state: true, msg },
              };
            })
          );
        } else {
          set(
            produce((state: ContextProps) => {
              state.errorUserFormData = {
                ...state.errorUserFormData,
                fullname: { state: false, msg: "" },
              };
            })
          );
        }
        if (
          state.userFormData.identifiant_type === "email" &&
          !isEmail(state.userFormData.identifiant.trim())
        ) {
          errorOccured = true;
          let msg = "Email incorrecte Ex: exemple@gmail.com";
          set(
            produce((state: ContextProps) => {
              state.errorUserFormData = {
                ...state.errorUserFormData,
                identifiant: { state: true, msg },
              };
            })
          );
        } else if (
          state.userFormData.identifiant_type === "phone" &&
          (!isPhoneNumber(state.userFormData.identifiant.trim()) ||
            ["+2250000000000", "Ex: +2250000000000"].includes(
              state.userFormData.identifiant.trim()
            ))
        ) {
          errorOccured = true;
          let msg = "Téléphone incorrecte Ex: +2250000000000";
          set(
            produce((state: ContextProps) => {
              state.errorUserFormData = {
                ...state.errorUserFormData,
                identifiant: { state: true, msg },
              };
            })
          );
        } else {
          set(
            produce((state: ContextProps) => {
              state.errorUserFormData = {
                ...state.errorUserFormData,
                identifiant: { state: false, msg: "" },
              };
            })
          );
        }
        return errorOccured;
      },
      add: (reservation) => {
        set(
          produce((state: ContextProps) => {
            state.reservations = state.reservations
              .filter((e) => e.id !== reservation.id)
              .concat(reservation);
          })
        );
      },
      update: (id, update) => {
        set(
          produce((state: ContextProps) => {
            state.reservations = state.reservations.map((e) => {
              if (e.id === id) {
                return {
                  ...e,
                  ...update,
                };
              }
              return e;
            });
          })
        );
      },
      validateVoucher: async (voucher, travel, frequency) => {
        const res = await validateVoucher({ voucher, travel, frequency });
        if (res.success) {
          set(
            produce((state: ContextProps) => {
              state.voucher = res.data;
            })
          );
        }
        return res;
      },
      clearVoucher: () => {
        set(
          produce((state: ContextProps) => {
            state.voucher = null;
          })
        );
      },
      verifyTravelTripDisponibilityAndRegister: async (
        start,
        user,
        voucher
      ) => {
        const res = await verifyTravelTripDisponibilityAndRegister(
          start,
          user,
          voucher
            ? {
                voucher_id: voucher.voucher_id,
                voucher_code: voucher.voucher_code,
                voucher_type: voucher.voucher_type,
                voucher_rate: voucher.voucher_rate,
              }
            : null
        );
        return res;
      },
    }),
    {
      name: LOCAL_DATASTORE_NAME,
      storage: createJSONStorage(() => ZustandSessionStorageCrypted("dat_srh")),
    }
  )
);
