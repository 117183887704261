const SecondBloc = () => {
  return (
    <div
      className={`flex flex-col w-full justify-center text-white relative z-[2]`}
    >
      <div className="flex  bg-coral w-full justify-center pt-0 pb-10">
        <div className="flex flex-col max-w-[740px] px-10 justify-center items-center gap-2">
          <span className="flex font-bold text-[22px]">Notre mission</span>
          <span className="flex text-center font-medium text-[18px] leading-relaxed">
            Ouvrir les horizons et connecter les communautés ouest-africaine en
            rendant les voyages en transport en commun accessibles, simples et
            agréables pour tous. Nous nous engageons à bâtir un avenir où la
            réservation de billets de bus, métro et bateau est une expérience
            sans tracas et enrichissante, favorisant l'exploration, la
            découverte et la création de liens durables entre les personnes et
            les lieux.
          </span>
        </div>
      </div>
      <img src="../BottomWave.svg" alt="" className="flex w-full" />
    </div>
  );
};

export default SecondBloc;
