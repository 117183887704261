import React from "react";
// import DestinationCarrousel from "../../../components/destinationCarrousel";
import DownloadTicketCard from "../../../components/downloadTicketCard";
import ReservationSuccess from "../../../components/reservationSuccess";
import { useSearchParams } from "react-router-dom";
import { useNaviLoadBoth } from "../../../functions/useNaviLoadBoth";
import { TicketModel } from "../../../models/models";
import { useOneEffect } from "../../../hooks/useOnEffect";
import { useSearchStore } from "../../../configs/stores/search";

const ReserationSuccess = () => {
  const { searchTicket } = useSearchStore();
  const [searchParams] = useSearchParams();
  const navigate = useNaviLoadBoth;
  const id = searchParams.get("id");
  const [isLoading, setIsLoading] = React.useState(true);
  const [message, setMessage] = React.useState<string | null>(null);
  const [ticket, setTicket] = React.useState<TicketModel | null>(null);
  function getTicket(id: string) {
    setIsLoading(true);
    searchTicket(id).then((res) => {
      setIsLoading(false);
      if (res.success) {
        setMessage(null);
        setTicket(res.data);
      } else {
        setMessage(res.message);
      }
    });
  }
  useOneEffect(() => {
    setMessage(null);
    if (id) {
      getTicket(id);
    } else {
      navigate("/");
    }
  }, []);
  return (
    <div className="flex flex-1 w-full overflow-hidden items-start justify-center px-10 tablet_mini:px-6 phone:px-5 max_tablet_mini:py-10 py-14">
      <div className="flex w-full flex-col max-w-[1536px] gap-[40px] items-center">
        {!isLoading ? (
          ticket ? (
            <div className="flex flex-row flex-wrap gap-10 justify-center items-start">
              <ReservationSuccess />
              <DownloadTicketCard ticket={ticket} />
            </div>
          ) : (
            <div className="flex items-center gap-5 flex-col px-5 py-5">
              <span className="flex text-[25px] font-semibold">
                Resultat de votre réservation
              </span>
              <span className="flex text-[20px] font-semibold text-red-400">
                {message}
              </span>
              <span
                onClick={() => navigate("/")}
                className="flex text-[20px] cursor-pointer underline underline-offset-2 font-medium text-primary"
              >
                Retour a l'accueil
              </span>
            </div>
          )
        ) : (
          <div className="flex gap-5 flex-col px-5 py-5">
            <span className="flex text-[25px] font-semibold">
              Resultat de votre réservation
            </span>
            <span className="flex text-[23px] font-semibold">
              Chargement du ticket, patientez un instant...
            </span>
          </div>
        )}
        {/* <DestinationCarrousel /> */}
      </div>
    </div>
  );
};

export default ReserationSuccess;
