import React from "react";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";

type Props = {
  color?: string;
  size?: number;
  href?: string;
  stroke?: number;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
};

const BarsIcon = ({
  color = "#828282",
  stroke = 1.5,
  size = 24,
  href,
  onClick,
}: Props) => {
  const Ratio_SVG = 24 / 24;
  const navigate = useNaviLoadBoth;
  const heigth = size.toString();
  const width = (Ratio_SVG * size).toString();
  const clickable = !!onClick || !!href;
  function click(e: any) {
    onClick && onClick(e);
    href && navigate(href);
  }
  return (
    <svg
      width={width}
      height={heigth}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={stroke.toString()}
      stroke={color}
      className={clickable ? "cursor-pointer" : undefined}
      onClick={click}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
      />
    </svg>
  );
};

export default BarsIcon;
