import React from "react";
import { useReservationSessionStore } from "../../configs/stores/reservationSession";
import clsx from "clsx";

type Props = {
  error?: { state: boolean; msg: string };
  onChange?: (value: string) => void;
  onChangeType?: (value: string) => void;
};

const FormInputIdentifiantReservation = ({
  onChange,
  onChangeType,
  error = { state: false, msg: "" },
}: Props) => {
  const { userFormData, clearErrors } = useReservationSessionStore();
  const identifiantType = userFormData.identifiant_type;
  React.useEffect(() => {
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex flex-row items-center">
        <div className="flex flex-row gap-3 items-center">
          <FormIdentifiant
            title={"Téléphone"}
            value={"phone"}
            selectedValue={identifiantType}
            onClick={onChangeType}
          />
          {/* <span className="flex text-[14px]">ou</span>
          <FormIdentifiant
            title={"Adresse email"}
            value={"email"}
            selectedValue={identifiantType}
            onClick={onChangeType}
          /> */}
        </div>
        <span className="flex text-coral pl-2">*</span>
      </div>
      <SwitchIdentifiantField
        type={identifiantType}
        identifiant={userFormData.identifiant}
        onChange={onChange}
        error={error}
      />
    </div>
  );
};

type FormIdentifiantProps = {
  title: string;
  value: string;
  selectedValue: string;
  onClick?: (value: string) => void;
};

function FormIdentifiant({
  title,
  value,
  selectedValue,
  onClick,
}: FormIdentifiantProps) {
  return (
    <div
      onClick={() => onClick && onClick(value)}
      className="flex flex-col rounded-md cursor-pointer gap-[2px]"
    >
      <span
        className={clsx(
          "flex text-[14px]",
          selectedValue === value ? " text-black" : ""
        )}
      >
        {title}
      </span>
      {/* <div
        className={clsx(
          "flex h-[2px] rounded-md",
          selectedValue === value ? "bg-primary" : "bg-black/10"
        )}
      /> */}
    </div>
  );
}

type SwitchIdentifiantFieldProps = {
  type: string;
  identifiant: string;
  onChange?: (value: string) => void;
  error: { state: boolean; msg: string };
};

function SwitchIdentifiantField({
  type,
  identifiant,
  onChange,
  error,
}: SwitchIdentifiantFieldProps) {
  switch (type) {
    case "email":
      return (
        <div className="flex flex-col relative gap-1">
          <input
            type="email"
            placeholder={"exemple@gmail.com"}
            maxLength={70}
            value={identifiant}
            onChange={(e) => onChange && onChange(e.target.value.trim() ?? "")}
            className={clsx(
              "flex w-full text-[16px] font-normal rounded-md border border-gainsboro bg-white py-[8px] outline-none px-3",
              error.state
                ? "caret-red-500 border-red-500 outline-none text-red-500 placeholder:text-red-500/70"
                : "border-slate-300 outline-none focus:border-primary"
            )}
          />
          {error.msg.length !== 0 && (
            <span className="flex select-none pl-[6px] text-[13px] text-red-500">
              {error.msg}
            </span>
          )}
        </div>
      );
    case "phone":
      return (
        <div className="flex flex-col relative gap-1">
          <input
            type="text"
            placeholder={"2250000000000"}
            maxLength={20}
            value={identifiant}
            onChange={(e) => onChange && onChange(e.target.value.trim() ?? "")}
            className={clsx(
              "flex w-full text-[16px] font-normal rounded-md border border-gainsboro bg-white py-[8px] outline-none px-3",
              error.state
                ? "caret-red-500 border-red-500 outline-none text-red-500 placeholder:text-red-500/70"
                : "border-slate-300 outline-none focus:border-primary"
            )}
          />
          {error.msg.length !== 0 && (
            <span className="flex select-none pl-[6px] text-[13px] text-red-500">
              {error.msg}
            </span>
          )}
        </div>
      );

    default:
      return null;
  }
}

export default FormInputIdentifiantReservation;
