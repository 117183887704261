import React from "react";

const ShareButton = () => {
  const linkToShare = "https://example.com"; // Lien à partager

  const onShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Titre du lien à partager",
          url: linkToShare,
        })
        .then(() => console.log("Lien partagé avec succès"))
        .catch((error) => console.error("Erreur lors du partage :", error));
    } else {
      console.log("API de partage non disponible");
    }
  };
  return (
    <>
      <button
        onClick={onShare}
        className="transition-all phone:hidden duration-150 flex h-[50px] px-3 py-1 items-center justify-center rounded-[10px] flex-row gap-3 hover:bg-black/15 active:bg-black/10"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-5 h-5"
        >
          <path d="M13 4.5a2.5 2.5 0 1 1 .702 1.737L6.97 9.604a2.518 2.518 0 0 1 0 .792l6.733 3.367a2.5 2.5 0 1 1-.671 1.341l-6.733-3.367a2.5 2.5 0 1 1 0-3.475l6.733-3.366A2.52 2.52 0 0 1 13 4.5Z" />
        </svg>
        <span className="flex phone:hidden text-[18px] font-semibold">
          Partager
        </span>
      </button>
      <div onClick={onShare} className="hidden py-1 phone:flex">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 5.75a3.25 3.25 0 0 1-5.532 2.314L9.31 10.906a3.245 3.245 0 0 1-.001 2.192l5.156 2.84a3.25 3.25 0 1 1-.922 1.775l-5.478-3.017a3.25 3.25 0 1 1 .004-5.389l5.474-3.018A3.25 3.25 0 1 1 20 5.75z"
            fill="#000000"
          />
        </svg>
      </div>
    </>
  );
};

export default ShareButton;
