import React from "react";
import { useAuthStore } from "../../configs/stores/auth";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";
import TextInput from "./text-input";
import PhoneInput from "./phone-input";
import EmailInput from "./email-input";
import PasswordInput from "./password-input";
import { getPropValue } from "../../functions/getPropValue";
import {
  isEmail,
  isPassword,
  isPhoneNumber,
  primaryColor,
} from "../../constants";
import { Checkbox, FormControlLabel } from "@mui/material";

const SignUp = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const isAuthenticated = useAuthStore((e) => e.isAuthenticated);
  const { signup } = useAuthStore();
  const [formData, setFormData] = React.useState({
    user_fullname: "",
    user_phone: "",
    user_email: "",
    user_password: "",
    user_confirmpassword: "",
    user_conditionaccepted: false,
  });
  const [errorState, setErrorState] = React.useState({
    user_fullname: { state: false, msg: "" },
    user_phone: { state: false, msg: "" },
    user_email: { state: false, msg: "" },
    user_password: { state: false, msg: "" },
    user_confirmpassword: { state: false, msg: "" },
    generalMsg: "",
  });
  const navigate = useNaviLoadBoth;

  function onValueChange(slug: string) {
    return (value: any) => {
      setFormData((f) => ({ ...formData, [slug]: value }));
      if (getPropValue(errorState, slug)) {
        setErrorState((e) => ({ ...e, [slug]: { state: false, msg: "" } }));
      }
      if (errorState.generalMsg) {
        setErrorState((e) => ({
          ...e,
          generalMsg: "",
        }));
      }
    };
  }

  function checkFormData() {
    let errorOccured = false;
    // fullname
    if (formData.user_fullname.length === 0) {
      errorOccured = true;
      let msg = "";
      setErrorState((e) => ({ ...e, user_fullname: { state: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        user_fullname: { state: false, msg: "" },
      }));
    }
    // phone
    if (
      formData.user_phone.length === 0 ||
      !isPhoneNumber(formData.user_phone)
    ) {
      errorOccured = true;
      let msg = "Téléphone incorrecte";
      setErrorState((e) => ({ ...e, user_phone: { state: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, user_phone: { state: false, msg: "" } }));
    }
    // email
    if (formData.user_email.length === 0 || !isEmail(formData.user_email)) {
      errorOccured = true;
      let msg = "Adresse email incorrecte";
      setErrorState((e) => ({ ...e, user_email: { state: true, msg } }));
    } else {
      setErrorState((e) => ({ ...e, user_email: { state: false, msg: "" } }));
    }
    // password
    if (
      formData.user_password.length === 0 ||
      !isPassword(formData.user_password)
    ) {
      errorOccured = true;
      let msg = "";
      if (formData.user_password.length <= 10) {
        msg = "Minimum 10 caracteres";
      } else {
        msg = "Minimum un chiffre, une majuscule, une minuscule";
      }
      setErrorState((e) => ({ ...e, user_password: { state: true, msg } }));
    } else {
      setErrorState((e) => ({
        ...e,
        user_password: { state: false, msg: "" },
      }));
    }
    // confirm password
    if (
      formData.user_confirmpassword.length === 0 ||
      formData.user_confirmpassword !== formData.user_password
    ) {
      errorOccured = true;
      let msg = "Confirmer le mot de passe";
      setErrorState((e) => ({
        ...e,
        user_confirmpassword: { state: true, msg },
      }));
    } else {
      setErrorState((e) => ({
        ...e,
        user_confirmpassword: { state: false, msg: "" },
      }));
    }
    // condiions accepted
    if (!formData.user_conditionaccepted) {
      errorOccured = true;
      let msg =
        "Veuillez accepter les conditions d'utilisation avant de continuer";
      setErrorState((e) => ({
        ...e,
        generalMsg: msg,
      }));
    } else {
      setErrorState((e) => ({
        ...e,
        generalMsg: "",
      }));
    }
    return errorOccured;
  }

  function submitForm(e: any) {
    e.preventDefault();
    if (checkFormData()) {
      console.log("Error Occured !!!");
      return;
    }
    setErrorState((e) => ({
      ...e,
      generalMsg: "",
    }));
    setIsLoading(true);
    const { user_confirmpassword, user_conditionaccepted, ...formValue } =
      formData;
    signup({
      ...formValue,
    }).then((res: any) => {
      setIsLoading(false);
      if (!res.success) {
        setErrorState((e) => ({
          ...e,
          generalMsg: res.message.toString(),
        }));
      }
    });
  }
  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
  return (
    <div className="flex flex-1 flex-col gap-10 items-center justify-center min_tablet_mini:py-10">
      <div className="flex bg-white rounded-[10px] border-black/75 flex-col w-[100vw] max-w-[380px] gap-5 px-6 min_tablet_mini:px-8 py-10">
        <div className="flex leading-tight flex-col text-[24px] text-black font-semibold text-center">
          <span>Inscrivez-vous</span>
          <span></span>
        </div>
        <div className="flex flex-col gap-4">
          <TextInput
            placeholder="Nom & Prénoms"
            error={errorState.user_fullname}
            onTextChange={onValueChange("user_fullname")}
          />
          <PhoneInput
            error={errorState.user_phone}
            onTextChange={onValueChange("user_phone")}
          />
          <EmailInput
            error={errorState.user_email}
            onTextChange={onValueChange("user_email")}
          />
          <PasswordInput
            error={errorState.user_password}
            onTextChange={onValueChange("user_password")}
          />
          <PasswordInput
            placeholder="Confirmer mot de passe"
            error={errorState.user_confirmpassword}
            onTextChange={onValueChange("user_confirmpassword")}
          />
          <FormControlLabel
            onChange={(e, bool) => {
              onValueChange("user_conditionaccepted")(bool);
              if (errorState.generalMsg) {
                setErrorState((e) => ({
                  ...e,
                  generalMsg: "",
                }));
              }
            }}
            control={
              <Checkbox
                defaultChecked={formData.user_conditionaccepted}
                sx={{
                  color: "rgb(203 213 225)",
                  "&.Mui-checked": {
                    color: primaryColor,
                  },
                }}
              />
            }
            label={
              <div className="flex flex-row text-[14px] gap-[4px] items-center">
                <span>J'accepte les</span>
                <a
                  href="/privacy-policy"
                  className="flex text-primary underline underline-offset-2"
                >
                  conditions d'utilisation.
                </a>
              </div>
            }
          />
          {!!errorState.generalMsg && (
            <div className="flex w-full bg-red-500/15 pl-3 py-2 rounded-md">
              <span className="flex text-[15px] font-medium text-red-600">
                {errorState.generalMsg}
              </span>
            </div>
          )}
          <button
            onClick={submitForm}
            className={`flex rounded-[10px] ${
              true ? "bg-primary hover:bg-hover" : "bg-hover"
            }  items-center justify-center py-[16px]`}
          >
            <span className="flex text-white font-semibold text-[18px]">
              {isLoading ? "Inscription..." : "Inscription"}
            </span>
          </button>
        </div>
        <a href="/login" className="flex self-center">
          <span className="flex transition-all duration-150 text-primary group-hover:text-hover font-semibold text-[16px] underline underline-offset-2">
            Connectez-vous
          </span>
        </a>
        <span className="flex flex-col text-[13px] text-gray text-center">
          <span>En créant ou en vous connectant à un compte,</span>
          <span>
            vous acceptez nos conditions générales et Politique de
            confidentialité.
          </span>
        </span>
      </div>
    </div>
  );
};

export default SignUp;
