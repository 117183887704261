import React from "react";
import { useElementSize } from "../../functions/getAnyElementSize";

const FourthBloc = () => {
    const [test, settest] = React.useState(false)
  const { refInput: refInput1 } = useElementSize();
  const { refInput: refInput2 } = useElementSize();
  React.useEffect(() => {
    setTimeout(() => {
        settest(true)
    }, 900);
  }, [test])
  
  return (
    <div
      style={{
        minHeight: (refInput1.current?.offsetWidth??0)*.45 + 10,
      }}
      className="flex flex-col w-full items-center justify-center relative my-5"
    >
      <div className="flex flex-row bg-[#f2f2f2] w-full justify-center">
        <div
          ref={refInput1}
          className="flex w-full flex-row 2xl:w-[1536px] justify-end"
        >
          <div className="flex flex-col w-[60%] py-[120px] gap-4">
            <span className="flex text-[35px] font-extrabold leading-[1.2]">
              Une expérience 100% digitale
            </span>

            <span className="flex text-[20px] font-medium leading-[1.5] w-[90%]">
              Notre plateforme, accessible via un site web et une application
              mobile, permet aux utilisateurs de rechercher et comparer les
              options de transport en commun disponibles, de réserver leurs
              billets en quelques clics et de recevoir leurs billets
              électroniques instantanément. En outre, nous proposons une offre
              premium pour les voyageurs souhaitant vivre une expérience de
              première classe lors de leurs déplacements.
            </span>
          </div>
          <div
            ref={refInput2}
            style={{ left: refInput1.current?.offsetLeft ?? 10,width:(refInput1.current?.offsetWidth??0)*.45 }}
            className="flex top-0 absolute justify-center"
          >
            <div className="flex w-[50%]">
              <img
                src="../mobileApp.png"
                alt=""
                className="flex"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourthBloc;
