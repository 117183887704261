
const ReservationSuccess = () => {
  return (
    <div className="flex flex-col max-w-[550px] max_tablet:max-w-full items-start gap-5">
      <img src="../../verified.svg" alt="" className="flex h-[200px] w-[200px]" />
      <span className="flex text-[25px] font-semibold">
        Réservation réussie
      </span>
      <span className="flex text-[17px] font-normal leading-relaxed">
        Merci d'avoir choisi LesVoyageurs, nous vous souhaitons un excellent voyage.
      </span>
      <span className="flex text-[17px] mt-5 font-normal leading-relaxed">
        Vous devez présenter votre ticket de voyage pour la validation avant l'embarquement, en prenant vos dispositions pour vous présenter à la gare 1 heure avant le départ.
      </span>
      <a href="/" className="flex text-coral">
        <span>Retour à l'accueil</span>
      </a>
    </div>
  );
};

export default ReservationSuccess;
