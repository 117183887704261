import React from "react";
import { useAuthStore } from "../../configs/stores/auth";
import LoginEmailForm from "./email-form";
import { useNaviLoadBoth } from "../../functions/useNaviLoadBoth";
import LoginPhoneForm from "./phone-form";

const SignIn = () => {
  const isAuthenticated = useAuthStore((e) => e.isAuthenticated);
  const [selectedLoginMethod, setSelectedLoginMethod] = React.useState("phone");
  const navigate = useNaviLoadBoth;
  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
  return (
    <div className="flex flex-1 flex-col gap-10 items-center justify-center min_tablet_mini:py-10">
      <div className="flex bg-white rounded-[10px] border-black/75 flex-col w-[100vw] max-w-[380px] gap-5 px-6 min_tablet_mini:px-8 py-10">
        <div className="flex leading-tight flex-col text-[24px] text-black font-semibold text-center">
          <span>Connectez-vous</span>
          <span></span>
        </div>
        <div className="flex text-[15px] justify-center flex-row gap-2 flex-wrap">
          <span className="flex">Continuer via: </span>
          <div className="flex flex-row gap-2">
            <span
              onClick={() => {
                setSelectedLoginMethod("phone");
              }}
              className={`flex cursor-pointer font-semibold border-b-[2px] ${
                selectedLoginMethod === "phone"
                  ? `text-primary border-primary`
                  : `border-transparent`
              }`}
            >
              Téléphone
            </span>
            <span className="flex">-</span>
            <span
              onClick={() => {
                setSelectedLoginMethod("email");
              }}
              className={`flex cursor-pointer font-semibold border-b-[2px] ${
                selectedLoginMethod === "email"
                  ? `text-primary border-primary`
                  : `border-transparent`
              }`}
            >
              Email
            </span>
          </div>
        </div>
        {selectedLoginMethod === "phone" && <LoginPhoneForm />}
        {selectedLoginMethod === "email" && <LoginEmailForm />}
        <a href="/signup" className="flex self-center">
          <span className="flex transition-all duration-150 text-primary group-hover:text-hover font-semibold text-[16px] underline underline-offset-2">
            Créer un compte
          </span>
        </a>
        <span className="flex flex-col text-[13px] text-gray text-center">
          <span>En créant ou en vous connectant à un compte,</span>
          <span>
            vous acceptez nos conditions générales et Politique de
            confidentialité.
          </span>
        </span>
      </div>
    </div>
  );
};

export default SignIn;
